import styled from 'styled-components';
const variantValues = {
    minimize: { padding: '14px 6px', beforePaddingLeft: '2px' },
    default: { padding: '14px 6px', beforePaddingLeft: '12px' }
};
export const ListContainer = styled.ul `
  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => variantValues[props.$variant].padding};
  border-top: 1px solid #ffffff16;
  margin: 0;

  &:before {
    content: attr(aria-label);
    font-size: 12px;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: #ffffff96;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-left: ${(props) => variantValues[props.$variant].beforePaddingLeft};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block;

    ${(props) => props.theme.breakpoints.down('md')} {
      padding-left: ${variantValues.default.beforePaddingLeft};
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${variantValues.default.padding};
  }
`;
