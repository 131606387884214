import React from 'react';
import { Form } from 'react-bootstrap';
import Label from '../Label';
import FormFeedback from '../FormFeedback';
import { StyledInputGroup, StyledTextarea } from './styles';
const Textarea = React.forwardRef(({ name, label, errorMessage, isError, required, value, ...props }, ref) => {
    return (React.createElement(Form.Group, null,
        label && React.createElement(Label, { required: required }, label),
        React.createElement(StyledInputGroup, { "$isError": isError },
            React.createElement(StyledTextarea, { ...props, as: "textarea", ref: ref, id: name, name: name, required: required, rows: 6, value: value })),
        isError && errorMessage && React.createElement(FormFeedback, null, errorMessage)));
});
export default Textarea;
