import React, { useContext } from 'react';
import { FullSpinner } from '@campus/components';
import { useStage, useClassroom } from './hooks';
import { usePeriod } from './hooks/usePeriod';
const ContextManagerContext = React.createContext(null);
export const ContextManagerProvider = ({ children, contextType }) => {
    const { changeClassroom, classroom, classrooms, loading } = useClassroom(contextType);
    const { changeStage, loading: loadingStage, stage, stages } = useStage();
    const { changePeriod, loading: loadingPeriod, period, periods, isCurrentPeriod } = usePeriod(contextType);
    if (loading || loadingStage || loadingPeriod) {
        return React.createElement(FullSpinner, { title: "Carregando dados da classe..." });
    }
    return (React.createElement(ContextManagerContext.Provider, { value: {
            stage,
            stages,
            period,
            isCurrentPeriod,
            periods,
            classroom,
            classrooms,
            setPeriod: changePeriod,
            setClassroom: changeClassroom,
            setStage: changeStage
        } }, children));
};
export const useContextManager = () => useContext(ContextManagerContext);
