const getIsPathWithoutSideMenu = () => {
    const routesWithoutSideMenu = ['library-object'];
    const path = window.location.pathname;
    if (path.split('/').length > 1) {
        const isPathWithoutSideMenu = routesWithoutSideMenu.includes(path.split('/')[1]);
        return isPathWithoutSideMenu;
    }
    return false;
};
export default getIsPathWithoutSideMenu;
