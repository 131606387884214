import styled from 'styled-components';
export const HeaderMobileContainer = styled.div `
  display: flex;
  align-items: flex-start;
`;
export const HeaderMobileContent = styled.div `
  display: flex;
  align-items: flex-start;
  flex: 1;
`;
export const HeaderLogo = styled.img `
  width: 26px;
  z-index: 1;
  cursor: pointer;
`;
export const Avatar = styled.div `
  background: url(${(p) => `${encodeURI(p.url)}`});
  background-color: ${({ theme }) => theme.colors.gray1};
  min-width: 32px;
  min-height: 32px;
  background-size: cover;
  border-radius: 50%;
  align-self: end;
  z-index: 1;
  margin: auto;
`;
export const VerticalDivider = styled.div `
  height: 29px;
  border-left: 1px solid #ffffff;
  opacity: 0.3;
  margin: 0 12px;
`;
export const Title = styled.h1 `
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
  z-index: 1;
  position: relative;

  @media ${(props) => props.theme.mediaQuery.smallMobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const Subtitle = styled.h1 `
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 0;
  z-index: 1;
  position: relative;
  
  @media ${(props) => props.theme.mediaQuery.miniMobile} {
    font-size: 10px;
  }
`;
export const DivArrow = styled.div `
  opacity: 0.4;
`;
export const HeaderBackground = styled.div `
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${(props) => props.$isStudent && props.$isExpandedHeader ? 'height: 114px;' : ''}
  z-index: 0;
  ${(props) => !props.$background
    ? `background-color: ${props.$color || props.theme.colors.gray3};`
    : ''}
  ${(props) => (props.$background ? `background: ${props.$background};` : '')}
`;
export const HeaderContainer = styled.div `
  display: flex;
  ${(props) => props.$isStudent && props.$isExpandedHeader ? 'padding-bottom: 85px;' : ''}
  width: 100%;
`;
