import styled from 'styled-components';
export const IconHolder = styled.div `
  position: relative;
  display: inline;
  ${(props) => (props.$pb ? `padding-bottom:${props.$pb}px !important;` : '')}
`;
export const StyledIcon = styled.svg `
  color: ${(props) => props.theme.colors[props.$color] || props.$color};
  ${(props) => props.$size ? `width:${props.$size}px !important; height: auto;` : ''};
  ${(props) => (props.$margin ? `margin: ${props.$margin} !important` : '')};
`;
export const Attention = styled.div `
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 0px;
  right: 0;
`;
