import { DataPoint } from './dataPoint';
import { calculateDistance } from './utils';
const maximumGroupLength = 5;
export function createChartData(models) {
    if (!models?.length)
        return [];
    const dataPoints = models.map((m) => new DataPoint(m));
    const distances = Array.from({ length: dataPoints.length });
    for (let i = 0; i < distances.length; ++i) {
        distances[i] = Array.from({ length: dataPoints.length });
        for (let j = 0; j < distances.length; ++j) {
            if (i === j) {
                distances[i][j] = Number.POSITIVE_INFINITY;
                continue;
            }
            const { x: x1, y: y1 } = dataPoints[i];
            const { x: x2, y: y2 } = dataPoints[j];
            distances[i][j] = calculateDistance(x1, y1, x2, y2);
        }
    }
    const chartData = [];
    for (let i = 0; i < dataPoints.length; ++i) {
        if (!dataPoints[i])
            continue;
        while (dataPoints[i].group.length < maximumGroupLength) {
            let minimumDistance = distances[i][0];
            let minimumDistanceIndex = 0;
            for (let j = 1; j < dataPoints.length; ++j) {
                if (dataPoints[j] && distances[i][j] < minimumDistance) {
                    minimumDistance = distances[i][j];
                    minimumDistanceIndex = j;
                }
            }
            distances[i][minimumDistanceIndex] = Number.POSITIVE_INFINITY;
            if (dataPoints[minimumDistanceIndex] &&
                dataPoints[i].isCloseEnough(minimumDistance)) {
                dataPoints[i].addToGroup(dataPoints[minimumDistanceIndex]);
                dataPoints[minimumDistanceIndex] = undefined;
            }
            else {
                break;
            }
        }
        chartData.push(dataPoints[i]);
        dataPoints[i] = undefined;
    }
    return chartData;
}
