import { useEffect } from 'react';
import { BlipChat } from 'blip-chat-widget';
export const useBuildBlipChat = () => {
    useEffect(() => {
        const loadChat = async () => {
            new BlipChat()
                .withAppKey(process.env.REACT_APP_CHAT_KEY)
                .withButton({ color: '#b52b5c' })
                .build();
        };
        loadChat();
    }, []);
};
