const values = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};
const up = (key) => `@media (min-width:${values[key]}px)`;
const down = (key) => `@media (max-width:${values[key] - 5 / 100}px)`;
export default { up, down };
