import styled from 'styled-components';
export const Root = styled.div `
  width: 34px;
  min-width: 33px;
  height: 34px;
  border-radius: 100%;
  color: ${(props) => (props.$inverse ? props.$color : '#fff')};
  background-color: ${(props) => (props.$inverse ? '#fff' : props.$color)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  transition: all 200ms ease-out;
  
  @media ${(props) => props.theme.mediaQuery.mobile} {
    font-size: 10px;
  }
`;
export const CircleNewContentAvailable = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  box-shadow: 0 0 0 2.25px ${({ $color }) => $color};
`;
