import mergeTheme from './mergeTheme';
const adminTheme = {
    colors: {
        leftbar: {
            backgroundTop: '#7AB800',
            backgroundBottom: '#2AD2C9'
        }
    }
};
export default mergeTheme(adminTheme);
