import React from 'react';
import Button from '../../Button';
import { ButtonContainer, Container, ErrorText } from './styles';
const GenericError = ({ title, subTitle, onRetry, onExit, image }) => {
    return (React.createElement(Container, null,
        React.createElement(ErrorText, null,
            React.createElement("h1", null, title),
            React.createElement("h2", null, subTitle),
            React.createElement(ButtonContainer, null,
                React.createElement(Button, { onClick: onRetry }, "Ir para a P\u00E1gina Inicial"),
                onExit && React.createElement(Button, { onClick: onExit }, "Sair"))),
        image && React.createElement("img", { src: image })));
};
export default GenericError;
