import styled from 'styled-components';
import { Popover, Dropdown } from '@campus/components';
export const PopoverBody = styled(Popover.Body) `
  padding: 0;
  width: 120px;
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
export const DropdownItem = styled(Dropdown.Item) `
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #828282;
  padding: 8px 10px;
  font-size: 14px;
  font-family: 'Gilroy';
  line-height: 110%;

  :hover {
    background: #f2f2f2;
    color: #828282;
    cursor: pointer;
    border-radius: 2px;
  }
`;
