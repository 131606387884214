import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${(props) => props.theme.colors.gray2};
  font-size: 16pt;
  text-transform: uppercase;
  background: #ffffff66;
  position: relative;
  max-height: 65px;
  max-width: 65px;
  aspect-ratio: 1 / 1;
`;
export const Image = styled.img `
  width: 100%;
`;
export const AvatarPercentage = styled.div `
  position: absolute;
  z-index: 10;
  opacity: 0.7;
  height: 100%;
  width: 100%;

  background: ${(props) => props.$status === 'success'
    ? 'linear-gradient(358.31deg, #FEB942 2.87%, #7AB800 2.88%, rgba(122, 184, 0, 0) 30%)'
    : props.$status === 'error' && props.$percentageHigherZero
        ? 'linear-gradient(358.31deg, #EB5757 2.87%, rgba(235, 87, 87, 0) 30%)'
        : ''};

  &:hover {
    display: flex;
    z-index: 12;
    justify-content: center;
    align-items: center;
    background: linear-gradient(313.31deg, #003c71 0%, #b7295a 100%);
    opacity: 0.8;
    color: white;
    span {
      display: block;
    }
  }

  span {
    display: none;
  }
`;
export const StatusBackground = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    360deg,
    ${(props) => (props.$status === 'neutral' ? '#000' : '#000')} 2.88%,
    transparent 100%
  );
  opacity: 0.8;
  z-index: 1;
`;
export const StatusBorder = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  ${(props) => props.percentageHigherZero
    ? `border: 3px solid ${props.theme.colors[props.$status]};`
    : ''}
  z-index: 2;
`;
export const Name = styled.span `
  position: absolute;
  z-index: 11;
  font-size: 9px;
  left: 5px;
  bottom: 4px;
  color: #fff;
  font-weight: ${(props) => props.theme.weight.bold};
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
  line-height: 100%;
`;
