import React from 'react';
import { Pagination } from 'react-bootstrap';
import Select from '../../Select';
import { FooterContainer } from '../styles';
const _pageSizeOptions = [
    { label: '10 items', value: 10 },
    { label: '20 items', value: 20 },
    { label: '30 items', value: 30 }
];
const TableFooter = ({ table, loading, onFetch }) => {
    const { manualPagination, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex } } = table;
    const handlePageChange = (pageSize) => {
        setPageSize(pageSize);
    };
    return (React.createElement(FooterContainer, null,
        loading ? (React.createElement("span", null, "Carregando...")) : (React.createElement("span", null,
            "P\u00E1gina\u00A0",
            React.createElement("strong", null,
                pageIndex + 1,
                " de ",
                pageOptions.length))),
        React.createElement(Select, { name: "pageSize", isClearable: false, onChange: (option) => handlePageChange(option.value), defaultValue: _pageSizeOptions[0], options: _pageSizeOptions }),
        React.createElement(Pagination, null,
            React.createElement(Pagination.First, { onClick: () => gotoPage(0), disabled: !canPreviousPage }),
            React.createElement(Pagination.Prev, { onClick: () => previousPage(), disabled: !canPreviousPage }),
            React.createElement(Pagination.Next, { onClick: () => nextPage(), disabled: !canNextPage }),
            React.createElement(Pagination.Last, { onClick: () => gotoPage(pageCount - 1), disabled: !canNextPage }))));
};
export default React.memo(TableFooter);
