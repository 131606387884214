import React from 'react';
import TextAvatar from '../../../TextAvatar';
import { useSideMenu } from '../../context/SideMenuContext';
import { Item, Text, Badge } from './styles';
const ListItem = ({ title, color, badgeValue, performanceStatusColor = '', weightEvaluationsAvarege = 0, newContentAvailable = false }) => {
    const { options: { variant } } = useSideMenu();
    return (React.createElement(Item, { "$performanceStatusColor": performanceStatusColor, "$weightEvaluationsAvarege": weightEvaluationsAvarege, "$variant": variant, "aria-label": title },
        React.createElement(TextAvatar, { inverse: true, color: color, text: title, newContentAvailable: newContentAvailable }),
        React.createElement(Text, { "$variant": variant }, title),
        badgeValue && React.createElement(Badge, { "$variant": variant }, badgeValue)));
};
export default ListItem;
