import styled from 'styled-components';
export const Header = styled.div `
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h2 `
  display: flex;
  align-items: flex-end;
  font-size: 24px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  color: ${(props) => props.theme.colors.gray2};
  margin-bottom: 2px;

  span {
    color: ${(props) => props.theme.colors.primary};
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ImageIcon = styled.img `
  background: ${(props) => props.$bgColor ?? '#F0F0F0'};
  border-radius: 100%;
`;
export const DivStyled = styled.div `
  ${(props) => (props.$marginTop ? `margin-top: ${props.$marginTop}px;` : '')};
`;
