import Api from '../api/Api';
import { Context, CurrentContext } from '../models';
export const allContexts = async (type) => {
    const response = await Api.INSTANCE.getApi().get(`/context/${type}`);
    return response.data.map((item) => Context.fromJson(item));
};
export const getCurrentContext = async (type) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/context/current/${type}`);
        return CurrentContext.fromJson(response.data);
    }
    catch (error) {
        return null;
    }
};
