import styled from 'styled-components';
import Button from '../Button';
import Modal from '../Modal';
export const StyledCancelButton = styled(Button) `
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray500};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
`;
export const StyledDeleteButton = styled(Button) `
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.error};
  border-radius: 6px;
`;
export const StyledModal = styled(Modal) `
  .modal-content {
    padding: 16px 22px;
  }

  .modal-header {
    padding-bottom: 16px !important;
  }

  .modal-body {
    padding: 0 !important;
  }

  .modal-footer {
    padding-top: 23px !important;
    padding-bottom: 7px !important;
  }

  .btn-close {
    width: 17px;
    height: 17px;
    padding: 0;
    margin-right: 4px;
  }
`;
export const Line = styled.hr `
  width: 500px;
  position: relative;
  left: -23px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  margin: 0;

  @media ${(props) => props.theme.mediaQuery.tabletDown} {
    width: 100%;
    left: 0;
  }
`;
