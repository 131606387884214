import breakpoints from './breakpoints';
const defaultTheme = {
    colors: {
        white: 'white',
        primary: '#2e81ed',
        background: '#F3F3F3',
        blackAlpha500: 'rgba(0, 0, 0, 0.36)',
        gray1: '#333333',
        gray2: '#4F4F4F',
        gray3: '#828282',
        gray4: '#BDBDBD',
        gray5: '#E0E0E0',
        gray50: '#F2F2F2',
        gray100: '#E6E6E6',
        gray200: '#CCCCCC',
        gray300: '#B3B3B3',
        gray400: '#999999',
        gray500: '#808080',
        gray600: '#666666',
        gray700: '#4D4D4D',
        gray800: '#333333',
        CSA: {
            blue: '#0072CE',
            darkBlue: '#003C71',
            cyan: '#2AD2C9',
            green: '#7AB800',
            orange: '#FF7700',
            purple: '#7F37F2',
            red: '#B7312C'
        },
        orange1: '#F2994A',
        orange50: '#FFFAF0',
        orange300: '#F6AD55',
        orange500: '#D69E2E',
        blue1: '#2F80ED',
        green2: '#27AE60',
        green3: '#6FCF97',
        green50: '#F0FFF4',
        green200: '#9AE6B4',
        green500: '#6FCF97',
        blue500: '#3182CE',
        darkPink: '#B7295A',
        yellow1: '#F2C94C',
        yellow2: '#C4D600',
        success: '#7ab800',
        error: '#EB5757',
        sideMenu: {
            opacityBackground: '#001120'
        }
    },
    weight: {
        extraBold: 900,
        bold: 700,
        semiBold: 600,
        regular: 400
    },
    breakpoints,
    mediaQuery: {
        mobile: '(max-width: 767px)',
        miniMobile: '(max-width: 520px)',
        smallMobile: '(max-width: 300px)',
        tabletDown: '(max-width: 991px)',
        tabletUp: '(min-width: 992px)',
        greaterTabletDown: '(max-width: 1310px)',
        desktopDown: '(max-width: 1535px)',
        desktopUp: '(min-width: 1140px)'
    }
};
export default defaultTheme;
