import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
import { elementClassNames } from './Flow';
export const AccessTimeContainer = styled.div `
  width: 100%;
  position: relative;
  height: 92%;
  margin-bottom: 8px !important;
  border-bottom: 1px solid rgba(189, 189, 189, 0.2);
  background: radial-gradient(
    100% 100% at 49.83% 100%,
    rgb(94 94 94 / 10%) 0%,
    rgb(94 94 94 / 0%) 100%
  );
`;
export const FlowChartContainer = styled.canvas `
  width: 100% !important;
  height: 100% !important;
`;
export const CampusChartContainer = styled.canvas `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 120px;
`;
export const Axis = styled.div `
  display: flex;
  justify-content: space-evenly;
  font-size: 4px;
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
  width: 100%;
  bottom: -28px;

  span {
    flex: 1;
    position: relative;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 17px;
    color: #e0e0e0;
    font-weight: 400;
    font-size: 10px;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      display: block;
      right: -1px;
      bottom: 12px;
      width: 1px;
      height: 120px;
      background: linear-gradient(0, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      z-index: 50;
      opacity: 0.1;
    }
  }
`;
export const Container = styled.div `
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto 1fr;

  & .${elementClassNames.labelContainers} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  & .${elementClassNames.labelContainerForYAxis} {
    writing-mode: tb;
    rotate: 180deg;
  }

  &
    .${elementClassNames.labelContainerForYAxis}
    .${elementClassNames.labelSigns} {
    rotate: 180deg;
  }

  & .${elementClassNames.labelTexts} {
    display: block;
    margin: auto;

    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.11px;

    color: ${(props) => props.theme.colors.gray700};
  }

  & .${elementClassNames.labelSigns} {
    display: block;
    color: ${(props) => props.theme.colors.gray2};
  }

  & .${elementClassNames.labelMinusSignContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const PlusSign = styled(CampusIcon).attrs({
    name: 'plusSign'
}) ``;
export const MinusSign = styled(CampusIcon).attrs({
    name: 'minusSign'
}) ``;
