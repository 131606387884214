import styled, { createGlobalStyle } from 'styled-components';
export const CkEditorGlobalStyle = createGlobalStyle `
  :root {
    --ck-z-default: 10555 !important;
    --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
    --ck-focus-ring: 1px solid ${(props) => props.theme.colors.gray4};
  }

  .ck.ck-block-toolbar-button {
    background: #FFFFFF;
    border: 1px solid #999999;
    box-shadow: 0px 0px 3px 2px rgba(255, 255, 255, 0.25);
    border-radius: 6px;
    transform: translateX( -10px );
    
    @media (max-width: 650px) {
      transform: translateX( 0px );
    }
  }
`;
export const DefaultContainer = styled.div `
  width: 100%;
  font-weight: 500;
  overflow: unset !important;

  & > div {
    color: ${(props) => props.theme.colors.gray3};
    box-shadow: none !important;
    border: none !important;
  }

  h2,
  h3,
  h4 {
    color: ${(props) => props.theme.colors.gray700};
    font-weight: bold;

    :first-child {
      margin-top: 0 !important;
    }

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  h2 {
    margin: 32px 0 20px;
  }

  h3 {
    margin: 28px 0 16px;
  }

  h4 {
    margin: 26px 0 14px;
  }

  a {
    color: #457af5;
    font-weight: bold;
    cursor: pointer;

    :hover {
      color: #457af5;
      text-decoration: underline;
    }
  }

  blockquote {
    overflow: hidden;
    padding-right: 0;
    padding-left: 1em;
    margin: 26px 0;
    font-style: italic;
    border-left: 5px solid #457af5;
    font-family: Georgia;
    font-size: 18px;
    color: #457af5;
  }

  figure {
    border-radius: 2px !important;
    display: flex !important;
    flex-direction: column !important;
    width: max-content;
    max-width: 100%;

    img {
      max-width: 100% !important;
      border-radius: 2px;
    }

    figcaption {
      background-color: rgba(69, 122, 245, 0.1) !important;
      text-align: left;
      color: #457af5 !important;

      :focus {
        box-shadow: none !important;
        border: none !important;
      }
    }
  }

  p {
    margin: 0px;
    margin-bottom: 20px !important;

    :last-child {
      margin-bottom: 0 !important;
    }
  }

  & .placeholder {
    cursor: auto;
  }

  & b,
  strong {
    font-weight: ${(props) => props.theme.weight.bold};
  }
`;
export const BorderContainer = styled(DefaultContainer) `
  & > div {
    min-height: 150px;
    padding: 14px 16px;
  }
`;
