export class DataPoint {
    _x;
    _y;
    _group;
    diameter;
    hoverRadiusOffset;
    r;
    constructor(chartModel) {
        if (!chartModel) {
            throw new Error('chartModel must have a value');
        }
        this._group = [chartModel];
        this._x = chartModel.bubbleX;
        this._y = chartModel.bubbleY;
        this.r = chartModel.bubbleRadius;
        this.diameter = this.r + this.r;
        this.hoverRadiusOffset = chartModel.bubbleHoverRadiusOffset;
    }
    get x() {
        return this._x;
    }
    get y() {
        return this._y;
    }
    get radius() {
        return this.r;
    }
    get group() {
        return this._group;
    }
    isCloseEnough(distance) {
        return distance < this.radius;
    }
    addToGroup(p2) {
        this._group = this._group.concat(p2.group);
    }
}
