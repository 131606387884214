class Engagement {
    name;
    currentCount;
    contentCount;
    contentType;
    static fromJson(data) {
        const engagement = new Engagement();
        engagement.name = data.Name;
        engagement.currentCount = data.CurrentCount;
        engagement.contentCount = data.ContentCount;
        engagement.contentType = data.ContentType;
        return engagement;
    }
}
export default Engagement;
