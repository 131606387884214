class PaginationModel {
    items;
    totalCount;
    itemsCount;
    hasNextPage;
    constructor(items, totalCount, itemsCount, hasNextPage) {
        this.items = items;
        this.totalCount = totalCount;
        this.itemsCount = itemsCount;
        this.hasNextPage = hasNextPage;
    }
    static fromJson(classType, data, skipCount) {
        const items = data.Items;
        const result = items.map((item) => classType.fromJson(item));
        const totalCount = data.TotalCount;
        const itemsCount = data.ItemsCount;
        const skippedItems = (skipCount ?? 0) + itemsCount;
        const hasNext = skippedItems < totalCount;
        return new PaginationModel(result, totalCount, itemsCount, hasNext);
    }
}
export default PaginationModel;
