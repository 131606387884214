import { Row } from '@campus/components';
import styled from 'styled-components';
export const DashboardCard = styled.div `
  padding: 14px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: start;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  box-sizing: border-box;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  height: 300px;
  width: 100%;
  min-width: 320px;

  @media ${({ theme }) => theme.mediaQuery.tabletDown} {
    margin-left: 20px;
    ${(props) => props.$lastCard && 'margin-right: 20px;'}
  }
`;
export const Information = styled.p `
  width: 100%;
  margin: 0;
  padding: 11px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 1;
  color: ${({ theme }) => theme.colors.gray3};
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  display: flex;
  justify-content: space-between;
  max-height: 34px;

  & span:last-child {
    font-weight: 700;
    font-size: 18px;
    line-height: 13px;
    color: #003c71;
  }
`;
export const FakeCanva = styled.div `
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.error};
`;
export const Description = styled.p `
  margin: 0;
  align-self: end;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray4};
`;
export const DashboardRow = styled(Row) `
  overflow-x: auto;
  flex-wrap: initial;
  display: grid;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-left: 0px;
  margin-right: 0px;
  gap: 14px;
  grid-template-columns: repeat(3, 1fr);

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(10, 10, 10, 0);
  }

  &::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: rgb(189, 189, 189);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(150, 150, 150);
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }

  @media ${({ theme }) => theme.mediaQuery.tabletDown} {
    grid-template-columns: repeat(4, 1fr);
  }
`;
export const DashboardSectionStyled = styled.section `
  z-index: 1;
  @media ${({ theme }) => theme.mediaQuery.tabletDown} {
    margin-top: -65px;
    margin-right: -20px;
    margin-left: -20px;
  }
`;
export const DivColumnMobile = styled.div `
  display: none;
  @media ${({ theme }) => theme.mediaQuery.tabletDown} {
    display: flex;
    height: 300px;
    width: 20px;
  }
`;
