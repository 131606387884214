import styled from 'styled-components';
import { EditableText } from '@campus/components';
export default styled(EditableText) `
  display: block;
  color: ${(props) => props.theme.colors.gray2};
  background-color: ${(props) => props.theme.colors.background}CC;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  z-index: 200;
`;
