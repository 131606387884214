import React from 'react';
import * as icons from '../../assets/icons';
import { IconHolder, StyledIcon, Attention } from './styles';
const CampusIcon = ({ name, size, pb, color, attention, holderStyle, marginIcon, ...props }) => {
    const IconComponent = icons[name];
    if (!IconComponent) {
        return null;
    }
    return (React.createElement(IconHolder, { "$pb": pb, style: holderStyle },
        React.createElement(StyledIcon, { as: IconComponent, "$color": color, "$size": size, "$margin": marginIcon, ...props }),
        attention && React.createElement(Attention, null)));
};
export default CampusIcon;
