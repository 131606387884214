import styled from 'styled-components';
export const Root = styled.div `
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  background-color: white;

  & div {
    width: 6rem;
    height: 6rem;
  }

  & span {
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: ${(props) => props.theme.colors.gray2};
    margin-top: -8px;
  }

  img {
    width: 110px;
  }
`;
