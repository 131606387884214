import styled from 'styled-components';
export const Root = styled.main `
  width: 100%;
`;
export const MainContainer = styled.div `
  display: flex;
`;
export const DivIcon = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 100%;

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    display: none;
  }
`;
export const Hr = styled.hr `
  border: 0;
  margin: 0;
  height: 1px;
  background-color: #ffffff;
  width: 38px;
`;
