import styled from 'styled-components';
import { Form, InputGroup } from 'react-bootstrap';
export const StyledFormGroup = styled(Form.Group) `
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
`;
export const StyledInputGroup = styled(InputGroup) `
  & > * {
    border-color: ${(props) => props.theme.colors[props.$isError ? 'error' : 'gray4']};
  }

  & > :not(:first-child) {
    border-left: none;
  }

  & > :not(:last-child) {
    border-right: none;
  }

  & .invalid-feedback {
    color: ${(props) => props.theme.colors.error};
  }
`;
export const StyledInput = styled(Form.Control) `
  width: 100%;
  ${(props) => (props.$height ? `height: ${props.$height}px;` : '')}
  color: ${(props) => props.theme.colors.gray2};
  font-size: 14px;
  line-height: 140%;
  border-radius: 6px;
  padding: 0.5rem 0.75rem;

  &::placeholder {
    color: ${(props) => props.theme.colors.gray4};
    font-size: 14px;
    line-height: 140%;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.gray4};
    box-shadow: none;
    color: ${(props) => props.theme.colors.gray2};
  }

  &&::-webkit-calendar-picker-indicator {
    display: none;
  }
`;
export const IconBox = styled(InputGroup.Text) `
  background: transparent;

  &:first-child {
    padding-right: 0px;
  }
`;
