import styled from 'styled-components';
import CampusIcon from '../../../CampusIcon';
export const SkeletonRoot = styled.div `
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
`;
export const Collapse = styled.div `
  padding: 10px 20px 20px 20px;

  section {
    transition: max-height 0.4s ease-in-out;
    max-height: ${(props) => (props.$open ? '100%' : '0px')};
    overflow: hidden;
  }
`;
export const Button = styled.button `
  background: transparent;
  border: none;
  text-align: left;
  padding: 0;
  position: relative;
  display: ${(props) => (props.$variant === 'default' ? 'flex' : 'none')};
  align-items: center;
  max-width: 100%;
  gap: 14px;
  color: #fff;

  & > svg {
    width: 12px;
    height: auto;
    transform: rotate(${(props) => (props.$open ? 180 : 0)}deg);
    transition: transform 0.4s ease-in-out;
  }

  & > div {
    overflow: hidden;
  }

  h1 {
    font-size: 18px;
    font-weight: ${(props) => props.theme.weight.bold};
    color: #fff;
    margin-bottom: 3px;
    overflow: hidden;
    width: 233px;
  }

  h2 {
    font-size: 12px;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: #fff;
    opacity: 0.6;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ChevronIcon = styled(CampusIcon) `
  opacity: 0.4;
  width: 14px;
  height: 9px;
  display: flex;
  margin-top: 10px;
`;
export const MinimizeButton = styled.button `
  margin-top: 15px;
  margin-bottom: 5px;
  background: transparent;
  border: none;
  text-align: left;
  padding: 0;
  position: relative;
  display: ${(props) => (props.$variant === 'default' ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fff;

  svg {
    width: 18px;
    height: auto;

    :last-child {
      transform: rotate(${(props) => (props.$open ? 180 : 0)}deg);
      transition: transform 0.4s ease-in-out;
      width: 12px;
      height: auto;
    }
  }

  /* ${(props) => props.theme.breakpoints.down('md')} {
    display: none;
  } */
`;
