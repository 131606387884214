import styled from 'styled-components';
export const Container = styled.div `
  padding: 14px 0;

  background: white;

  cursor: initial;

  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);

  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.gray200};

  & > :first-child:hover {
    border-radius: 4px 4px 0 0;
  }

  & > :last-child:hover {
    border-radius: 0 0 4px 4px;
  }
`;
export const ItemContainer = styled.div `
  padding: 5px 12px;

  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.gray50};
  }
`;
export const ItemLabel = styled.p `
  margin-bottom: 0;

  white-space: nowrap;

  text-align: start;

  font-size: 14px;
  font-weight: 600;

  line-height: 110%;

  color: ${(props) => props.theme.colors.gray3};
`;
