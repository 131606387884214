import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Radio from '../Radio';
const FormRadio = ({ name, label, required, disabled, options, grid }) => {
    const { control } = useFormContext();
    return (React.createElement(Col, { ...grid },
        React.createElement(Controller, { control: control, name: name, rules: { required }, render: ({ field, fieldState: { error } }) => (React.createElement(Radio, { ...field, options: options, required: !!required, label: label, disabled: disabled, isError: !!error, errorMessage: error?.message })) })));
};
FormRadio.defaultProps = {
    grid: { xs: 6 }
};
export default FormRadio;
