import axios from 'axios';
import { BlobServiceClient } from '@azure/storage-blob';
import { Upload as TusUpload } from 'tus-js-client';
import { v4 as uuidv4 } from 'uuid';
import { NetworkError } from '../exceptions';
import { readUpload } from '../services';
import UploadProvider from './UploadProvider';
import { Response } from '../models';
class Upload {
    variant;
    fileName;
    fileSize;
    variantId;
    fileContent;
    viewUrl;
    provider;
    type;
    howToUpload;
    onError;
    constructor(variant, props) {
        const { variantId, type, provider = UploadProvider.Azure, howToUpload, onError } = props ?? {};
        this.variant = variant;
        this.variantId = variantId ?? uuidv4();
        this.provider = provider;
        this.type = type;
        this.howToUpload = howToUpload ?? undefined;
        this.onError = onError ?? undefined;
    }
    async attrFile(file) {
        this.fileContent = file;
        this.fileName = file.name;
        this.fileSize = file.size;
    }
    async attrBlob(blob, fileName) {
        this.fileContent = blob;
        this.fileName = fileName;
        this.fileSize = blob.size;
    }
    getViewUrl() {
        return this.viewUrl;
    }
    getFileName() {
        return this.fileName;
    }
    getFileSize() {
        return this.fileSize;
    }
    async exec(onProgress) {
        onProgress?.(0);
        const uploadCommonParams = {
            fileName: this.fileName,
            provider: this.provider,
            fileSize: this.fileSize,
            type: this.type
        };
        try {
            const result = this.howToUpload
                ? await this.howToUpload(uploadCommonParams)
                : await readUpload({
                    ...uploadCommonParams,
                    variant: this.variant,
                    variantId: this.variantId
                });
            if (result.error) {
                if (this.onError) {
                    this.onError(result.error);
                    return;
                }
                else {
                    throw result.error.error;
                }
            }
            const mapProvider = {
                Azure: () => this.azureUpload(result.data, onProgress),
                Vimeo: () => this.vimeoUpload(result.data, onProgress)
            };
            return await mapProvider[this.provider]();
        }
        catch (error) {
            if (!this.onError) {
                throw error;
            }
            if (error instanceof Response) {
                this.onError(error.error);
            }
            else if (axios.isAxiosError(error)) {
                this.onError(NetworkError.fromAxiosError(error));
            }
            else {
                this.onError(NetworkError.fromError(error));
            }
        }
    }
    async azureUpload(uploadResult, onProgress) {
        const blobServiceClient = new BlobServiceClient(uploadResult.uploadUrl);
        const containerClient = blobServiceClient.getContainerClient(uploadResult.container);
        const blockBlobClient = containerClient.getBlockBlobClient(this.fileName);
        await blockBlobClient.uploadData(this.fileContent, {
            onProgress: (progress) => onProgress?.(Math.round((progress.loadedBytes / this.fileSize) * 100))
        });
        this.viewUrl = uploadResult.viewUrl;
        return { viewUrl: uploadResult.viewUrl };
    }
    async vimeoUpload(uploadResult, onProgress) {
        await new Promise(async (resolve) => {
            const tusUpload = new TusUpload(this.fileContent, {
                endpoint: uploadResult.uploadUrl,
                uploadUrl: uploadResult.uploadUrl,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                onProgress: (bytesUploaded, bytesTotal) => onProgress?.(Math.round((bytesUploaded / bytesTotal) * 100)),
                onSuccess: () => resolve()
            });
            tusUpload.start();
        });
        this.viewUrl = uploadResult.viewUrl;
        return { viewUrl: uploadResult.viewUrl };
    }
}
export default Upload;
