import styled from 'styled-components';
export const Editor = styled.div `
  padding: 18px;
  gap: 12px;
  padding: 0px;
  width: 100%;
  border: none;
  outline: none;
  margin: -4px;
  padding: 4px;
  border-radius: 4px;
  transition: 0.2s all;

  &:hover,
  &:focus {
    background-color: ${(props) => props.$disabled ? 'transparent' : props.theme.colors[props.$color]};
  }

  ${({ contentEditable }) => contentEditable &&
    `:empty:before {
      content: attr(placeholder);
     }`}
`;
