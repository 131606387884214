class NetworkError {
    message;
    validationErrors;
    error;
    static fromError(e) {
        const networkError = new NetworkError();
        networkError.error = e;
        networkError.message = e.message;
        return networkError;
    }
    static fromAxiosError(error) {
        const networkError = new NetworkError();
        networkError.error = error;
        if (error.response) {
            const errorData = error.response.data;
            networkError.message = errorData.title;
            if (errorData.errors) {
                const errors = Object.keys(errorData.errors).map((key) => errorData?.errors?.[key]?.[0]);
                networkError.validationErrors = errors;
                if (errors?.[0]) {
                    networkError.message = errors[0];
                }
            }
        }
        else {
            networkError.message =
                'Ocorreu um erro que não foi possível ser tratado, tente novamente mais tarde.';
        }
        return networkError;
    }
}
export default NetworkError;
