import { Api } from '@campus/auth';
import { StudyPeriod, PaginationModel, Response } from '../models';
export const listStudyPeriod = async ({ teacherId, studentId, managerId }) => {
    try {
        const response = await Api.INSTANCE.getApi().get(`/studyPeriod`, {
            params: {
                TeacherId: teacherId,
                StudentId: studentId,
                ManagerId: managerId,
                SkipCount: 0,
                MaxResultCount: 1000
            }
        });
        return Response.fromData(PaginationModel.fromJson(StudyPeriod, response.data), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
