import styled from 'styled-components';
export const Box = styled.div `
  display: block;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.gray5};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  min-width: ${(props) => props.$width};
  min-height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius};
  margin-bottom: ${(props) => props.$marginBottom};
  ${(props) => props.$marginRigth ? `margin-rigth: ${props.$marginRigth};` : ''}
  opacity: 0.3;
  color: ${(props) => props.theme.colors.gray1};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    width: 80%;
    background: ${(props) => `linear-gradient(90deg, ${props.theme.colors.gray4}00 0,  ${props.theme.colors.gray4}99 20%, ${props.theme.colors.gray4}E6 60%, ${props.theme.colors.gray4}00)`};
    animation: skeleton-animation 2.5s infinite;
    content: '';
    color: ${(props) => props.theme.colors.gray1};
  }

  @keyframes skeleton-animation {
    100% {
      transform: translateX(150%);
    }
  }
`;
