import React from 'react';
import { Select } from '@campus/components';
import { defaults } from './defaults';
import { labelCustomStyle, selectHeaderStyle } from './styles';
const StageSelect = (props) => {
    const value = props.selectedStage
        ? {
            value: props.selectedStage.id,
            label: `➜ ${props.selectedStage.name}`,
            stage: props.selectedStage
        }
        : null;
    const options = props.allStages.map((s) => ({
        value: s.id,
        label: s.name,
        stage: s
    }));
    function onChange(selectedOption) {
        props.onChange(selectedOption.stage);
    }
    return (React.createElement(Select, { label: "ETAPAS", isClearable: false, value: value, options: options, onChange: onChange, placeholder: defaults.placeholder, noOptionsMessage: () => defaults.noOptionsMessage, labelStyle: labelCustomStyle, styles: selectHeaderStyle }));
};
export default StageSelect;
