import React from 'react';
import { FullSpinner, UnauthorizedError } from '@campus/components';
import { Auth0Provider } from '@auth0/auth0-react';
import { MsalProvider } from '@azure/msal-react';
import AuthorityProvider from '../AuthorityProvider';
import { AuthContext } from './Context';
import { useAuthorization, authConfig } from './hooks/useAuthorization';
const AuthProvider = ({ type, children }) => {
    const { authenticated, error, loading, logout } = useAuthorization();
    if (error)
        return React.createElement(UnauthorizedError, { onRetry: logout });
    if (loading || !authenticated) {
        return React.createElement(FullSpinner, { title: "Carregando dados do usu\u00E1rio..." });
    }
    return (React.createElement(AuthContext.Provider, { value: { logout: logout } },
        React.createElement(AuthorityProvider, { type: type }, children)));
};
const AuthProviderWrapper = ({ type, children }) => {
    const AUTH_PROVIDER = process.env.REACT_APP_AUTH_PROVIDER;
    if (AUTH_PROVIDER === 'AUTH0') {
        return (React.createElement(Auth0Provider, { ...authConfig['AUTH0']() },
            React.createElement(AuthProvider, { type: type }, children)));
    }
    return (React.createElement(MsalProvider, { instance: authConfig['AZURE']() },
        React.createElement(AuthProvider, { type: type }, children)));
};
export default AuthProviderWrapper;
