import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
`;
export const ContainerItem = styled.div `
  display: flex;
  align-items: center;
`;
export const Value = styled.label `
  color: ${({ theme, current }) => (current ? 'white' : theme.colors.gray4)};
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid
    ${({ theme, current }) => current ? theme.colors.blue1 : theme.colors.gray4};
  background-color: ${({ theme, current }) => current ? theme.colors.blue1 : 'transparent'};
  padding-top: 3px;
  margin-right: 9px;
  text-align: center;
`;
export const Description = styled.label `
  color: ${({ theme, current }) => current ? theme.colors.blue1 : theme.colors.gray4};
  padding-top: 3px;
  font-size: 12px;
  font-weight: 500 bold;
  display: flex;
  flex-direction: column;
  line-height: 13.2px;
`;
export const Separator = styled.hr `
  color: ${({ theme, current }) => current ? theme.colors.blue1 : theme.colors.gray4};
  width: 50px;
  margin-left: 9px;
  margin-right: 9px;
  opacity: 1;
  height: 0.5px;
`;
