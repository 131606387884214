import format from 'date-fns/format';
class Stage {
    id;
    name;
    startDate;
    endDate;
    unityId;
    static fromJson(data) {
        const stage = new Stage();
        stage.id = data?.Id;
        stage.name = data?.Name;
        stage.startDate = data?.StartDate;
        stage.endDate = data?.EndDate;
        stage.unityId = data?.UnityId;
        return stage;
    }
    get nameWithDate() {
        const startDate = this.startDate
            ? format(new Date(this.startDate), 'dd/MM/yyyy')
            : '';
        const endDate = this.endDate
            ? format(new Date(this.endDate), 'dd/MM/yyyy')
            : '';
        return `${this.name} - (${startDate} até ${endDate})`;
    }
}
export default Stage;
