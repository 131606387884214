import styled from 'styled-components';
export const ContainerListFiles = styled.div `
  display: flex;
  gap: 5px;
  margin: 5px 0;
  max-width: 100%;
  overflow-x: auto;
  padding: 5px;
`;
export const ContentHover = styled.div `
  position: relative;
  min-width: 160px;
  width: 160px;
  height: 100px;
  cursor: pointer;
`;
export const HoverItem = styled.div `
  display: none;

  ${ContentHover}:hover & {
    position: absolute;
    display: block;
    top: 2px;
    right: 2px;
  } 
`;
