import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.gray5};
  position: relative;

  video {
    width: 100%;
    height: 100%;
  }
`;
export const IFrame = styled.iframe `
@media (max-width: 575.98px) {
  width: 400px;
  height: 400px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  width: 500px;
  height: 500px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  width: 700px;
  height: 700px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  width: 900px;
  height: 900px;
}

@media (min-width: 1200px) {
  width: 1000px;
  height: 1000px;
}
`;
