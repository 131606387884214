import styled from 'styled-components';
export const ClassFilterContainer = styled.div `
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 99;

  p {
    margin: 0;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.gray300};
    font-size: 14px;
  }
`;
