import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Content, Image } from './styles';
const Avatar = ({ name, image, className, size = 56, ...props }) => {
    const [showName, setShowName] = useState(!image);
    const userName = name ?? '';
    return (React.createElement(OverlayTrigger, { overlay: React.createElement(Tooltip, null, userName) },
        React.createElement(Content, { "aria-label": userName, size: size, className: className }, !showName ? (React.createElement(Image, { imageURL: image, onError: () => setShowName(true), ...props })) : (React.createElement("span", null, userName.substring(0, 2))))));
};
export default Avatar;
