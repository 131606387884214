const arrowIconHTML = `
  <svg class="icon" width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M1 7L4 4L1 1" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> 
`;
const ids = Object.freeze({
    tooltips: {
        parent: 'flow-chart-tooltip',
        child: 'flow-chart-tooltip__child'
    }
});
const cssClasses = Object.freeze({
    tooltip: 'campus-tooltip',
    clickable: 'clickable',
    notDisplayed: 'not-displayed',
    justInvisible: 'just-invisible',
    withBgImage: 'with-bg-image',
    withInlinePadding: 'with-inline-padding',
    toTheLeftOfTheBubble: 'to-the-left-of-the-bubble',
    title: 'title',
    feedback: 'feedback',
    performance: 'performance',
    engagement: 'engagement',
    groupItemsList: 'group-items-list',
    listRow: 'list-row',
    listRowText: 'text',
    listRowBubble: 'bubble',
    listRowBubbleText: 'bubble__text'
});
const cssProperties = Object.freeze({
    balloonTip: {
        top: '--balloon-tip-top',
        left: '--balloon-tip-left',
        right: '--balloon-tip-right',
        size: '--balloon-tip-size'
    },
    extraInvisibleSpaces: {
        onTheLeft: '--extra-invisible-space-on-the-left-side',
        onTheRight: '--extra-invisible-space-on-the-right-side',
        onTheOtherSides: '--extra-invisible-space-on-the-other-sides'
    }
});
const arbitrarySpaces = Object.freeze({
    fromParentToBubble: 10,
    fromParentToChild: 10
});
function getOrCreateTooltipElement(id) {
    let element = document.getElementById(id);
    if (!element) {
        element = document.createElement('div');
        element.classList.add(cssClasses.tooltip);
        element.id = id;
        document.body.append(element);
    }
    return element;
}
function fillTooltipWithGeneralContent(tooltipElement, tooltipContent) {
    tooltipElement.classList.remove(cssClasses.notDisplayed);
    tooltipElement.classList.add(cssClasses.withInlinePadding);
    const title = document.createElement('p');
    title.classList.add(cssClasses.title);
    title.textContent = tooltipContent.title;
    const feedback = document.createElement('p');
    feedback.classList.add(cssClasses.feedback);
    feedback.innerHTML = tooltipContent.feedback;
    const performance = document.createElement('p');
    performance.classList.add(cssClasses.performance);
    performance.innerHTML = `<b>Desempenho:</b> ${tooltipContent.performance}`;
    const engagement = document.createElement('p');
    engagement.classList.add(cssClasses.engagement);
    engagement.innerHTML = `<b>Engajamento:</b> ${tooltipContent.engagement}`;
    tooltipElement.replaceChildren(title, engagement, performance, feedback);
}
function setExtraSpacesUpOnParentTooltip(state) {
    // the values of the extraInvisibleSpaces properties
    // are negative because they go from inside to outside,
    // effectively making the item increase its size
    const spaceOnTheOtherSides = -state.dataPoint.diameter + 'px';
    state.tooltips.parent.element.style.setProperty(cssProperties.extraInvisibleSpaces.onTheOtherSides, spaceOnTheOtherSides);
    const inlineSpace = -(state.dataPoint.radius + state.dataPoint.hoverRadiusOffset) + 'px';
    if (state.tooltips.parent.isToTheLeftOfTheBubble) {
        state.tooltips.parent.element.style.setProperty(cssProperties.extraInvisibleSpaces.onTheLeft, state.tooltips.parent.containsGroupItemsList ? '0' : spaceOnTheOtherSides);
        state.tooltips.parent.element.style.setProperty(cssProperties.extraInvisibleSpaces.onTheRight, inlineSpace);
    }
    else {
        state.tooltips.parent.element.style.setProperty(cssProperties.extraInvisibleSpaces.onTheRight, state.tooltips.parent.containsGroupItemsList ? '0' : spaceOnTheOtherSides);
        state.tooltips.parent.element.style.setProperty(cssProperties.extraInvisibleSpaces.onTheLeft, inlineSpace);
    }
}
function setExtraSpacesUpOnChildTooltip(state) {
    const spaceOnTheOtherSides = -state.dataPoint.diameter + 'px';
    state.tooltips.child.style.setProperty(cssProperties.extraInvisibleSpaces.onTheOtherSides, spaceOnTheOtherSides);
    const parentRect = state.tooltips.parent.element.getBoundingClientRect();
    const childRect = state.tooltips.child.getBoundingClientRect();
    if (state.tooltips.parent.isToTheLeftOfTheBubble) {
        state.tooltips.child.style.setProperty(cssProperties.extraInvisibleSpaces.onTheLeft, spaceOnTheOtherSides);
        state.tooltips.child.style.setProperty(cssProperties.extraInvisibleSpaces.onTheRight, -(parentRect.left - childRect.right) + 'px');
    }
    else {
        state.tooltips.child.style.setProperty(cssProperties.extraInvisibleSpaces.onTheRight, spaceOnTheOtherSides);
        state.tooltips.child.style.setProperty(cssProperties.extraInvisibleSpaces.onTheLeft, -(childRect.left - parentRect.right) + 'px');
    }
}
function controlTooltipsVisibility(state) {
    if (state.windowMouseMoveEventHandler) {
        window.removeEventListener('mousemove', state.windowMouseMoveEventHandler);
    }
    state.windowMouseMoveEventHandler = () => {
        if (state.tooltipModel.opacity !== 0) {
            state.tooltips.parent.element.classList.remove(cssClasses.notDisplayed);
            return;
        }
        if (state.tooltips.parent.element.matches(':hover')) {
            state.tooltips.parent.element.classList.remove(cssClasses.notDisplayed);
            return;
        }
        if (state.tooltips.child.matches(':hover')) {
            state.tooltips.parent.element.classList.remove(cssClasses.notDisplayed);
            state.tooltips.child.classList.remove(cssClasses.notDisplayed);
            return;
        }
        state.tooltips.parent.element.classList.add(cssClasses.notDisplayed);
        state.tooltips.child.classList.add(cssClasses.notDisplayed);
        window.removeEventListener('mousemove', state.windowMouseMoveEventHandler);
        state.windowMouseMoveEventHandler = null;
    };
    window.addEventListener('mousemove', state.windowMouseMoveEventHandler);
}
function anyTooltipCausesOverflow({ state, centerOfTheBubble, offsetFromTheCenterOfTheBubble, parentTooltipWidth }) {
    const rightPositionIfOnRightSide = centerOfTheBubble + offsetFromTheCenterOfTheBubble + parentTooltipWidth;
    const parentCausesOverflow = rightPositionIfOnRightSide >= window.innerWidth;
    let childCausesOverflow = false;
    if (!parentCausesOverflow && state.tooltips.parent.containsGroupItemsList) {
        // heuristic: if the parent contains group items list and it does
        // not cause overflow, check if the child would cause overflow with
        // any content inside. To check this, display the child on screen,
        // but invisible to the user, and get its width
        state.tooltips.child.classList.remove(cssClasses.notDisplayed);
        state.tooltips.child.classList.add(cssClasses.justInvisible);
        const content = state.dataPoint.group[0].getTooltipGeneralContent();
        fillTooltipWithGeneralContent(state.tooltips.child, content);
        const childRect = state.tooltips.child.getBoundingClientRect();
        const childRight = rightPositionIfOnRightSide +
            childRect.width +
            arbitrarySpaces.fromParentToBubble;
        childCausesOverflow = childRight >= window.innerWidth;
        state.tooltips.child.classList.add(cssClasses.notDisplayed);
        state.tooltips.child.classList.remove(cssClasses.justInvisible);
    }
    return parentCausesOverflow || childCausesOverflow;
}
function positionParentTooltip(state) {
    const canvas = state.tooltipModel.chart.canvas;
    const canvasRect = canvas.getBoundingClientRect();
    const parentRect = state.tooltips.parent.element.getBoundingClientRect();
    const centerOfTheBubble = canvasRect.left + window.scrollX + state.tooltipModel.caretX;
    const offsetFromTheCenterOfTheBubble = state.dataPoint.radius +
        state.dataPoint.hoverRadiusOffset +
        arbitrarySpaces.fromParentToBubble;
    state.tooltips.parent.element.style.top =
        canvasRect.top +
            window.scrollY +
            state.tooltipModel.caretY -
            state.dataPoint.radius -
            state.dataPoint.hoverRadiusOffset +
            'px';
    const tooltipsCauseOverflow = anyTooltipCausesOverflow({
        state,
        centerOfTheBubble,
        offsetFromTheCenterOfTheBubble,
        parentTooltipWidth: parentRect.width
    });
    if (tooltipsCauseOverflow) {
        state.tooltips.parent.element.style.left =
            centerOfTheBubble -
                parentRect.width -
                offsetFromTheCenterOfTheBubble +
                'px';
        state.tooltips.parent.isToTheLeftOfTheBubble = true;
        state.tooltips.parent.element.classList.add(cssClasses.toTheLeftOfTheBubble);
    }
    else {
        state.tooltips.parent.element.style.left =
            centerOfTheBubble + offsetFromTheCenterOfTheBubble + 'px';
        state.tooltips.parent.isToTheLeftOfTheBubble = false;
        state.tooltips.parent.element.classList.remove(cssClasses.toTheLeftOfTheBubble);
    }
    state.tooltips.parent.element.style.setProperty(cssProperties.balloonTip.top, state.dataPoint.radius + 'px');
}
function positionChildTooltip(state, associatedListRow) {
    const listRowRect = associatedListRow.getBoundingClientRect();
    const iconElement = associatedListRow.lastElementChild;
    const iconRect = iconElement.getBoundingClientRect();
    state.tooltips.child.style.top =
        window.scrollY + listRowRect.top - iconRect.height + 'px';
    const childRect = state.tooltips.child.getBoundingClientRect();
    if (state.tooltips.parent.isToTheLeftOfTheBubble) {
        state.tooltips.child.style.left =
            listRowRect.left +
                window.scrollX -
                childRect.width -
                arbitrarySpaces.fromParentToChild +
                'px';
        state.tooltips.child.classList.add(cssClasses.toTheLeftOfTheBubble);
    }
    else {
        state.tooltips.child.style.left =
            listRowRect.right +
                window.scrollX +
                arbitrarySpaces.fromParentToChild +
                'px';
        state.tooltips.child.classList.remove(cssClasses.toTheLeftOfTheBubble);
    }
    state.tooltips.child.style.setProperty(cssProperties.balloonTip.top, iconRect.height + iconRect.top - listRowRect.top + 'px');
}
function createListRow({ state, chartModel, handlers }) {
    const listRowContent = chartModel.getTooltipListRowContent();
    const bubble = document.createElement('div');
    bubble.classList.add(cssClasses.listRowBubble);
    const bubbleSize = state.dataPoint.diameter + 'px';
    bubble.style.width = bubbleSize;
    bubble.style.height = bubbleSize;
    bubble.style.lineHeight = bubbleSize;
    if (listRowContent.bubble.backgroundImageUrl) {
        bubble.classList.add(cssClasses.withBgImage);
        const bgImageUrl = listRowContent.bubble.backgroundImageUrl;
        bubble.style.backgroundImage = `url(${bgImageUrl})`;
    }
    else {
        bubble.style.background = listRowContent.bubble.backgroundColor;
        const bubbleText = document.createElement('span');
        bubbleText.classList.add(cssClasses.listRowBubbleText);
        bubbleText.textContent = listRowContent.bubble.text;
        bubble.append(bubbleText);
    }
    const text = document.createElement('span');
    text.classList.add(cssClasses.listRowText);
    text.textContent = listRowContent.text;
    const listRow = document.createElement('div');
    listRow.classList.add(cssClasses.listRow);
    listRow.append(bubble, text);
    listRow.insertAdjacentHTML('beforeend', arrowIconHTML);
    listRow.onmouseenter = () => {
        const content = chartModel.getTooltipGeneralContent();
        fillTooltipWithGeneralContent(state.tooltips.child, content);
        positionChildTooltip(state, listRow);
        setExtraSpacesUpOnChildTooltip(state);
        controlTooltipsVisibility(state);
    };
    if (handlers?.onListRowClick) {
        listRow.classList.add(cssClasses.clickable);
        listRow.onclick = () => {
            handlers?.onListRowClick(chartModel.data);
        };
    }
    return listRow;
}
function fillParentTooltipWithListContent(state, handlers) {
    state.tooltips.parent.element.classList.remove(cssClasses.notDisplayed);
    state.tooltips.parent.element.classList.remove(cssClasses.withInlinePadding);
    const groupItemsList = document.createElement('div');
    groupItemsList.classList.add(cssClasses.groupItemsList);
    for (const chartModel of state.dataPoint.group) {
        groupItemsList.append(createListRow({ state, chartModel, handlers }));
    }
    state.tooltips.parent.element.replaceChildren(groupItemsList);
}
export function showTooltip(dataPoint, tooltipModel, handlers) {
    const state = {
        dataPoint,
        tooltipModel,
        tooltips: {
            parent: {
                element: getOrCreateTooltipElement(ids.tooltips.parent)
            },
            child: getOrCreateTooltipElement(ids.tooltips.child)
        }
    };
    state.tooltips.parent.element.classList.remove(cssClasses.notDisplayed);
    state.tooltips.child.classList.add(cssClasses.notDisplayed);
    if (dataPoint.group.length === 1) {
        const chartModel = dataPoint.group[0];
        const content = chartModel.getTooltipGeneralContent();
        fillTooltipWithGeneralContent(state.tooltips.parent.element, content);
        state.tooltips.parent.containsGroupItemsList = false;
    }
    else {
        fillParentTooltipWithListContent(state, handlers);
        state.tooltips.parent.containsGroupItemsList = true;
    }
    positionParentTooltip(state);
    setExtraSpacesUpOnParentTooltip(state);
    controlTooltipsVisibility(state);
}
export function hideTooltip() {
    const parent = document.getElementById(ids.tooltips.parent);
    const child = document.getElementById(ids.tooltips.child);
    if (parent) {
        parent.classList.add(cssClasses.notDisplayed);
    }
    if (child) {
        child.classList.add(cssClasses.notDisplayed);
    }
}
