import styled from 'styled-components';
import { Card } from 'react-bootstrap';
export const StyledCard = styled(Card) `
  border: 1px solid ${(props) => props.theme.colors.gray5};
  box-shadow: ${(props) => props.$disabled ? 'none' : '0px 2px 5px -2px #0000001a'};
  border-radius: 4px;
  background: ${(props) => {
    if (props.$disabled) {
        return 'rgba(255,255,255, 0.3)';
    }
    if (props.$canceled) {
        return 'linear-gradient(to bottom right, transparent calc(50% - 4px), rgba(255,65,112,.3) calc(50% - 3px), rgba(255,65,112,.3) 50%, rgba(255,65,112,.3) calc(50% + 4px), transparent calc(50% + 5px)),  no-repeat 0px 0px / 100% 100%';
    }
    return 'rgba(255,255,255, 1)';
}};
  height: ${(props) => (props.$fullHeight ? '100%' : 'auto')};
  overflow: ${(props) => (props.$overflowHidden ? 'hidden' : 'initial')};
`;
