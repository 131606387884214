import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Content = styled.div `
  display: flex;
  flex-wrap: wrap;
  padding: 9px;
  border: 1px solid ${({ theme }) => theme.colors.gray4};
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  gap: 6px;
`;
export const Item = styled.li `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 7px;
  background: ${({ theme }) => theme.colors.blue1};
  border-radius: 2px;
  gap: 7px;

  & button {
    cursor: pointer;
    transition: 0.2s all;
    padding: 0px;
    background: none;
    border: none;
    display: flex;

    &:hover {
      opacity: 0.6;
    }
  }
`;
export const ButtonAddTag = styled.button `
  background: transparent;
  border: transparent;
  outline: none;
  font-style: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray3};
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  & > img {
    margin-right: 3.5px;
  }
`;
export const InvisibleInput = styled.input `
  border: none;
  outline: none;
`;
export const Suggestions = styled.div `
  z-index: 99999;
  width: 200px;

  ul {
    background-color: #fff;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
    width: 200px;
    max-height: 300px;
    border-radius: 6px;
    padding: 0px;
    list-style-type: none;
    font-size: 12px;
    overflow: auto;
    margin: 0px;

    & > li {
      padding: 0 10px;
      width: 100%;
      padding: 8px 12px;
      color: ${(props) => props.theme.colors.gray3};
    }

    & li > button {
      padding: 8px 12px;
      cursor: pointer;
      background: none;
      border: none;
      width: 100%;
      text-align: left;
      color: ${(props) => props.theme.colors.gray1};

      &:hover {
        background-color: ${(props) => props.theme.colors.gray5};
      }
    }
  }
`;
export const LabelTag = styled.label `
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;
