import React, { useContext, useState, useEffect, createContext, useCallback } from 'react';
import { useContextManager } from '../../ContextManagerProvider';
import useStudentId from '../../../hooks/useStudentId';
import { getDisciplinePendingCount, listStudentDisciplines } from '../../../services/disciplines';
export const StudentClassDisciplinesContext = createContext(undefined);
export const StudentClassDisciplinesProvider = (props) => {
    const [classDisciplines, setClassDisciplines] = useState(null);
    const [loadingClassDisciplines, setLoadingClassDisciplines] = useState(false);
    const { classroom, stage } = useContextManager();
    const studentOrUserLoggerId = useStudentId();
    const studentId = props.studentId ?? studentOrUserLoggerId;
    const getData = useCallback(async (updateLoadingState = true) => {
        if (updateLoadingState)
            setLoadingClassDisciplines(true);
        const result = await listStudentDisciplines(studentId, classroom.id, stage.id);
        if (result.data) {
            setClassDisciplines(result.data);
        }
        else {
            setClassDisciplines([]);
        }
        if (updateLoadingState)
            setLoadingClassDisciplines(false);
    }, [studentId, classroom.id, stage.id]);
    const getDisciplinePendingCountData = useCallback(async (disciplineId) => {
        const result = await getDisciplinePendingCount(studentId, disciplineId);
        if (Number.isInteger(result.data)) {
            setClassDisciplines((prevClassDisciplines) => {
                return prevClassDisciplines.map((classDiscipline) => {
                    if (classDiscipline.id === disciplineId) {
                        classDiscipline.pendingCount = result.data;
                    }
                    return classDiscipline;
                });
            });
        }
    }, [studentId]);
    const reloadDisciplinePendingCount = useCallback((disciplineId) => {
        return getDisciplinePendingCountData(disciplineId);
    }, [getDisciplinePendingCountData]);
    useEffect(() => {
        if (classDisciplines === null) {
            getData(true);
        }
    }, [classDisciplines, getData]);
    return (React.createElement(StudentClassDisciplinesContext.Provider, { value: {
            classDisciplines,
            loadingClassDisciplines,
            reloadDisciplinePendingCount
        } }, props.children));
};
export const useClassDisciplines = () => useContext(StudentClassDisciplinesContext);
