class EditorUpload {
    loader;
    onUpload;
    constructor(loader, onUpload) {
        this.loader = loader;
        this.onUpload = onUpload;
    }
    upload() {
        return this.loader.file.then((file) => new Promise((resolve, reject) => {
            this.onUpload(file)
                .then((res) => {
                resolve({ default: res });
            })
                .catch((err) => {
                reject(err);
            });
        }));
    }
}
export default EditorUpload;
