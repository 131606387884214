import styled from 'styled-components';
export const Root = styled.nav `
  height: 100vh;
  width: ${(props) => (props.$options.variant === 'minimize' ? 72 : 300)}px;
  overflow-y: ${(props) => (props.$options.variant === 'minimize' ? 'hidden' : 'auto')};

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: ${(props) => (props.$options.variant === 'minimize' ? 52 : 300)}px;
  }

  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.leftbar.backgroundTop} 7.5%,
    ${(props) => props.theme.colors.leftbar.backgroundBottom} 32%,
    ${(props) => props.theme.colors.leftbar.backgroundBottom} 32% 100%
  );
  box-sizing: border-box;
  display: flex;
  transition: width 0.2s;
  position: sticky;
  top: 0;

  
`;
export const Content = styled.div `
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  overflow-y: ${(props) => (props.variant === 'minimize' ? 'hidden' : 'auto')};

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }
  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;
export const BlackOpacity = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.sideMenu.opacityBackground};
  opacity: 0.66;
`;
