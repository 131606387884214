import styled from 'styled-components';
const variantMap = {
    added: {
        backgroundContent: 'linear-gradient(270deg, #E2F5EA 61.98%, rgba(226, 245, 234, 0) 100%)',
        background: '#6FCF97'
    },
    default: {
        backgroundContent: 'linear-gradient(270deg, #DAE4FD 61.98%, rgba(218, 228, 253, 0) 100%)',
        background: '#457AF5'
    },
    remove: {
        backgroundContent: 'linear-gradient(270deg, #FBDDDD 61.98%, rgba(251, 221, 221, 0) 100%)',
        background: '#EB5757'
    }
};
export const Box = styled.div `
  width: 100%;
  padding: 8px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
  position: relative;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    background-color: ${(props) => props.theme.colors.gray300};
    left: 17px;
    top: 22px;
    height: 100%;
  }
`;
export const Title = styled.h4 `
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.gray700};
  margin: 0;
  font-weight: ${(props) => props.theme.weight.semiBold};
  padding: 4px 10px;
  padding-left: 30px;
  width: 100%;
  position: relative;
  transition: 0.2s all;
  cursor: pointer;
  pointer-events: ${(props) => (props.$disabled ? 'none' : 'initial')};

  ${({ $variant }) => $variant === 'added' &&
    `background-color: ${variantMap['added'].background}33;`}

  &:hover {
    background-color: ${(props) => variantMap[props.$variant].background}33;
  }

  & strong {
    color: ${(props) => props.theme.colors.blue1};
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & img {
    position: absolute;
    top: 8px;
    left: 17px;
    width: 8px;
    filter: invert(82%) sepia(2%) saturate(0%) hue-rotate(74deg) brightness(154%) contrast(104%);
  }
`;
export const Parents = styled.h5 `
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.colors.gray400};
  font-weight: ${(props) => props.theme.weight.semiBold};
  margin: 0;
  padding: 4px 17px 4px 0;
  padding-left: 30px;
  position: relative;

  & strong {
    color: ${(props) => props.theme.colors.blue1};
    font-weight: ${(props) => props.theme.weight.bold};
  }

  & .circle {
    position: absolute;
    top: 8px;
    left: 14px;
    width: 7px;
    height: 7px;
    background-color: ${(props) => props.theme.colors.gray400};
    border-radius: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #fff;
    left: 14px;
    top: 10px;
    height: 100%;
  }
`;
export const DisciplineName = styled.span `
  text-decoration: underline;
  font-weight: 700;
`;
export const ParentDescription = styled.span `
  font-weight: 800;
  
  &:last-child {
    font-weight: 600;
  }
`;
export const ActionContent = styled.div `
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 177px;
  padding-right: 18px;
  max-width: 50%;
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  background: ${(props) => variantMap[props.$variant].backgroundContent};
  transition: 0.4s all;
`;
export const ActionButton = styled.button `
  padding: 2px;
  background-color: ${(props) => props.theme.colors[props.$variant === 'add' ? 'blue1' : 'error']};
  border-radius: 2px;
  border: none;
  font-size: 10px;
  display: flex;
  text-transform: uppercase;
  color: white;
  line-height: 100%;
  transition: 0.2s all;
  font-weight: ${(props) => props.theme.weight.bold};
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`;
export const AddedLabel = styled.label `
  font-size: 10px;
  display: flex;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.green3};
  line-height: 110%;
  font-weight: ${(props) => props.theme.weight.extraBold};
`;
