import styled from 'styled-components';
export const Header = styled.header `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const Title = styled.p `
  margin: 0;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray2};
`;
export const TimeSeries = styled.p `
  margin: 0;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray4};
`;
export const ContainerRight = styled.div `
  display: flex;
  align-items: right;

  span {
    width: 41px;
    color: ${({ theme }) => theme.colors.gray4};
    text-align: right;
    font-size: 11px;
    font-style: normal;
    font-weight: ${({ theme }) => theme.weight.bold};
    line-height: 100%;
    letter-spacing: 0.11px;
    margin-right: 7px;
  }
`;
export const HowItWorks = styled.span `
  font-size: 11px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: ${(props) => (props.videoOpen ? 'pointer' : 'inherit')};
`;
