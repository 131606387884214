import styled from 'styled-components';
import { Tooltip } from '@campus/components';
export const Image = styled.img `
  width: ${(props) => (props.$size ? `${props.$size}px` : '58px')};
  height: ${(props) => (props.$size ? `${props.$size}px` : '58px')};
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
`;
export const CustomTooltipWrapper = styled(Tooltip) `
  .tooltip-inner {
    border-radius: 4px;
    background: rgba(74, 74, 74, 1);
  }
  .tooltip-arrow::before {
    border-top-color: rgba(74, 74, 74, 1) !important;
  }
`;
