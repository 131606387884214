export var Breakpoints;
(function (Breakpoints) {
    Breakpoints[Breakpoints["xs"] = 0] = "xs";
    Breakpoints[Breakpoints["sm"] = 576] = "sm";
    Breakpoints[Breakpoints["md"] = 768] = "md";
    Breakpoints[Breakpoints["lg"] = 992] = "lg";
    Breakpoints[Breakpoints["xl"] = 1200] = "xl";
    Breakpoints[Breakpoints["xxl"] = 1400] = "xxl";
})(Breakpoints || (Breakpoints = {}));
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
function getBreakpoint(width) {
    if (width >= Breakpoints.xxl) {
        return Breakpoints.xxl;
    }
    else if (width >= Breakpoints.xl) {
        return Breakpoints.xl;
    }
    else if (width >= Breakpoints.lg) {
        return Breakpoints.lg;
    }
    else if (width >= Breakpoints.md) {
        return Breakpoints.md;
    }
    else if (width >= Breakpoints.sm) {
        return Breakpoints.sm;
    }
    return Breakpoints.xs;
}
export default function useWindowDimensions() {
    const windowDimensions = getWindowDimensions();
    return {
        width: windowDimensions.width,
        breakpoint: getBreakpoint(windowDimensions.width),
        isMobile: getBreakpoint(windowDimensions.width) === Breakpoints.xs ||
            getBreakpoint(windowDimensions.width) === Breakpoints.sm ||
            getBreakpoint(windowDimensions.width) === Breakpoints.md
    };
}
