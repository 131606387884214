import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import CampusIcon from '../CampusIcon';
export const StyledCheck = styled(Form.Check) `
  display: flex;
  align-items: center;
  width: 100%;
`;
export const StyledCheckLabel = styled(Form.Check.Label) `
  color: ${(props) => props.theme.colors.gray700};
  font-size: 14px;
  line-height: 140%;
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : 8)}px;
  flex: 1;
  min-width: 0;
  margin-top: 1px;
`;
export const StyledCampusIcon = styled(CampusIcon) `
  margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : 7)}px;
  display: flex;
  align-items: center;
`;
