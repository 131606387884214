import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CampusIcon from '../../../CampusIcon';
import { useSideMenu } from '../../context/SideMenuContext';
import { Collapse, Button, MinimizeButton, ChevronIcon } from './styles';
const Header = ({ title, subtitle, children }) => {
    const [openClassCollapse, setOpenClassCollapse] = useState(false);
    const { options } = useSideMenu();
    const location = useLocation();
    useEffect(() => {
        setOpenClassCollapse(false);
    }, [options.variant]);
    const handleToggleClassCollapse = useCallback(() => {
        setOpenClassCollapse((prev) => !prev);
    }, [location.pathname, openClassCollapse]);
    useEffect(() => {
        if (location.pathname === '/') {
            setOpenClassCollapse(false);
        }
    }, [location.pathname]);
    return (React.createElement(Collapse, { "aria-label": "Selecione uma nova classe", "$open": openClassCollapse },
        React.createElement(Button, { "aria-controls": "list-classroom", "aria-expanded": openClassCollapse, onClick: handleToggleClassCollapse, "$open": openClassCollapse, "$variant": options.variant },
            React.createElement("div", null,
                React.createElement("h1", null, title),
                React.createElement("h2", null, subtitle)),
            React.createElement(ChevronIcon, { name: "chevronDown" })),
        React.createElement(MinimizeButton, { "aria-controls": "list-classroom", "aria-expanded": openClassCollapse, onClick: handleToggleClassCollapse, "$open": openClassCollapse, "$variant": options.variant },
            React.createElement(CampusIcon, { name: "school", size: 18 }),
            React.createElement(ChevronIcon, { name: "chevronDown" })),
        React.createElement("section", { id: "list-classroom", role: "region", tabIndex: -1 }, children)));
};
export default Header;
