import React from 'react';
import { Select } from '@campus/components';
import { defaults } from './defaults';
import { labelCustomStyle, selectHeaderStyle } from './styles';
const ClassroomSelect = (props) => {
    const value = props.selectedClassroom
        ? {
            value: props.selectedClassroom.id,
            label: `➜ ${props.selectedClassroom.name}`,
            classroom: props.selectedClassroom
        }
        : null;
    const options = props.allClassrooms.map((c) => ({
        value: c.id,
        label: c.name,
        classroom: c
    }));
    function onChange(selectedOption) {
        props.onChange(selectedOption.classroom);
    }
    return (React.createElement(Select, { label: "MINHAS TURMAS", isClearable: false, value: value, options: options, onChange: onChange, placeholder: defaults.placeholder, noOptionsMessage: () => defaults.noOptionsMessage, labelStyle: labelCustomStyle, styles: selectHeaderStyle }));
};
export default ClassroomSelect;
