import { Api } from '@campus/auth';
import { Response, UploadResult } from '../models';
export const readUpload = async (options) => {
    try {
        const type = options.type ? `/${options.type}` : '';
        const response = await Api.INSTANCE.getApi().put(`${options.variant}/${options.variantId}/upload${type}`, {
            Provider: options.provider,
            FileName: options.fileName,
            FileSize: options.fileSize
        });
        return Response.fromData(UploadResult.fromJson(response.data, options.provider), response.status);
    }
    catch (e) {
        return Response.error(e);
    }
};
