import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Label from '../Label';
import Check from '../Check';
import FormFeedback from '../FormFeedback';
import { FormCheck, FormLabel } from './styles';
const Radio = React.forwardRef(({ name, label, value, onChange, options, isError, errorMessage, required, ...props }, ref) => {
    const [inputValue, setInputValue] = useState(value);
    useEffect(() => {
        if (inputValue != value) {
            setInputValue(value);
        }
    }, [value]);
    const handleChange = (event) => {
        if (value == null) {
            setInputValue(event.target.value);
        }
        onChange(event);
    };
    return (React.createElement(Form.Group, null,
        label && React.createElement(Label, { required: required }, label),
        options.map((option) => (React.createElement(FormCheck, { key: option.value },
            React.createElement(Check, { ...props, ref: ref, id: `${name}-${option.value}`, name: name, type: "radio", checked: inputValue == option.value, value: option.value, required: required, onChange: (event) => handleChange(event), isInvalid: isError }),
            React.createElement(FormLabel, { "$checked": inputValue === option.value, htmlFor: `${name}-${option.value}` }, option.label)))),
        isError && React.createElement(FormFeedback, null, errorMessage)));
});
export default Radio;
