import { Teacher } from './Teacher';
import { Permission } from './Permission';

export class PermissionFeatureTeacher {
  teacher?: Teacher;
  permission?: Permission;

  static fromJson(data: any): PermissionFeatureTeacher {
    const permissionFeatureTeacher = new PermissionFeatureTeacher();

    permissionFeatureTeacher.teacher = Teacher.fromJson(data?.Teacher);
    permissionFeatureTeacher.permission = Permission.fromJson(data?.Permission);

    return permissionFeatureTeacher;
  }
}
