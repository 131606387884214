class StudyPeriod {
    id;
    name;
    startDate;
    endDate;
    active;
    static fromJson(data) {
        const period = new StudyPeriod();
        period.id = data?.Id;
        period.name = data?.Name;
        period.startDate = data?.StartDate;
        period.endDate = data?.EndDate;
        period.active = data?.Active;
        return period;
    }
}
export default StudyPeriod;
