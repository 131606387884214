import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import SelectAsync from '../SelectAsync';
const FormSelectorAsync = ({ name, label, required, disabled, placeholder, isClearable, getOptions, grid, defaultValue }) => {
    const { control } = useFormContext();
    return (React.createElement(Col, { ...grid },
        React.createElement(Controller, { control: control, name: name, rules: { required }, defaultValue: defaultValue, render: ({ field, fieldState: { error } }) => (React.createElement(SelectAsync, { ...field, loadOptions: getOptions, cacheOptions: true, defaultOptions: true, required: required, label: label, placeholder: placeholder, isDisabled: disabled, isError: !!error, errorMessage: error?.message, isClearable: isClearable })) })));
};
FormSelectorAsync.defaultProps = {
    grid: { xs: 6 }
};
export default FormSelectorAsync;
