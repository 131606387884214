import React, { useState } from 'react';
import { TableRow, TableTd } from '../styles';
const TableBody = ({ table, onClickRow }) => {
    const [selectedRow, setSelectedRow] = useState();
    const { page, prepareRow, getTableBodyProps } = table;
    const handleClickRow = (row) => {
        if (!!onClickRow) {
            setSelectedRow((p) => (p != row.id ? row.id : null));
            onClickRow(row.values);
        }
    };
    return (React.createElement("tbody", { ...getTableBodyProps() }, page.map((row) => {
        prepareRow(row);
        return (React.createElement(TableRow, { ...row.getRowProps(), "$selected": row.id == selectedRow, className: row.id == selectedRow ? 'tr-selected' : '' }, row.cells.map((cell) => (React.createElement(TableTd, { ...cell.getCellProps() }, cell.render('Cell', { onClick: () => handleClickRow(row) }))))));
    })));
};
export default TableBody;
