import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  width: 100%;
`;
export const Tab = styled.button `
  font-size: 16px;
  font-weight: ${(props) => props.theme.weight[props.$active ? 'semiBold' : 'regular']};
  color: ${(props) => props.theme.colors[props.$active ? 'blue1' : 'gray600']};
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 8px 16px;
  border-bottom: 4px solid
    ${(props) => (props.$active ? props.theme.colors.blue1 : 'transparent')};
`;
