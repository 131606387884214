import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
export const Header = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 5px;
  color: ${(props) => props.theme.colors.gray4};
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
`;
export const Title = styled.span `
  flex: 0.5;
`;
export const Step = styled.span `
  text-align: right;
  flex: 0.5;
`;
export const StyleProgressBar = styled(ProgressBar) `
  height: 4px;

  .progress-bar {
    background-color: ${(props) => props.$color || props.theme.colors.gray2};
    border-radius: 2px;
  }
`;
