import styled from 'styled-components';
const variantValues = {
    minimize: { svgDisplay: 'initial', spanDisplay: 'none', textAlign: 'center' },
    default: { svgDisplay: 'none', spanDisplay: 'initial', textAlign: 'left' }
};
export const Button = styled.button `
  color: #ffffff96;
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  padding: 14px 20px;
  box-sizing: border-box;
  border-top: 1px solid #ffffff1a;
  transition: 0.4s all;
  text-align: ${(props) => variantValues[props.$variant].textAlign};

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: flex;
  }

  svg {
    display: ${(props) => variantValues[props.$variant].svgDisplay};
  }

  span {
    display: ${(props) => variantValues[props.$variant].spanDisplay};
  }

  &:hover {
    color: #ffffff;
  }
`;
