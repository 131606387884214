import React from 'react';
import CampusIcon from '../../../CampusIcon';
import { useSideMenu } from '../../context/SideMenuContext';
import { Button } from './styles';
const Option = ({ icon, children }) => {
    const { options: { variant } } = useSideMenu();
    return (React.createElement(Button, { "$variant": variant },
        icon && React.createElement(CampusIcon, { name: icon, size: 18, color: "#fff" }),
        React.createElement("span", null, children)));
};
export default Option;
