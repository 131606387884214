import React, { useCallback, useState } from 'react';
import BAccordion from 'react-bootstrap/Accordion';
export const CollapserContext = React.createContext(null);
const Collapser = ({ children }) => {
    const [opened, setOpened] = useState([]);
    const handleOpen = useCallback((key) => {
        setOpened((prev) => {
            const _key = key.toString();
            if (prev.includes(_key)) {
                return prev.filter((i) => i != _key);
            }
            return [...prev, _key];
        });
    }, []);
    const handleForcedOpen = useCallback((key) => {
        const _key = [key.toString()];
        setOpened(_key);
    }, []);
    return (React.createElement(CollapserContext.Provider, { value: { opened, setOpened: handleOpen, forceOpened: handleForcedOpen } },
        React.createElement(BAccordion, { activeKey: opened }, children)));
};
export default Collapser;
