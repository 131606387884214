import { useCallback, useState } from 'react';
import queryString from 'query-string';
import { Context } from '../models';
import Api from '../api/Api';
import { allContexts, getCurrentContext } from '../services/context';
export const useContext = () => {
    const [context, setContext] = useState();
    const [contexts, setContexts] = useState([]);
    const [loading, setLoading] = useState(true);
    const removeContextQuery = () => {
        const url = queryString.exclude(location.href, [
            'period',
            'tenantId',
            'unityId'
        ]);
        history.replaceState('', document.title, url);
    };
    const compareContext = (a, b) => a.tenantId == b?.tenantId && a.unityId == b?.unityId;
    const loadContexts = useCallback(async (type) => {
        setLoading(true);
        const contexts = await allContexts(type);
        setContexts(contexts);
        if (!context) {
            const current = Context.getLocalContext();
            await saveContext(current ?? contexts[0], type);
        }
        setLoading(false);
    }, []);
    const saveContext = async (context, type) => {
        Api.INSTANCE.updateContext(context);
        sessionStorage.setItem('@campus:context', JSON.stringify(context));
        const current = await getCurrentContext(type);
        Api.INSTANCE.updateContext(current);
        setContext(current);
        removeContextQuery();
    };
    const isCurrentContext = (contextToCompare) => compareContext(contextToCompare, context);
    return {
        loadContexts,
        saveContext,
        isCurrentContext,
        context,
        contexts,
        loading
    };
};
