import React from 'react';
import { Form } from 'react-bootstrap';
import { default as ReactSelectAsync } from 'react-select/async';
import Label from '../Label';
import FormFeedback from '../FormFeedback';
const SelectAsync = React.forwardRef(({ label, required, errorMessage, isError, customPlaceholderColor, ...props }, ref) => {
    return (React.createElement(Form.Group, null,
        label && React.createElement(Label, { required: required }, label),
        React.createElement(ReactSelectAsync, { components: { IndicatorSeparator: null }, styles: {
                placeholder: (base) => ({
                    ...base,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: customPlaceholderColor
                        ? customPlaceholderColor
                        : 'hsl(0, 0%, 50%)'
                })
            }, ...props, ref: ref }),
        isError && React.createElement(FormFeedback, null, errorMessage)));
});
export default SelectAsync;
