import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  align-items: center;
  margin: auto;
  height: 100vh;
  position: relative;
  max-width: 1000px;
`;
export const ErrorText = styled.div `
  color: ${(props) => props.theme.colors.gray1};

  h1 {
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 52px;
  }

  h2 {
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 22px;
    margin-bottom: 25px;
  }
`;
export const ButtonContainer = styled.div `
  display: flex;
  gap: 16px;
`;
