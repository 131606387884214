import styled from 'styled-components';
import { ProgressBar } from '@campus/components';
export const Header = styled.header `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
`;
export const HeaderContent = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const Title = styled.span `
  color: ${(props) => props.theme.colors.gray2};
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;
export const Content = styled.div `
  display: flex;
  flex-direction: column;
  padding: 14px;
  padding-bottom: 20px;
  gap: 10px;
`;
export const WeightContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const WeightHeader = styled.div `
  display: flex;
  align-items: flex-end;
  gap: 5px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: row;
  }
`;
export const WeightTitle = styled.span `
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.theme.colors.gray3};
`;
export const WeightPercentage = styled.span `
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 12px;
  color: ${(props) => props.theme.colors.green3};
`;
export const WeightPercentageText = styled.span `
  width: 12px;
  display: flex;
`;
export const ProgressBarContent = styled(ProgressBar) `
  height: 10px;
  margin-top: 5px;
  border-radius: 2px;
`;
export const ProgressBarItem = styled(ProgressBar) `
  border-radius: 0px;
  && {
    height: 10px;
    background-color: ${(props) => props.$color};
  }
`;
export const MessageWithoutPerformanceBox = styled.div `
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.gray5};
  background: ${(props) => props.theme.colors.gray50};
  font-weight: ${(props) => props.theme.weight.semiBold};
  border-radius: 2px;
`;
export const MessageWithoutPerformance = styled.span `
  font-size: 12px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray3};
  padding: 5px;
  line-height: 11px;
`;
