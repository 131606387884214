import React from 'react';
import { Select } from '@campus/components';
import { defaults } from './defaults';
import { labelCustomStyle, selectHeaderStyle } from './styles';
const StudyPeriodSelect = (props) => {
    const value = props.selectedPeriod
        ? {
            value: props.selectedPeriod.id,
            label: `➜ ${props.selectedPeriod.name}`,
            period: props.selectedPeriod
        }
        : null;
    const options = props.allPeriods.map((p) => ({
        value: p.id,
        label: p.name,
        period: p
    }));
    function onChange(selectedOption) {
        props.onChange(selectedOption.period);
    }
    return (React.createElement(Select, { label: "PER\u00CDODO LETIVO", isClearable: false, value: value, options: options, onChange: onChange, placeholder: defaults.placeholder, noOptionsMessage: () => defaults.noOptionsMessage, labelStyle: labelCustomStyle, styles: selectHeaderStyle }));
};
export default StudyPeriodSelect;
