import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { getIsPathWithoutSideMenu, useSideMenu } from '@campus/components';
import { ContextType } from '@campus/auth';
import AppLeftBar from './AppLeftBar';
import { RootComponent } from './DefaultComponents';
import { MainContainer } from '../styles';
import { useWindowDimensions } from '../../../hooks';
import { MenuContainer } from '../../../components';
const Main = ({ contextType, components }) => {
    const { pathname } = useLocation();
    const { isMobile } = useWindowDimensions();
    const { options, setHiden, setIsFloating, setVariant } = useSideMenu();
    const isPathWithoutSideMenu = getIsPathWithoutSideMenu();
    const Content = components.Content ?? RootComponent;
    const Menu = components.Menu;
    const isManager = useMemo(() => contextType === ContextType.MANAGER, [contextType]);
    const isHome = useMemo(() => pathname === '/', [pathname]);
    const display = useMemo(() => (options.hiden ? 'none' : 'flex'), [options]);
    const position = useMemo(() => {
        if (isMobile || (!isHome && !isManager)) {
            return 'fixed';
        }
        return 'sticky';
    }, [isMobile, isHome, isManager]);
    useEffect(() => {
        setHiden(isMobile);
        if (isHome || isManager) {
            setIsFloating(isMobile);
            setVariant('default');
        }
        else {
            setIsFloating(true);
            setVariant(isMobile ? 'default' : 'minimize');
        }
    }, [isMobile, isHome, isManager]);
    const handlClickContent = () => {
        if (!options.hiden && isMobile) {
            setHiden(true);
        }
    };
    const toggleSideMenuVariant = (variant) => {
        if (!isMobile && !isHome && !isManager) {
            setVariant(variant);
        }
    };
    return (React.createElement(MainContainer, null,
        React.createElement(MenuContainer, { style: { display, position }, onMouseEnter: () => toggleSideMenuVariant('default'), onMouseLeave: () => toggleSideMenuVariant('minimize') },
            React.createElement(AppLeftBar, null),
            Menu ? React.createElement(Menu, null) : null),
        !isHome && !isManager && !isMobile && !isPathWithoutSideMenu && (React.createElement("div", { style: { minWidth: '152px', display } })),
        React.createElement(Content, { style: { overflowX: 'hidden' }, onClick: handlClickContent },
            React.createElement(Outlet, null))));
};
export default Main;
