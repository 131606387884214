import React from 'react';
import { Header, HeaderItem, HeaderList, List, ListItem, ListItemSkeleton, Option } from './components';
import { default as getIsPathWithoutSideMenu } from '../utils/menu';
import { useSideMenu } from './context/SideMenuContext';
import { Root, Content, BlackOpacity } from './styles';
const SideMenu = ({ children, ...props }) => {
    const { options } = useSideMenu();
    const isPathWithoutSideMenu = getIsPathWithoutSideMenu();
    return (React.createElement(React.Fragment, null, !isPathWithoutSideMenu && (React.createElement(Root, { "$options": options, ...props },
        React.createElement(BlackOpacity, null),
        React.createElement(Content, { variant: options.variant }, children)))));
};
export default Object.assign(SideMenu, {
    Header: Header,
    HeaderItem: HeaderItem,
    HeaderList: HeaderList,
    List: List,
    ListItem: ListItem,
    ListItemSkeleton: ListItemSkeleton,
    Option: Option
});
