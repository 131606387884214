import styled from 'styled-components';
import { Form } from 'react-bootstrap';
export const StyledCheck = styled(Form.Check.Input) `
  &&[type='checkbox'] {
    border-radius: 2px;
    border: 2px solid ${(props) => props.theme.colors.gray200};
    margin: 0px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    box-shadow: none;
    cursor: pointer;
  }

  &[type='checkbox']:checked {
    background-color: ${(props) => props.theme.colors.blue1};
    border-color: ${(props) => props.theme.colors.blue1};
    background-size: 12px;
  }

  &&[type='radio'] {
    border: 2px solid ${(props) => props.theme.colors.gray200};
    margin: 0px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    box-shadow: none;
    cursor: pointer;
  }

  &[type='radio']:checked {
    background-color: ${(props) => props.theme.colors.white};
    border: 5px solid ${(props) => props.theme.colors.blue1};
    background-size: 12px;
  }
`;
