import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.gray5};
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;
export const Title = styled.span `
  color: ${(props) => props.theme.colors.gray700};
  font-size: 10px;
  word-break: break-word;
  margin-top: 4px;
  position: absolute;
  bottom: 8px;
  background-color: white;
`;
export const ImageStyled = styled.img `
  max-width: 900px;
  max-height: 500px;
`;
