import React from 'react';
import { StudentClassDisciplinesProvider } from './StudentClassDisciplinesProvider';
import { useStudentClassDisciplines } from './StudentClassDisciplinesProvider/useStudentClassDisciplines';
import { TeacherClassDisciplinesProvider } from './TeacherClassDisciplinesProvider';
import { useTeacherClassDisciplines } from './TeacherClassDisciplinesProvider/useTeacherClassDisciplines';
import Variant from '../../models/Variant';
const ClassDisciplinesProvider = ({ variant, studentId, children }) => {
    switch (variant) {
        case Variant.TEACHER:
            return (React.createElement(TeacherClassDisciplinesProvider, null, children));
        case Variant.STUDENT:
        default:
            return (React.createElement(StudentClassDisciplinesProvider, { studentId: studentId }, children));
    }
};
const hooksVariant = {
    [Variant.TEACHER]: useTeacherClassDisciplines,
    [Variant.MANAGER]: useStudentClassDisciplines,
    [Variant.STUDENT]: useStudentClassDisciplines
};
const useClassDisciplines = (variant) => {
    return hooksVariant[variant]();
};
export { useClassDisciplines, ClassDisciplinesProvider };
