import styled from 'styled-components';
export const HeaderStyled = styled.header `
  width: 100%;
  height: 103px;
  background-color: ${({ theme }) => theme.colors.content.primaryColor};
  padding: 0 20px;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 1020px;
    margin: auto;
    color: white;
  }

  h1 {
    font-size: 24px;
    font-weight: ${(props) => props.theme.weight.bold};
    margin-bottom: 0px;
  }

  h2 {
    font-size: 12px;
    font-weight: ${(props) => props.theme.weight.regular};
    margin-bottom: 11px;
  }
`;
