import styled from 'styled-components';
import Modal from '../Modal';
export const StyledModal = styled(Modal) `
  .modal-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 28px;
    line-height: 130%;
    color: ${(props) => props.theme.colors.gray1};
  }

  .modal-header {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray4} !important;
    padding-bottom: 16px !important;
  }

  span {
    color: ${(props) => props.theme.colors.gray4};
    font-size: 16px;
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.bold};
    line-height: 113.2%;
    letter-spacing: 0.16px;
  }
`;
export const TitleContent = styled.div `
  display: flex;
  flex-direction: column;
`;
