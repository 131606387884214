import { useCallback, useEffect } from 'react';
import { useFlexLayout, usePagination, useRowSelect, useTable } from 'react-table';
const useDataGrid = ({ data, columns, pageCount, onFetchData, renderHooks, onRowsSelected, selectedRowIds = {}, onRowsSelectedRadio }) => {
    const manualPagination = !!onFetchData || !pageCount;
    const tableConfig = {
        data,
        columns,
        manualPagination,
        pageCount,
        initialState: { selectedRowIds },
        stateReducer: (newState, action) => {
            if (onRowsSelectedRadio && action.type === 'toggleRowSelected') {
                newState.selectedRowIds = {
                    [action.id]: true
                };
            }
            return newState;
        }
    };
    const table = useTable(tableConfig, usePagination, useFlexLayout, useRowSelect, renderHooks);
    const { selectedFlatRows } = table;
    const { pageIndex, pageSize } = table.state;
    useEffect(() => {
        if (onRowsSelected) {
            onRowsSelected(selectedFlatRows.map((s) => s.original));
        }
    }, [selectedFlatRows]);
    const onFetch = useCallback(() => {
        if (onFetchData) {
            onFetchData(pageIndex, pageSize);
        }
    }, [onFetchData, pageIndex, pageSize]);
    useEffect(() => {
        onFetch();
    }, [onFetch]);
    return { table, onFetch };
};
export default useDataGrid;
