import styled from 'styled-components';
const sizeMap = {
    medium: {
        fontSize: 10,
        fontWeight: 'extraBold',
        textTransform: 'uppercase',
        padding: '2px 4px 1px 4px',
        top: '-1px'
    },
    large: {
        fontSize: 14,
        fontWeight: '600',
        textTransform: 'lowercase',
        padding: '4px 7px',
        top: '0'
    }
};
export const StyledTag = styled.div `
  display: inline-block;
  position: relative;
  font-size: ${(props) => sizeMap[props.$size].fontSize}px;
  font-weight: ${(props) => props.theme.weight[sizeMap[props.$size].fontWeight]};
  background-color: ${(props) => `${props.theme.colors[props.$color]
    ? `${props.theme.colors[props.$color]}1A`
    : `${props.$color.replace(')', '')}, 0.15)`}`};
  color: ${(props) => props.theme.colors[props.$color] || props.$color};
  text-transform: ${(props) => sizeMap[props.$size].textTransform};
  padding: ${(props) => sizeMap[props.$size].padding};
  top: ${(props) => sizeMap[props.$size].top};
  border-radius: 2px;
  text-align: center;
  line-height: 110%;
`;
