var BreakpointsEnum;
(function (BreakpointsEnum) {
    BreakpointsEnum[BreakpointsEnum["xs"] = 0] = "xs";
    BreakpointsEnum[BreakpointsEnum["sm"] = 576] = "sm";
    BreakpointsEnum[BreakpointsEnum["md"] = 768] = "md";
    BreakpointsEnum[BreakpointsEnum["lg"] = 992] = "lg";
    BreakpointsEnum[BreakpointsEnum["xl"] = 1200] = "xl";
    BreakpointsEnum[BreakpointsEnum["xxl"] = 1400] = "xxl";
})(BreakpointsEnum || (BreakpointsEnum = {}));
export const breakpointsAndCardsPerRow = {
    [BreakpointsEnum.xs]: 2,
    [BreakpointsEnum.sm]: 3,
    [BreakpointsEnum.md]: 4,
    [BreakpointsEnum.lg]: 3,
    [BreakpointsEnum.xl]: 5,
    [BreakpointsEnum.xxl]: 5
};
