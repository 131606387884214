import styled from 'styled-components';
export const Header = styled.header `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const Title = styled.p `
  margin: 0;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray2};
`;
export const TimeSeries = styled.p `
  margin: 0;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray4};
`;
export const HowItWorksContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-column-gap: 5px;
  align-items: center;
`;
export const HowItWorks = styled.span `
  font-size: 11px;
  font-weight: ${({ theme }) => theme.weight.bold};
  line-height: 1;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: ${props => props.videoOpen ? 'pointer' : 'inherit'};
`;
