import React from 'react';
import { Form } from 'react-bootstrap';
import { default as ReactSelect } from 'react-select';
import FormFeedback from '../FormFeedback';
import Label from '../Label';
const Select = React.forwardRef(({ label, required, errorMessage, isError, labelStyle, ...props }, ref) => {
    return (React.createElement(Form.Group, null,
        label && (React.createElement(Label, { required: required, style: labelStyle }, label)),
        React.createElement(ReactSelect, { components: { IndicatorSeparator: null }, styles: {
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                placeholder: (base) => ({
                    ...base,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                })
            }, menuPosition: 'fixed', ...props, ref: ref }),
        isError && React.createElement(FormFeedback, null, errorMessage)));
});
Select.defaultProps = {
    isClearable: true
};
export default Select;
