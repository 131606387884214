import styled from 'styled-components';
export const StyledCardHeader = styled.header `
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
  min-height: 40px;
  display: flex;
  align-items: center;
`;
export const Title = styled.span `
  font-size: 16px;
  font-weight: ${(props) => props.theme.weight.semiBold};
  color: ${(props) => props.theme.colors.blue1};
  margin-left: 16px;
`;
