import styled from 'styled-components';
import { Modal } from '@campus/components';
export const StyledModal = styled(Modal) `
  .modal-dialog {
    height: 100%;
  }

  .modal-content {
    height: 100%;
    background-color: transparent;
  }
`;
StyledModal.Header = styled(Modal.Header) `
  border-bottom: 0px;
  display: flex;
  flex-direction: row-reverse;
`;
export const Container = styled.div `
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
`;
export const CloseImg = styled.img `
  cursor: pointer;
`;
export const IFrame = styled.iframe `
  @media (max-width: 575.98px) {
    width: 400px;
    height: 400px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 500px;
    height: 500px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 700px;
    height: 700px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    width: 900px;
    height: 900px;
  }

  @media (min-width: 1200px) {
    width: 1000px;
    height: 1000px;
  }
`;
