import styled from 'styled-components';
import { Modal, Table } from '@campus/components';

export const DivName = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;

  & :first-child {
    margin-right: 8px;
  }

  & span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;

    color: rgba(79, 79, 79, 0.8);
  }
`;

export const TableStyled = styled(Table)`
  thead {
    display: block;
  }

  tbody {
    display: block;
  }

  tr > td:first-child {
    width: 550px;
  }
  tr > td:last-child {
    width: 75px;
  }
  tr > th:first-child {
    width: 550px;
  }
  tr > th:last-child {
    width: 75px;
  }
  thead > tr:first-child {
    border-top: 1px solid ${(props) => props.theme.colors.gray300};
  }
`;

export const DivPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 1rem;

  span {
    color: ${(props) => props.theme.colors.gray500};
    font-weight: 600;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export const ModalStyled = styled(Modal)`
  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 555px;
  }
`;

export const DivActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const DivSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
