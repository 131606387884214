import mergeTheme from './mergeTheme';
const studentTheme = {
    colors: {
        background: '#F3F3F3',
        leftbar: {
            backgroundTop: '#B7295A',
            backgroundBottom: '#003C71'
        },
        content: {
            primaryColor: '#457AF5',
            background: 'rgba(229, 229, 229, 0.3)'
        }
    }
};
export default mergeTheme(studentTheme);
