import styled from 'styled-components';
export const Content = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  box-shadow: 0px 2px 3px 0px #00000026;
  border-radius: 100%;
  overflow: hidden;
  color: ${(props) => props.theme.colors.gray2};
  font-size: 16pt;
  text-transform: uppercase;
  background: #ffffff66;
  position: relative;
`;
export const Image = styled.div `
  background: url(${(props) => `${encodeURI(props.imageURL)}`});
  background-color: ${({ theme }) => theme.colors.gray1};
  width: 56px;
  height: 56px;
  background-size: cover;
  border-radius: 50%;
`;
