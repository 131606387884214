import React from 'react';
import styled from 'styled-components';
import { Table as BootstrapTable } from 'react-bootstrap';
const TableContainer = styled.div `
  font-size: 14px;

  table {
    margin: 0;
  }

  ${(props) => props.hasScoreBorder
    ? `
  tr > td:nth-child(4) {
    border-left: 2px solid ${props.theme.colors.CSA.darkBlue} !important;
  }

  tr > td:nth-child(5) {
    border-right: 2px solid ${props.theme.colors.CSA.darkBlue} !important;
  }

  tr:last-child > td:nth-child(5),
  tr:last-child  > td:nth-child(4) {
    border-bottom: 2px solid ${props.theme.colors.CSA.darkBlue} !important;
  }`
    : ''}

  thead {
    border-color: ${(props) => props.theme.colors.gray300} !important;
    color: ${(props) => props.theme.colors.gray600};
    font-weight: ${(props) => props.theme.weight.semiBold};

    th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: linear-gradient(
        to right,
        ${(props) => props.theme.colors.gray600} 80%,
        transparent 100%
      );
      background-clip: border-box;
      ${(props) => !props.pdfView
    ? `      
        -webkit-background-clip: text;
        -webkit-text-fill-color: #fff0;
      `
    : 'background: none;'}
    }

    p {
      margin: 0;
    }
  }

  tbody {
    border-color: ${(props) => props.theme.colors.gray300} !important;

    tr {
      color: ${(props) => props.theme.colors.gray600};
      border-top: none;

      td {
        border-color: ${(props) => props.theme.colors.gray300} !important;
      }
    }
  }
`;
const Table = ({ children, $hasScoreBorder, $pdfView, ...props }) => {
    return (React.createElement(TableContainer, { hasScoreBorder: $hasScoreBorder, pdfView: $pdfView },
        React.createElement(BootstrapTable, { hover: !$pdfView, bordered: true, ...props }, children)));
};
export default Table;
