import styled from 'styled-components';
export const HeaderBackground = styled.div `
  display: flex;
  background: linear-gradient(
    313.31deg,
    ${(props) => props.theme.colors.leftbar.backgroundTop} 0%,
    ${(props) => props.theme.colors.leftbar.backgroundBottom} 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${(props) => (props.isStudent ? '114px' : '72px')};
  z-index: 0;
`;
export const HeaderContainer = styled.div `
  display: flex;
  padding-bottom: ${(props) => (props.isStudent ? '85px' : '20px')};
`;
export const HeaderStyled = styled.div `
  ${(props) => (props.isStudent ? 'height: 114px;' : '')}
`;
export const HeaderLogo = styled.img `
  width: 26px;
  z-index: 1;
  cursor: pointer;
`;
export const HeaderTitle = styled.h1 `
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
  width: 100%;
  z-index: 1;
`;
export const VerticalDivider = styled.div `
  border-left: 1px solid #ffffff;
  opacity: 0.3;
  margin: 0 10px;
`;
export const Avatar = styled.div `
  background: url(${(p) => `${encodeURI(p.url)}`});
  background-color: ${({ theme }) => theme.colors.gray1};
  min-width: 32px;
  min-height: 32px;
  background-size: cover;
  border-radius: 50%;
  align-self: end;
  z-index: 1;
`;
