import mergeTheme from './mergeTheme';
const teacherTheme = {
    colors: {
        leftbar: {
            backgroundTop: '#003C71',
            backgroundBottom: '#2AD2C9'
        },
        content: {
            primaryColor: '#457AF5',
            background: 'rgba(229, 229, 229, 0.3)'
        }
    }
};
export default mergeTheme(teacherTheme);
