export var AffectIndex;
(function (AffectIndex) {
    AffectIndex["OPTIONAL"] = "Optional";
    AffectIndex["NO"] = "No";
    AffectIndex["YES"] = "Yes";
})(AffectIndex || (AffectIndex = {}));
class EvaluationType {
    id;
    name;
    shortName;
    affectIndex;
    pluralName;
    static fromJson(data) {
        const evaluationType = new EvaluationType();
        evaluationType.id = data?.Id;
        evaluationType.name = data?.Name;
        evaluationType.shortName = data?.ShortName;
        evaluationType.affectIndex = data?.AffectIndex;
        evaluationType.pluralName = data?.PluralName;
        return evaluationType;
    }
}
export default EvaluationType;
