import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.colors.gray5};
  padding: 10px;

  .iconClick {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
