import styled from 'styled-components';
export const Root = styled.nav `
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
  padding-bottom: 12px;
  height: 100vh;
  width: ${(props) => (props.$variant === 'minimize' ? 70 : 62)}px;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.leftbar.backgroundTop} 7.5%,
    ${(props) => props.theme.colors.leftbar.backgroundBottom} 32%,
    ${(props) => props.theme.colors.leftbar.backgroundBottom} 32% 100%
  );
  box-sizing: border-box;

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    width: 80px;
  }
`;
export const Divider = styled.div `
  display: none;
  width: 38px;
  border-top: 1px solid #fff;
  opacity: 0.3;

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    display: block;
  }
`;
export const Logo = styled.img `
  display: none;
  width: 38px;
  margin: 21px 0px;

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    display: block;
  }
`;
export const Spacing = styled.div `
  flex: 1;
`;
export const ItemsContainer = styled.div `
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  margin: 8px 0px;
`;
export const AvatarBar = styled.div `
  cursor: pointer;
  border-radius: 50%;

  div:hover {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.6);
  }
`;
