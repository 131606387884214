import React from 'react';
import { ToastContainer as Container } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
export const ToastifyGlobalStyle = createGlobalStyle `
  :root {
    --toastify-z-index: 1000001;
  }
`;
const ToastContainer = ({ children }) => {
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(ToastifyGlobalStyle, null),
        React.createElement(Container, { position: "bottom-right", autoClose: 3000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true })));
};
export default ToastContainer;
