import styled from 'styled-components';
export const Root = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px;
`;
export const PlayerButton = styled.button `
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.gray100};
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: 0.2s all;

  &:hover {
    opacity: 0.6;
  }

  & svg {
    display: block;
    width: 16px;
    height: 20px;
    margin-left: ${(props) => (props.$playing ? 0 : '4px')};
    color: ${(props) => props.theme.colors.gray700};
  }
`;
export const MutedButton = styled.button `
  background-color: transparent;
  border: none;
  transition: 0.2s all;
  padding: 0px;

  &:hover {
    opacity: 0.6;
  }

  & svg {
    width: 23px;
    min-width: 23px;
    height: 23px;
    color: ${(props) => props.theme.colors.gray500};
  }
`;
export const Options = styled.div `
  flex: 1;
  overflow: hidden;
`;
export const TitleContent = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Title = styled.span `
  color: ${(props) => props.theme.colors.gray700};
  font-size: 10px;
  word-break: break-word;
  margin-top: 4px;
`;
export const Progress = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;

  .progress {
    height: 6px;
    flex: 1;
  }

  label {
    font-size: 12px;
    line-height: 130%;
    color: ${(props) => props.theme.colors.gray500};

    strong {
      color: ${(props) => props.theme.colors.gray700};
    }
  }
`;
