import mergeTheme from './mergeTheme';
const managerTheme = {
    colors: {
        leftbar: {
            backgroundTop: '#FF6A00',
            backgroundBottom: '#B7295A'
        },
        content: {
            primaryColor: '#FF7700',
            background: 'rgba(229, 229, 229, 0.3)'
        }
    }
};
export default mergeTheme(managerTheme);
