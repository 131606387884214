/* eslint-disable quotes */
import { SelectOption } from '@campus/components';

import { format, parseISO } from 'date-fns';

import { FormModel } from './interfaces';
import PaginatedFilter from './interfaces/PaginatedFilter';

export interface StudyPeriodFilter extends PaginatedFilter {
  name?: string;
}

export type StudyPeriodInput = {
  name: string;
  startDate: Date;
  endDate: Date;
  active?: boolean;
  hideScoreStartDate?: Date;
  hideScoreEndDate?: Date;
  visibleToStudentAt?: string;
  id?: string;
};

export type StudyPeriodDTO = {
  Name: string;
  StartDate: Date;
  EndDate: Date;
  Active?: boolean;
  HideScoreStartDate?: Date;
  HideScoreEndDate?: Date;
  VisibleToStudentAt?: string;
};

export class StudyPeriod implements FormModel<StudyPeriodInput> {
  id: string;

  name: string;

  startDate: Date;

  endDate: Date;

  active?: boolean;

  hideScoreStartDate?: Date;

  hideScoreEndDate?: Date;

  visibleToStudentAt?: string;

  startDateFormated?: string;

  endDateFormated?: string;

  visibleToStudentAtFormated?: string;

  activeFormated?: string;

  static fromJson(data: any): StudyPeriod {
    const period = new StudyPeriod();
    period.id = data?.Id;
    period.name = data?.Name;

    if (data?.StartDate) {
      const startDate = parseISO(data?.StartDate);
      period.startDate = data?.StartDate;
      period.startDateFormated = format(startDate, 'dd/MM/yyyy hh:mm');
    }

    if (data?.EndDate) {
      const endDate = parseISO(data?.EndDate);
      period.endDate = data?.EndDate;
      period.endDateFormated = format(endDate, 'dd/MM/yyyy hh:mm');
    }

    if (data?.VisibleToStudentAt) {
      const visibleToStudentAt = parseISO(data?.VisibleToStudentAt);
      period.visibleToStudentAt = data?.VisibleToStudentAt;
      period.visibleToStudentAtFormated = format(
        visibleToStudentAt,
        'dd/MM/yyyy hh:mm'
      );
    }

    period.hideScoreStartDate = data?.HideScoreStartDate;
    period.hideScoreEndDate = data?.HideScoreEndDate;

    period.active = data?.Active;
    period.activeFormated = data?.Active ? 'Sim' : 'Não';

    return period;
  }

  static fromInputToDTO = (input: StudyPeriod): StudyPeriodDTO => {
    const {
      name,
      startDate,
      endDate,
      hideScoreStartDate,
      hideScoreEndDate,
      visibleToStudentAt
    } = input || {};

    return {
      Name: name,
      StartDate: startDate,
      EndDate: endDate,
      ...(hideScoreStartDate && { HideScoreStartDate: hideScoreStartDate }),
      ...(hideScoreEndDate && { HideScoreEndDate: hideScoreEndDate }),
      ...(visibleToStudentAt && { VisibleToStudentAt: visibleToStudentAt })
    };
  };

  toSelectOption = (): SelectOption => ({
    value: this.id,
    label: this.name
  });

  toInput = (): StudyPeriodInput => ({
    id: this.id,
    name: this.name,
    startDate: this.startDate,
    endDate: this.endDate,
    active: this.active,
    hideScoreStartDate: this.hideScoreStartDate,
    hideScoreEndDate: this.hideScoreEndDate,
    visibleToStudentAt: this.visibleToStudentAt
  });
}
