import styled from 'styled-components';
export const Img = styled.img `
  z-index: 1;

  width: 100%;
  margin: 0.5rem 0;
  border-radius: 4px;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    content: url(${(props) => props.imgMobile});
    margin: 0;
  }
`;
export const ALink = styled.a `
  @media ${(props) => props.theme.mediaQuery.mobile} {
    margin-top: 20px;
  }
`;
