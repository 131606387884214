import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editors from '@campus/editor';
import EditorUpload from './Upload';
import { DefaultContainer, BorderContainer, CkEditorGlobalStyle } from './styles';
export const ContentEditor = ({ className, idElement, content, placeholder, readOnly, withBorder, onChange, onUpload, isStudent = false, disableDebouncing }) => {
    const id = useMemo(() => `ced-${idElement}`, [idElement]);
    const data = useMemo(() => content ?? '', [content]);
    const [editorInstance, setEditorInstance] = useState(null);
    const [editorData, setEditorData] = useState(data);
    const debouceTime = readOnly || isStudent ? 0 : 1000;
    const [editorDataDebounce] = useDebounce(editorData, debouceTime);
    function UploadAdapterPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new EditorUpload(loader, onUpload);
    }
    const config = useMemo(() => ({
        placeholder: placeholder,
        link: { addTargetToExternalLinks: true },
        removePlugins: ['Title', 'MediaEmbed'],
        extraPlugins: [UploadAdapterPlugin]
    }), [placeholder]);
    const handleSave = useCallback(async (data) => {
        onChange?.(data);
    }, [onChange]);
    const handleChange = (editor) => {
        if (readOnly) {
            return null;
        }
        const data = editor.getData();
        setEditorData(data);
        if (disableDebouncing) {
            onChange?.(data);
        }
    };
    useEffect(() => {
        handleSave(editorDataDebounce);
    }, [editorDataDebounce]);
    useEffect(() => {
        if (editorInstance) {
            if (readOnly) {
                editorInstance?.enableReadOnlyMode(id);
            }
            else {
                editorInstance?.disableReadOnlyMode(id);
            }
        }
    }, [readOnly, editorInstance]);
    const CKContainer = withBorder ? BorderContainer : DefaultContainer;
    return (React.createElement(CKContainer, { className: className },
        React.createElement(CkEditorGlobalStyle, null),
        React.createElement(CKEditor, { id: id, editor: Editors.Editor, data: data, config: config, disabled: readOnly, onChange: (_, e) => handleChange(e), onReady: (editor) => setEditorInstance(editor) })));
};
ContentEditor.defaultProps = {
    placeholder: '',
    readOnly: false,
    withBorder: false
};
export default ContentEditor;
