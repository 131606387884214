import React from 'react';
import Skeleton from '../../../Skeleton';
import { useSideMenu } from '../../../SideMenu';
import { Item } from './styles';
export const ListItemSkeleton = () => {
    const { options } = useSideMenu();
    return (React.createElement(Item, { "$variant": options.variant },
        React.createElement(Skeleton, { width: "33px", height: "33px", borderRadius: "100%" }),
        options.variant === 'default' && React.createElement(Skeleton, { width: "70%", height: "15px" })));
};
export default ListItemSkeleton;
