import queryString from 'query-string';
class Context {
    imageUrl;
    bannerImageUrl;
    bannerImageMobileUrl;
    bannerRedirectUrl;
    name;
    tenantId;
    unityId;
    unityName;
    contextType;
    studyPeriodActive;
    studyPeriodId;
    logoUrl;
    constructor(tenantId, unityId, studyPeriodId) {
        this.tenantId = tenantId;
        this.unityId = unityId;
        this.studyPeriodId = studyPeriodId;
    }
    static getLocalContext = () => {
        const search = queryString.parse(location.search);
        if (search?.tenantId && search?.unityId) {
            return new Context(search?.tenantId, search?.unityId, search?.period);
        }
        const localContext = JSON.parse(sessionStorage.getItem('@campus:context'));
        return localContext;
    };
    static fromJson(data) {
        const context = new Context();
        context.imageUrl = data?.ImageUrl;
        context.bannerImageUrl = data?.BannerImageUrl;
        context.bannerImageMobileUrl = data?.BannerImageMobileUrl;
        context.bannerRedirectUrl = data?.BannerRedirectUrl;
        context.name = data?.Name;
        context.tenantId = data?.TenantId;
        context.unityId = data?.UnityId;
        context.unityName = data?.UnityName;
        context.contextType = data?.PersonType;
        context.studyPeriodActive = data?.StudyPeriodActive;
        context.studyPeriodId = data?.StudyPeriodId;
        context.logoUrl = data?.LogoUrl;
        return context;
    }
}
export default Context;
