import React, { useContext, useEffect } from 'react';
import { useAccordionButton } from 'react-bootstrap';
import BAccordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import { CollapserContext } from './Collapser';
const AcordionHeader = styled.div `
  display: flex;
  align-items: center;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
`;
const AcordionButton = styled(BAccordion.Button) `
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
`;
const CollapserItem = ({ eventKey, children, header, open = false }) => {
    const key = eventKey.toString();
    const { opened, setOpened } = useContext(CollapserContext);
    const accordionButton = useAccordionButton(key, () => setOpened(key));
    useEffect(() => open && setOpened(key), [open]);
    return (React.createElement(BAccordion.Item, { eventKey: key },
        React.createElement(AcordionHeader, { className: "accordion-header" },
            header,
            React.createElement(AcordionButton, { onClick: accordionButton })),
        React.createElement(BAccordion.Body, null, opened.includes(key) ? children : null)));
};
export default CollapserItem;
