export const selectHeaderStyle = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        marginTop: 2,
        background: '#FFFFFF',
        border: '1px solid #CCCCCC',
        boxShadow: '0px 2px 5px -2px rgba(0, 0, 0, 0.1)',
        borderRadius: 4
    }),
    control: () => ({
        display: 'flex',
        border: '1px solid rgba(255, 255, 255, 0.4)',
        borderRadius: '4px',
        height: '29px',
        alignItems: 'center',
        paddingTop: 1
    }),
    dropdownIndicator: (_, state) => ({
        color: 'rgba(255, 255, 255, 0.4)',
        width: 25,
        height: 27
    }),
    singleValue: (base) => ({
        ...base,
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '110%;',
        color: '#ffffff'
    }),
    option: (base, state) => ({
        ...base,
        '&:hover': {
            background: '#F2F2F2'
        },
        color: '#828282',
        fontFamily: 'Gilroy',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '110%',
        backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.4)' : 'white'
    })
};
export const labelCustomStyle = {
    marginTop: '20px',
    marginBottom: '5px',
    fontFamily: 'Gilroy',
    fontWeight: 600,
    fontSize: '12px',
    fontStyle: 'normal',
    lineHeight: '110%',
    color: '#ffffff',
    opacity: 0.6
};
