import generateColor from '../utils/generateColor';
import DisciplineEvaluation from './DisciplineEvaluation';
import PerformanceStatus from './PerformanceStatus';
import Engagement from './Engagement';
class Discipline {
    id;
    name;
    contentCount;
    color;
    pendingCount;
    currentCount;
    performanceStatus;
    evaluations;
    engagement;
    totalWeight;
    currentWeight;
    currentEngagement;
    averageScore;
    weightEvaluationsAvarege;
    newContentAvailable;
    flowFeedback;
    showPerformance;
    constructor() {
        this.evaluations = [];
    }
    static fromJson(data) {
        const discipline = new Discipline();
        discipline.id = data?.Id;
        discipline.name = data?.Name;
        discipline.contentCount = data?.ContentCount;
        discipline.currentCount = data?.CurrentCount;
        discipline.totalWeight = data?.TotalWeight;
        discipline.currentWeight = data?.CurrentWeight;
        discipline.currentEngagement = data?.CurrentEngagement;
        discipline.pendingCount = data?.PendingCount;
        discipline.performanceStatus = data?.PerformanceStatus;
        discipline.newContentAvailable = data?.NewContentAvailable;
        discipline.flowFeedback = data?.FlowFeedback;
        discipline.showPerformance = data?.ShowPerformance;
        discipline.evaluations =
            data?.Evaluation?.map((item) => DisciplineEvaluation.fromJson(item)) ??
                [];
        discipline.engagement = Discipline.engagementFromJson(data?.Engagement);
        discipline.averageScore = data?.AverageScore;
        discipline.setColor();
        return discipline;
    }
    static engagementFromJson(data) {
        const engagement = data
            ?.map((e) => Engagement.fromJson(e))
            .filter((e) => e.currentCount != null);
        return engagement ?? [];
    }
    setColor() {
        this.color = generateColor(`${this.name}${new Date().getDate()}${new Date().getMonth()}`);
    }
    performanceStatusColor = () => {
        switch (this.performanceStatus) {
            case PerformanceStatus.LOW:
                return 'error';
            case PerformanceStatus.HIGH:
                return 'green3';
            default:
                return '';
        }
    };
}
export default Discipline;
