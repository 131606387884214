import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, getIsPathWithoutSideMenu, OverlayTrigger, Popover } from '@campus/components';
import LogoImage from '@campus/components/assets/logo.svg';
import { useAuthority } from '@campus/auth';
import { useWindowDimensions } from '../../hooks';
import UserPopover from '../../components/UserPopover';
import { Root, Logo, Divider, Spacing, ItemsContainer, AvatarBar } from './styles';
const LeftBar = ({ children, ...props }) => {
    const { currentContext } = useAuthority();
    const userImage = currentContext?.userImage;
    const userName = currentContext?.userName;
    const { isMobile } = useWindowDimensions();
    const isPathWithoutSideMenu = getIsPathWithoutSideMenu();
    return (React.createElement(React.Fragment, null, !isPathWithoutSideMenu && (React.createElement(Root, { ...props },
        React.createElement(Link, { to: "/" },
            React.createElement(Logo, { alt: "Campus", src: LogoImage })),
        React.createElement(Divider, null),
        React.createElement(ItemsContainer, null, children),
        React.createElement(Spacing, null),
        React.createElement(OverlayTrigger, { trigger: "click", placement: "right", rootClose: true, overlay: React.createElement(Popover, { arrowProps: { top: -8 } },
                React.createElement(UserPopover, null)) },
            React.createElement(AvatarBar, null,
                React.createElement(Avatar, { image: userImage, name: userName, size: isMobile ? 38 : 56 })))))));
};
export default LeftBar;
