import styled from 'styled-components';
const variantValues = {
    minimize: {
        padding: '6px 2px',
        justifyContent: 'center',
        textDisplay: 'none',
        badgePosition: 'absolute'
    },
    default: {
        padding: '6px 14px',
        justifyContent: 'initial',
        textDisplay: 'initial',
        badgePosition: 'relative'
    }
};
export const Item = styled.li `
  display: flex;
  flex-direction: row;
  padding: ${(props) => variantValues[props.$variant].padding};
  justify-content: ${(props) => variantValues[props.$variant].justifyContent};
  align-items: center;
  gap: 9px;
  position: relative;
  line-height: 110%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    & div {
      width: 26px;
      min-width: 26px;
      height: 26px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 13px;
    background-color: ${(props) => props.$performanceStatusColor && props.$weightEvaluationsAvarege > 0
    ? props.theme.colors[props.$performanceStatusColor]
    : ''};
    left: -6px;
    display: ${(props) => (props.$performanceStatusColor ? 'block' : 'none')};
  }

  &:hover {
    background: #ffffff25;
    border-radius: 4px;
  }

  /* ${(props) => props.theme.breakpoints.down('md')} {
    padding: ${variantValues.default.padding};
    justify-content: ${variantValues.default.justifyContent};
  } */
`;
export const Text = styled.span `
  color: white;
  font-weight: 700;
  font-size: 16px;
  flex: 1;
  display: ${(props) => variantValues[props.$variant].textDisplay};

  /* ${(props) => props.theme.breakpoints.down('md')} {
    display: ${variantValues.default.textDisplay};
  } */
`;
export const Badge = styled.span `
  background: #ffffff66;
  min-width: 23px;
  border-radius: 92px;
  font-size: 12px;
  font-weight: ${(props) => props.theme.weight.bold};
  text-align: center;
  color: #fff;
  align-self: space-between;
  position: ${(props) => variantValues[props.$variant].badgePosition};
  bottom: 0;
  right: 0;

  /* ${(props) => props.theme.breakpoints.down('md')} {
    position: ${variantValues.default.badgePosition};
  } */
`;
