import styled from 'styled-components';
import { Form } from 'react-bootstrap';
export const FormSwitch = styled(Form.Check) `
  height: 16px;
  padding-left: 26px;
  display: flex;
  align-items: center;

  && input {
    background-color: ${(props) => props.theme.colors.gray300};
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    border: none;
    height: 16px;
    width: 26px;
    background-position: 1.5px center;
    background-size: 14px;
    box-shadow: none;
    margin-left: -26px;
    margin-top: 0;
  }

  && input:focus {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
  }

  && input:checked {
    background-color: ${(props) => props.theme.colors.blue1};
    background-position: 10px center;
  }

  & > label {
    color: ${(props) => props.theme.colors.gray700};
    font-size: 14px;
    line-height: 150%;
    margin-left: 8px;
  }
`;
