import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  CampusIcon,
  Checkbox,
  Input,
  Pagination,
  toast
} from '@campus/components';

import {
  getPermissionFeatureStudent,
  setPermissionFeature,
  togglePermissionFeature
} from 'repositories/PermissionRepository';
import { PermissionFeatureStudent } from 'models/PermissionFeatureStudent';
import PermissionType from 'utils/PermissionType';
import UserAccessType from 'utils/UserAccessType';

import { usePagination } from 'hooks/usePagination';

import {
  DivName,
  DivPage,
  TableStyled,
  PaginationContainer,
  ModalStyled,
  DivActions,
  DivSearch
} from './styles';

type Props = {
  show: boolean;
  setShowModal: (value: boolean) => void;
};

const ModalPermissionStudent: React.FC<Props> = ({ show, setShowModal }) => {
  const [search, setSearch] = useState('');
  const [textSearched, setTextSearched] = useState('');
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState<PermissionFeatureStudent[]>(
    []
  );

  const {
    page,
    totalPages,
    pageSize,
    goToFirstPage,
    goToNextPage,
    goToLastPage,
    goToPrevPage,
    setPage,
    setTotalPages
  } = usePagination();

  const loadDataStudent = useCallback(
    async (name?: string) => {
      setLoading(true);
      const response = await getPermissionFeatureStudent(
        page,
        pageSize,
        PermissionType.CALCULATOR_MDC_ACCESS,
        name
      );
      setStudentData(response?.data?.items ?? []);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      setLoading(false);
    },
    [page, pageSize, setLoading, setTotalPages]
  );

  useEffect(() => {
    if (show) {
      loadDataStudent();
    }
  }, [loadDataStudent, show]);

  const handleChangeStudentPermission = useCallback(
    async (permissionStudent: PermissionFeatureStudent) => {
      if (permissionStudent.permission?.id) {
        const resultUpdate = await togglePermissionFeature(
          permissionStudent.permission,
          UserAccessType.Student
        );

        const updatedItem = resultUpdate.data;

        setStudentData((currentValue) => {
          return currentValue.map((item) => {
            return item.student.user.id === updatedItem.userId
              ? { ...item, permission: updatedItem }
              : item;
          });
        });
      } else {
        const result = await setPermissionFeature(
          PermissionType.CALCULATOR_MDC_ACCESS,
          !permissionStudent.permission.enabled,
          permissionStudent.student.user.id,
          null,
          UserAccessType.Student
        );

        const createdItem = result.data;

        setStudentData((currentValue) => {
          return currentValue.map((item) => {
            return item.student.user.id === createdItem.userId
              ? { ...item, permission: createdItem }
              : item;
          });
        });
      }

      toast.success('Permissão alterada com sucesso');
    },
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (textSearched !== e.target.value) {
      if (e.target.value === '') {
        loadDataStudent();
      } else {
        loadDataStudent(search);
      }
      setTextSearched(e.target.value);
      setPage(1);
    }
  };

  return (
    <ModalStyled show={show} onHide={() => setShowModal(false)} size="lg">
      <ModalStyled.Header closeButton>
        <ModalStyled.Title>Gerenciar permissoes de Aluno</ModalStyled.Title>
      </ModalStyled.Header>
      <ModalStyled.Body>
        {!loading && (
          <>
            <DivSearch>
              <Input
                placeholder="Pesquisar aluno"
                onBlur={handleSearch}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                name="search"
                StartIcon={<CampusIcon name="search" />}
              />
            </DivSearch>
            <TableStyled>
              <thead>
                <tr>
                  <th>Aluno</th>
                  <th>Acesso</th>
                </tr>
              </thead>
              <tbody>
                {studentData.map((value) => (
                  <tr key={value.student.id}>
                    <td>
                      <div>
                        <DivName>
                          <span>{value.student.user.name}</span>
                        </DivName>
                      </div>
                    </td>
                    <td>
                      <Checkbox
                        checked={value.permission.enabled}
                        name={`permission-${value.student.id}`}
                        onChange={() => {
                          handleChangeStudentPermission(value);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableStyled>
            <PaginationContainer>
              <DivPage>
                <span>
                  Página{' '}
                  <strong>
                    {page} de {totalPages}
                  </strong>
                </span>
              </DivPage>
              <Pagination>
                <Pagination.First
                  onClick={goToFirstPage}
                  disabled={page === 1}
                />
                <Pagination.Prev onClick={goToPrevPage} disabled={page === 1} />
                <Pagination.Next
                  onClick={goToNextPage}
                  disabled={page === totalPages}
                />
                <Pagination.Last
                  onClick={goToLastPage}
                  disabled={page === totalPages}
                />
              </Pagination>
            </PaginationContainer>
          </>
        )}
      </ModalStyled.Body>
      <ModalStyled.Footer>
        <DivActions>
          <Button onClick={() => setShowModal(false)} variant="secondary">
            Fechar
          </Button>
        </DivActions>
      </ModalStyled.Footer>
    </ModalStyled>
  );
};

export default ModalPermissionStudent;
