import styled from 'styled-components';
export const MenuRoot = styled.div `
  background: ${(props) => props.theme.colors.gray50};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 4px;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 32px;
  padding: 0 12px;
`;
export const MenuOption = styled.button `
  background: transparent;
  border: none;
  padding: 0;
  color: ${(props) => props.theme.colors.gray700};
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    transition: all 0.2s ease-in-out;
  }

  &:hover:not([disabled]) svg {
    opacity: 0.6;
  }

  &:disabled svg {
    opacity: 0.4;
  }
`;
