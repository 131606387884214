import styled from 'styled-components';
export const ImportantInfo = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray500};
  line-height: 130%;
`;
export const SmallTitle = styled.h2 `
  color: ${(props) => props.theme.colors.gray2};
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
`;
export const ImportantInfoTile = styled.span `
  margin: 0;

  b {
    color: ${(props) => props.$color};
  }
`;
export const TablePanel = styled.div `
  background-color: white;
  padding: 14px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #cccccc;
  width: max-content;
`;
export const TableContainer = styled.div `
  padding: 10px 0 0px 0;
  width: max-content;

  table {
    border: 1px solid ${(props) => props.theme.colors.gray300};
    border-collapse: collapse;
    border-left: 0;
    border-right: 0;
    border-top-width: 2px;
    border-bottom-width: 2px;
  }

  th,
  td {
    border: 1px solid ${(props) => props.theme.colors.gray300};
  }

  td {
    padding: 4px 8px;
    vertical-align: middle;
  }

  th:first-child,
  td:first-child {
    border-left: 0 !important;
    border-right-width: 2px !important;
  }

  th:last-child,
  td:last-child {
    border-right: 0 !important;
    border-left-width: 2px !important;
  }

  tr:nth-child(2) th:first-child {
    border-right-width: 1px !important;
  }
`;
export const TableHeadTitle = styled.th `
  font-size: 18px;
  vertical-align: middle;
  width: 100%;
  line-height: 120%;
  font-weight: 600;
`;
export const TableHead = styled.th `
  white-space: normal !important;
  vertical-align: middle;
  text-align: center;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  line-height: 110%;
  font-size: 14px;
  font-weight: 600;
  word-spacing: 9999999px;

  color: ${(props) => props.$color};
`;
export const TableSubHead = styled.th `
  white-space: normal !important;
  vertical-align: middle;
  text-align: center;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  line-height: 120%;
  font-size: 14px;
  font-weight: 600;
  background-color: #f2f2f2 !important;
  padding: 5px 6px 5px !important;
  border-top-width: 2px !important;
  color: ${(props) => props.$color};
`;
export const TablePercentage = styled.td `
  text-align: center;
  font-size: 12px;
  font-weight: 600;
`;
export const TableTd = styled.td `
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border: 0 !important;
  color: ${(props) => props.showTotal
    ? props.total < 60
        ? '#EB5757'
        : props.total >= 85
            ? '#27AE60'
            : 'inherit'
    : 'inherit'};
  background-color: ${(props) => props.showTotal
    ? props.total < 60
        ? 'rgba(235, 87, 87, .15)'
        : props.total >= 85
            ? 'rgba(111, 207, 151, .2)'
            : 'inherit'
    : 'inherit'} !important;
`;
export const DisciplineTitle = styled.td `
  align-items: center;
`;
export const DisciplineTitleContainer = styled.div `
  display: flex;
  align-items: center;
  width: max-content;
`;
export const DisciplineColor = styled.div `
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  margin-right: 2px;
`;
export const ReportHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
`;
export const ReportHeaderContent = styled.div `
  display: flex;
`;
export const ReportHeaderLeft = styled.div `
  display: flex;
  flex-direction: column;
  border-right: 2px solid #000000;
  padding-right: 20px;
  margin-right: 20px;

  label {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 13.5px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
  }

  h1 {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    color: #000000;
    margin-bottom: 5px;
  }
`;
export const ReportHeaderRight = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 3px;

  label {
    font-family: 'Gilroy';
    font-style: normal;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
  }

  strong {
    font-weight: 800;
    margin-right: 5px;
  }
`;
export const EntityContent = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EntityImage = styled.img `
  width: auto;
  height: 50px;
`;
export const ReportFooterLabel = styled.label `
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: rgba(0, 0, 0, 0.48);
`;
export const TableFooter = styled.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 2px;
`;
export const DisciplineText = styled.div `
  color: ${(props) => props.theme.colors.gray500};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-left: 3px;
  white-space: nowrap;
  width: 470px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DivPage = styled.div `
  width: fit-content;
`;
