import React from 'react';

import {
  StudentIcon,
  CalendarIcon,
  ClassificationIcon,
  ClassroomIcon,
  GradeIcon,
  Grid1x2Fill,
  JournalBookmarkFill,
  ManageSearchIcon,
  ManagerIcon,
  SchoolIcon,
  SegmentIcon,
  TeacherIcon,
  BuildingHouseIcon,
  ProfileIcon
} from 'icons/icons';

import Unit from 'pages/Unit';
import Period from 'pages/Period';
import EducationLevel from 'pages/EducationLevel';
import Segment from 'pages/Segment';
import Discipline from 'pages/Discipline';
import DisciplineMatrix from 'pages/DisciplineMatrix';
import Student from 'pages/Student';
import Teacher from 'pages/Teacher';
import Manager from 'pages/Manager';
import EvaluationMatrix from 'pages/EvaluationMatrix';
import ClassificationType from 'pages/ClassificationType';
import Classroom from 'pages/Classroom';
import Grade from 'pages/Grade';
import Stage from 'pages/Stage';
import Tenant from 'pages/Tenant';
import Profile from 'pages/Profile';
import ClassroomDiscipline from 'pages/ClassroomDiscipline';
import EvaluationTypeDescription from 'pages/EvaluationTypeDescription';
import DigitalObject from 'pages/DigitalObject';
import StudyPeriod from 'pages/StudyPeriod';
import CalculatorMdc from 'pages/CalculatorMdc';
import LiteraryWork from 'pages/LiteraryWork';

const routes = [
  {
    name: 'Estudantes',
    path: 'student',
    description: 'Gerenciamento de estudantes',
    component: <Student />,
    Icon: StudentIcon
  },
  {
    name: 'Gestores',
    path: 'manager',
    description: 'Gerenciamento de gestores',
    component: <Manager />,
    Icon: ManagerIcon
  },
  {
    name: 'Professores',
    path: 'teacher',
    description: 'Gerenciamento de professores',
    component: <Teacher />,
    Icon: TeacherIcon
  },
  {
    name: 'Perfis',
    path: 'profile',
    description: 'Gerenciamento dos perfis',
    component: <Profile />,
    Icon: ProfileIcon
  },
  {
    name: 'Etapas',
    path: 'stage',
    description: 'Gerenciamento das etapas',
    component: <Stage />,
    Icon: CalendarIcon
  },
  {
    name: 'Turmas',
    path: 'classroom',
    description: 'Gerenciamento de turmas',
    component: <Classroom />,
    Icon: ClassroomIcon
  },
  {
    name: 'Matrizes de Avaliação',
    path: 'evaluationMatrix',
    description: 'Gerenciamento das matrizes de avaliação',
    component: <EvaluationMatrix />,
    Icon: Grid1x2Fill
  },
  {
    name: 'Classificação dos objetivos de aprendizagem',
    path: 'classificationType',
    description: 'Gerenciamento de Classificação dos objetivos de aprendizagem',
    component: <ClassificationType />,
    Icon: ClassificationIcon
  },
  {
    name: 'Níveis de Ensino',
    path: 'educationLevel',
    description: 'Gerenciamento de níveis de ensino',
    component: <EducationLevel />,
    Icon: ManageSearchIcon
  },
  {
    name: 'Segmentos',
    path: 'segment',
    description: 'Gerenciamento de segmentos',
    component: <Segment />,
    Icon: SegmentIcon
  },
  {
    name: 'Séries',
    path: 'grade',
    description: 'Gerenciamento de séries',
    component: <Grade />,
    Icon: GradeIcon
  },
  {
    name: 'Turno',
    path: 'period',
    description: 'Gerenciamento de turnos',
    component: <Period />,
    Icon: CalendarIcon
  },
  {
    name: 'Componentes Curriculares',
    path: 'discipline',
    description: 'Gerenciamento de componentes curriculares',
    component: <Discipline />,
    Icon: JournalBookmarkFill
  },
  {
    name: 'Turma / componente curricular',
    path: 'classroomDiscipline',
    description: 'Gerenciamento de turma/componente curricular',
    component: <ClassroomDiscipline />,
    Icon: JournalBookmarkFill
  },
  {
    name: 'Percentuais de avalição',
    path: 'disciplineMatrix',
    description: 'Gerenciamento de componentes curriculares',
    component: <DisciplineMatrix />,
    Icon: JournalBookmarkFill
  },
  {
    name: 'Unidades',
    path: 'unit',
    description: 'Gerenciamento de unidades',
    component: <Unit />,
    Icon: SchoolIcon
  },
  {
    name: 'Inquilinos',
    path: 'tentant',
    description: 'Gerenciamento de inquilinos',
    component: <Tenant />,
    Icon: BuildingHouseIcon
  },
  {
    name: 'Tipos de Avaliação',
    path: 'EvaluationType',
    description: 'Gerenciamento dos tipos de avaliação',
    component: <EvaluationTypeDescription />,
    Icon: SchoolIcon
  },
  {
    name: 'Períodos letivos',
    path: 'StudyPeriod',
    description: 'Gerenciamento de períodos letivos',
    component: <StudyPeriod />,
    Icon: SchoolIcon
  },
  {
    name: 'Objetos Digitais',
    path: 'digital-object',
    description: 'Gerenciamento dos objetos digitais',
    component: <DigitalObject />,
    Icon: SchoolIcon
  },
  {
    name: 'Campus vestibular',
    path: 'LiteraryWork',
    description: 'Gerenciamento de permissões do Campus vestibular',
    component: <LiteraryWork />,
    Icon: ProfileIcon
  },
  {
    name: 'Calculadora MDC',
    path: 'CalculatorMDC',
    description: 'Gerenciamento de permissões da Calculadora MDC',
    component: <CalculatorMdc />,
    Icon: ProfileIcon
  }
];

export default routes;
