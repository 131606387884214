import User from './User';
export var PersonType;
(function (PersonType) {
    PersonType["TEACHER"] = "Teacher";
    PersonType["STUDENT"] = "Student";
})(PersonType || (PersonType = {}));
class Person {
    contextType;
    personId;
    user;
    static fromJson(data) {
        const person = new Person();
        person.contextType = data.AccessType;
        person.personId = data.PersonId;
        person.user = User.fromJson(data?.User);
        return person;
    }
}
export default Person;
