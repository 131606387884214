import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import Table from '../Table';
import Checkbox from '../Checkbox';
import TablePlaceholder from './components/TablePlaceholder';
import TableBody from './components/TableBody';
import TableFooter from './components/TableFooter';
import useDataGrid from './hooks/useDataGrid';
import { Container, CheckboxContainer, TableTh } from './styles';
const DataGrid = ({ data, loading, columns, pageCount, onClickRow, onRowsSelected, onRowsSelectedWidth, onRowsSelectedRadio, onFetchData, selectedRowIds, hasScoreBorder, disabled }) => {
    const renderHooks = useCallback((hooks) => {
        if (onRowsSelected) {
            hooks.visibleColumns.push((columns) => [
                {
                    id: 'selection',
                    width: onRowsSelectedWidth ?? 36,
                    Header: ({ getToggleAllRowsSelectedProps }) => (!onRowsSelectedRadio && (React.createElement(CheckboxContainer, null,
                        React.createElement(Checkbox, { name: "select-all", ...getToggleAllRowsSelectedProps() })))) ||
                        null,
                    Cell: ({ row }) => {
                        return ((onRowsSelectedRadio && (React.createElement(Form.Check, { type: "radio", disabled: disabled, "aria-label": "radio 1", ...row.getToggleRowSelectedProps() }))) || (React.createElement(CheckboxContainer, null,
                            React.createElement(Checkbox, { name: "select", disabled: disabled, ...row.getToggleRowSelectedProps() }))));
                    }
                },
                ...columns
            ]);
        }
    }, []);
    const { table, onFetch } = useDataGrid({
        data,
        columns,
        pageCount,
        onFetchData,
        renderHooks,
        onRowsSelected,
        selectedRowIds,
        onRowsSelectedRadio
    });
    const { getTableProps, headerGroups } = table;
    const { pageIndex } = table.state;
    return (React.createElement(Container, null,
        React.createElement(Table, { ...getTableProps(), "$hasScoreBorder": hasScoreBorder },
            React.createElement("thead", null, headerGroups.map((headerGroup) => (React.createElement("tr", { ...headerGroup.getHeaderGroupProps() }, headerGroup.headers.map((column) => (React.createElement(TableTh, { ...column.getHeaderProps() }, column.render('Header')))))))),
            loading && pageIndex === 0 ? (React.createElement(TablePlaceholder, { table: table })) : (React.createElement(TableBody, { table: table, onClickRow: onClickRow }))),
        !!pageCount && (React.createElement(TableFooter, { onFetch: onFetch, table: table, loading: loading }))));
};
export default React.memo(DataGrid);
