import styled from 'styled-components';
import { Card } from '../';
import GearLeftSvg from '../../assets/icons/gear-left.svg';
import GearRightSvg from '../../assets/icons/gear-right.svg';
export const ContainerLoading = styled.div `
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.$small ? 'column' : 'row')};
  margin: ${(props) => (props.$small ? '15px 15px' : '136px 140px')};
  height: ${(props) => (props.$small ? '173px' : '550px')};
`;
export const IconLoadingContent = styled.div `
  justify-content: center;
  align-items: center;
  display: flex;
`;
export const IconGearLeft = styled.div `
  position: relative;
  top: -11px;
  width: 24px;
  height: 24px;
  background: url(${GearLeftSvg}) no-repeat;
  animation: rotation 2s infinite linear reverse;

  @keyframes rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;
export const IconGearRight = styled.div `
  position: relative;
  left: -5px;
  width: 17px;
  height: 17px;
  background: url(${GearRightSvg}) no-repeat;
  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
export const TextLoading = styled.span `
  width: 240px;
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 16px;
  line-height: 100%;
  color: ${(props) => props.theme.colors.gray400};
  margin-left: 7px;

  strong {
    color: ${(props) => props.theme.colors.gray700};
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 16px;
    line-height: 100%;
  }
`;
export const CardStyled = styled(Card) `
  height: ${(props) => (props.$small ? '173px' : '550px')};
`;
