class User {
    id;
    email;
    imageUrl;
    name;
    static fromJson(data) {
        const user = new User();
        user.id = data?.Id;
        user.email = data?.Email;
        user.imageUrl = data?.ImageUrl;
        user.name = data?.Name;
        return user;
    }
}
export default User;
