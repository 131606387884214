import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from '../Select';
const FormSelector = ({ name, label, required, disabled, options, placeholder, isClearable, grid }) => {
    const { control } = useFormContext();
    return (React.createElement(Col, { ...grid },
        React.createElement(Controller, { control: control, name: name, rules: { required }, render: ({ field, fieldState: { error } }) => (React.createElement(Select, { ...field, options: options, required: required, label: label, placeholder: placeholder, isDisabled: disabled, isError: !!error, errorMessage: error?.message, isClearable: isClearable })) })));
};
FormSelector.defaultProps = {
    grid: { xs: 6 }
};
export default FormSelector;
