import UploadProvider from './UploadProvider';
class UploadResult {
    uploadUrl;
    viewUrl;
    container;
    fileName;
    static fromJson(data, provider) {
        const uploadResult = new UploadResult();
        uploadResult.uploadUrl = data.UploadUrl;
        uploadResult.viewUrl = data.ViewUrl;
        if (provider === UploadProvider.Azure) {
            const uploadUrlSplit = uploadResult.uploadUrl.split('?');
            let uploadUrlParams = uploadUrlSplit[0].split('/');
            uploadResult.container = uploadUrlParams[uploadUrlParams.length - 2];
            uploadResult.fileName = uploadUrlParams[uploadUrlParams.length - 1];
            uploadResult.uploadUrl = `${uploadUrlParams.slice(0, -2).join('/')}?${uploadUrlSplit[1]}`;
        }
        return uploadResult;
    }
}
export default UploadResult;
