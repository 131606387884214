import { Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
export const CustomTooltipWrapper = styled(Tooltip) `
  &&& {
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
    width: 200px;
  }
  .tooltip-inner {
    border-radius: 4px;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  }
  .tooltip-arrow {
    display: none !important;
  }
  .tooltip-inner {
    border: 0px !important;
    background: #fff !important;
    box-shadow: none !important;
    padding: 3px !important;
  }
`;
export const StatusTitle = styled.span `
  color: ${(props) => props.theme.colors.gray600};
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-left: 3px;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
export const StatusName = styled.span `
  color: ${(props) => props.theme.colors.gray3};
  margin-top: 6px;
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  display: flex;
  text-align: left;
`;
export const StatusDate = styled.span `
  color: ${(props) => props.theme.colors.gray3};
  font-family: Gilroy;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
`;
export const Line = styled.hr `
  width: 100%;
  height: 1px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 4px;
  margin-left: 0px;
  margin-right: 0px;
  position: relative;
  right: 10px;
  width: 200px;
`;
export const DivTitle = styled.div `
  display: flex;
`;
export const DivStateDate = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
