export var EFileType;
(function (EFileType) {
    EFileType["document"] = "Document";
    EFileType["image"] = "Image";
    EFileType["audio"] = "Audio";
    EFileType["video"] = "Video";
})(EFileType || (EFileType = {}));
class FileAnswer {
    fileType;
    url;
    fileName;
    fileSize;
    static fromJson(data) {
        const file = new FileAnswer();
        file.fileType = data?.FileType;
        file.url = data?.Url;
        file.fileName = data?.FileName;
        file.fileSize = data?.FileSize;
        return file;
    }
}
export default FileAnswer;
