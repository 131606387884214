import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
import CloseIcon from '@campus/components/assets/icons/close-radius.svg';
import GreenCloseIcon from '@campus/components/assets/icons/close-green.svg';
import OrangeCloseIcon from '@campus/components/assets/icons/close-orange.svg';
import BlueCloseIcon from '@campus/components/assets/icons/close-blue.svg';
export const StyledAlert = styled(Alert) `
  padding: 8px 16px;
  margin-bottom: 0;
  padding-right: ${({ dismissible }) => dismissible && '48px'};
  border-radius: 4px;

  ${({ $styles }) => $styles &&
    `
    border: 1px solid ${$styles.borderColor};
    background-color: ${$styles.backgroundColor};
    color: ${$styles.textColor};
  `}

  h4 {
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 18px;
    margin: 0;
  }

  p {
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 14px;
    margin: 0;
  }

  .btn-close {
    visibility: hidden;
  }

  .alert {
    position: relative;
  }
`;
export const RedCloseButton = styled.div `
  width: 18px;
  height: 18px;
  background: url(${CloseIcon}) no-repeat;
  top: 10px;
  right: 10px;
  padding: 10px;
  position: absolute;
  cursor: pointer;
`;
export const GreenCloseButton = styled.div `
  width: 18px;
  height: 18px;
  background: url(${GreenCloseIcon}) no-repeat;
  top: 10px;
  right: 10px;
  padding: 10px;
  position: absolute;
  cursor: pointer;
`;
export const OrangeCloseButton = styled.div `
  width: 18px;
  height: 18px;
  background: url(${OrangeCloseIcon}) no-repeat;
  top: 10px;
  right: 10px;
  padding: 10px;
  position: absolute;
  cursor: pointer;
`;
export const BlueCloseButton = styled.div `
  width: 18px;
  height: 18px;
  background: url(${BlueCloseIcon}) no-repeat;
  top: 10px;
  right: 10px;
  padding: 10px;
  position: absolute;
  cursor: pointer;
`;
