import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export default function useMenuHeaderSelect() {
    const navigate = useNavigate();
    const location = useLocation();
    const handleSelectOptionChanged = useCallback(() => {
        location.pathname === '/' ? navigate(0) : navigate('/');
    }, [location.pathname, navigate]);
    return { handleSelectOptionChanged };
}
