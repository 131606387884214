import Context from './Context';
import Parameters from './Parameters';
import Person from './Person';
class CurrentContext extends Context {
    person;
    personType;
    parameters;
    static fromJson(data) {
        const context = new CurrentContext();
        context.person = Person.fromJson(data?.Person);
        context.parameters = Parameters.fromJson(data?.Parameters);
        context.personType = data?.PersonType;
        context.imageUrl = data.ImageUrl;
        context.bannerImageUrl = data?.BannerImageUrl;
        context.bannerImageMobileUrl = data?.BannerImageMobileUrl;
        context.bannerRedirectUrl = data?.BannerRedirectUrl;
        context.name = data.Name;
        context.tenantId = data.TenantId;
        context.unityId = data.UnityId;
        context.unityName = data.UnityName;
        context.studyPeriodActive = data?.StudyPeriodActive;
        context.studyPeriodId = data?.StudyPeriodId;
        context.logoUrl = data?.LogoUrl;
        return context;
    }
    get userName() {
        return this.person.user.name;
    }
    get userImage() {
        return this.person.user.imageUrl;
    }
}
export default CurrentContext;
