import styled from 'styled-components';
import { DropdownContent, DropdownStyled } from '@campus/components';
export const Container = styled(DropdownStyled) ``;
export const DigitalObjectContent = styled.div `
  display: flex;
  flex-direction: column;
`;
export const DigitalObjectImg = styled.img `
  width: 205px;
  height: 275px;
  cursor: pointer;
  border-radius: 4px;

  ${(props) => (props.$isVideo ? 'filter: blur(3.5px);' : '')}
`;
export const DigitalObjectImgContent = styled.div `
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray5};
  border-radius: 7px;
`;
export const DigitalObjectVideoImg = styled.img `
  width: 195px;
  height: auto;
  max-height: 100px;
  cursor: pointer;
  border-radius: 4px;
`;
export const PlayIconStyled = styled.img `
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
export const DigitalObjectVideoContent = styled.div `
  position: absolute;
  top: 90px;
  left: 5px;
`;
export const DigitalObjectName = styled.span `
  color: ${(props) => props.theme.colors.gray2};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  width: 205px;
  padding-top: 10px;
  cursor: pointer;
`;
export const DropdownContentStyled = styled(DropdownContent) `
  display: block;

  height: 161px;
  width: auto;
  max-width: 415px;
  min-width: revert;

  overflow-y: auto;
`;
export const DropdownContainer = styled.div `
  position: absolute;
  right: ${(props) => props.$right}px;
`;
