import { Api } from '@campus/auth';
import { Response, PaginationModel } from '@campus/commons';

import PermissionType from 'utils/PermissionType';
import UserAccessType from 'utils/UserAccessType';

import { PermissionFeatureGrade } from '../models/PermissionFeatureGrade';

import { PermissionFeatureManager } from '../models/PermissionFeatureManager';
import { PermissionFeatureStudent } from '../models/PermissionFeatureStudent';
import { PermissionFeatureTeacher } from '../models/PermissionFeatureTeacher';
import { Permission } from '../models/Permission';

const path: string = '/feature';
const permissionFeature: string = 'permissionfeature';

export async function getPermissionFeatureGrade(
  page: number,
  pageSize: number,
  permission: PermissionType,
  gradeName?: string
): Promise<Response<PaginationModel<PermissionFeatureGrade>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${permission}/${permissionFeature}/grade`,
      {
        params: {
          code: permission,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          GradeName: gradeName ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureGrade,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function getPermissionFeatureManager(
  page: number,
  pageSize: number,
  permission: PermissionType,
  name?: string
): Promise<Response<PaginationModel<PermissionFeatureManager>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${permission}/${permissionFeature}/manager`,
      {
        params: {
          code: permission,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          Name: name ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureManager,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function getPermissionFeatureStudent(
  page: number,
  pageSize: number,
  permission: PermissionType,
  studentName?: string
): Promise<Response<PaginationModel<PermissionFeatureStudent>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${permission}/${permissionFeature}/student`,
      {
        params: {
          code: permission,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          Name: studentName ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureStudent,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function getPermissionFeatureTeacher(
  page: number,
  pageSize: number,
  permission: PermissionType,
  teacherName?: string
): Promise<Response<PaginationModel<PermissionFeatureTeacher>>> {
  try {
    const skipCount = (page - 1) * pageSize;

    const response = await Api.INSTANCE.getApi().get(
      `${path}/${permission}/${permissionFeature}/teacher`,
      {
        params: {
          code: permission,
          SkipCount: skipCount,
          MaxResultCount: pageSize,
          Name: teacherName ?? null
        }
      }
    );

    const data = PaginationModel.fromJson(
      PermissionFeatureTeacher,
      response.data,
      skipCount
    );

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function setPermissionFeature(
  code: string,
  enable: boolean,
  userId?: string,
  gradeId?: string,
  userAccessType?: UserAccessType
): Promise<Response<Permission>> {
  try {
    const response = await Api.INSTANCE.getApi().post(
      `${path}/${code}/${permissionFeature}`,
      {
        UserId: userId ?? null,
        GradeId: gradeId ?? null,
        UserAccessType: userAccessType ?? null,
        Enabled: enable
      }
    );

    const data = Permission.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}

export async function togglePermissionFeature(
  permissionData: Permission,
  userAccessType?: UserAccessType
): Promise<Response<Permission>> {
  try {
    const response = await Api.INSTANCE.getApi().patch(
      `${permissionFeature}/${permissionData.id}`,
      {
        Enabled: !permissionData.enabled,
        UserId: permissionData.userId ?? null,
        UserAccessType: userAccessType ?? null,
        GradeId: permissionData.gradeId ?? null
      }
    );

    const data = Permission.fromJson(response.data);

    return Response.fromData(data, response.status);
  } catch (e) {
    return Response.error(e);
  }
}
