import { Student } from './Student';
import { Permission } from './Permission';

export class PermissionFeatureStudent {
  student?: Student;
  permission?: Permission;

  static fromJson(data: any): PermissionFeatureStudent {
    const permissionFeatureStudent = new PermissionFeatureStudent();

    permissionFeatureStudent.student = Student.fromJson(data?.Student);
    permissionFeatureStudent.permission = Permission.fromJson(data?.Permission);

    return permissionFeatureStudent;
  }
}
