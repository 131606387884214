import styled from 'styled-components';
export const DropdownStyled = styled.div `
  position: relative;
  display: inline-block;
  margin-left: 5px;

  &:hover {
    .dropdown-content {
      display: block;
    }
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    &:hover {
      .dropdown-content {
        right: -140px;
      }
    }
    margin-left: 0px;
  }
`;
export const DropdownContent = styled.div `
  display: none;
  position: absolute;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 99999999;
  cursor: pointer;
  width: 100%;
  min-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
export const DropdownContentItem = styled.div `
  padding: 5px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #828282;
  height: auto;

  &:hover {
    background-color: #f2f2f2;
  }
`;
export const DropdownLabel = styled.div `
  border: none;
  background-color: rgb(243, 243, 243);
  color: ${(props) => props.disciplineColor ?? '#2e81ed'};
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  margin-left: 4px;
  padding: 0;
  margin: 0;

  img {
    margin-left: 4px;
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    font-size: 18px;
  }
`;
export const SelectContent = styled.div `
  padding: 1px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const SelectContentItem = styled.button `
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.gray2};
  padding: 2px;
  width: ${(props) => props.$width ?? '210'}px;

  :disabled {
    color: ${(props) => props.theme.colors.blackAlpha500};
  }
`;
