import React from 'react';
import { FormInput, DataGridProps } from '@campus/components';

import AdminContainer from 'containers/AdminContainer';
import StudyPeriodRepository from 'repositories/StudyPeriodRepository';

const StudyPeriodForm: React.FC = () => {
  return (
    <>
      <FormInput
        name="name"
        label="Nome"
        placeholder="Insira o nome"
        required
        grid={{ xs: 12 }}
      />

      <FormInput
        name="startDate"
        label="Data de inicio"
        required
        grid={{ xs: 6 }}
        type="datetime-local"
      />

      <FormInput
        name="endDate"
        label="Data do fim"
        required
        grid={{ xs: 6 }}
        type="datetime-local"
      />

      <FormInput name="active" label="Ativo?" grid={{ xs: 12 }} disabled />

      <FormInput
        name="hideScoreStartDate"
        label="Data de inicio (esconder notas)"
        grid={{ xs: 6 }}
        type="datetime-local"
      />

      <FormInput
        name="hideScoreEndDate"
        label="Data do fim (esconder notas)"
        grid={{ xs: 6 }}
        type="datetime-local"
      />

      <FormInput
        name="visibleToStudentAt"
        label="Data de visibilidade para o aluno"
        grid={{ xs: 6 }}
        type="datetime-local"
      />
    </>
  );
};

const StudyPeriodFilter: React.FC = () => {
  return <FormInput name="name" label="Nome" placeholder="Insira o nome" />;
};

const StudyPeriod: React.FC = () => {
  const columns: DataGridProps['columns'] = [
    { Header: 'Nome', accessor: 'name' },
    { Header: 'Ativo?', accessor: 'activeFormated' },
    { Header: 'Data de início', accessor: 'startDateFormated' },
    { Header: 'Data de fim', accessor: 'endDateFormated' },
    {
      Header: 'Visível para o aluno em',
      accessor: 'visibleToStudentAtFormated'
    }
  ];

  return (
    <AdminContainer
      title="Cadastro de períodos letivos"
      columns={columns}
      repository={new StudyPeriodRepository()}
      components={{
        Form: StudyPeriodForm,
        Filter: StudyPeriodFilter
      }}
    />
  );
};

export default StudyPeriod;
