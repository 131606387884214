import styled from 'styled-components';
import { Button } from 'react-bootstrap';
export const StyledButton = styled(Button) `
  position: relative;
  pointer-events: ${(props) => props.$loading && 'none'};
  border-radius: 6px;

  &.btn-primary,
  &.btn-primary:disabled,
  &.btn-primary:focus,
  &.btn-primary:hover,
  &.btn-primary:active {
    color: #fff;
  }

  &.btn:active,
  &.btn:focus,
  &.btn:active:focus {
    box-shadow: none;
  }

  &.btn-outline-primary:hover,
  &.btn-outline-primary:active {
    color: #fff;
  }

  & span {
    color: ${(props) => props.$loading && 'transparent'};
  }
`;
export const SpinnerContent = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
