import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { useEffect } from 'react';
import queryString from 'query-string';
const DOMAIN = process.env.REACT_APP_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPE = process.env.REACT_APP_SCOPE;
export const getAzureAuthConfig = () => {
    return new PublicClientApplication({
        auth: {
            clientId: CLIENT_ID,
            authority: DOMAIN,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.href,
            navigateToLoginRequestUrl: true
        }
    });
};
export const useAzureAuthorization = () => {
    const azureConfig = {
        scopes: [SCOPE],
        state: sessionStorage.getItem('@campus:loginState')
    };
    const { error, result } = useMsalAuthentication(InteractionType.Redirect, azureConfig);
    const isAuthenticated = useIsAuthenticated();
    const { accounts, instance, inProgress } = useMsal();
    const logout = async () => {
        await instance.logoutRedirect({
            account: accounts[0]
        });
    };
    const getAccessToken = async () => {
        const account = accounts[0];
        if (!account)
            return null;
        const response = await instance.acquireTokenSilent({
            account: account,
            scopes: [SCOPE]
        });
        return response.accessToken;
    };
    useEffect(() => {
        const searchParams = queryString.parse(window.location.search);
        if (searchParams.tenantId) {
            sessionStorage.setItem('@campus:loginState', JSON.stringify(searchParams));
        }
    }, []);
    useEffect(() => {
        if (result && result.state) {
            const loginResultState = JSON.parse(result.state);
            if (loginResultState) {
                const searchParams = queryString.stringify(loginResultState);
                const url = `${window.location.origin}?${searchParams}`;
                window.location.replace(url);
            }
        }
    }, [result]);
    if (error)
        console.error({ inProgress, isAuthenticated, error });
    return {
        isAuthenticated,
        loading: inProgress == 'login',
        error: !!error,
        getAccessToken,
        logout
    };
};
