import styled from 'styled-components';
import CampusIcon from '../CampusIcon';
export const Container = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 15px;
  ${(props) => (props.$isMobile ? 'justify-content: center;' : '')}

  & > span {
    line-height: normal;
    font-weight: bold;
    font-size: 20px;
    color: #4f4f4f;
  }

  .container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .carousel-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-wrapper {
    display: flex;
    flex-direction: column;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;
export const ContentIconButton = styled.button `
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;
export const ArrowLeft = styled(CampusIcon) `
  transform: rotate(180deg);
`;
export const DivSpace = styled.div `
  padding-left: 19px;
  height: 19px;
`;
export const CarouselItemAnimation = styled.div `
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  position: relative;
  z-index: 999;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;
