import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
export const StyledModal = styled(Modal) `
  .modal-dialog {
    height: ${(props) => (props.$fullHeight ? 'calc(100% - 56px)' : 'auto')};
  }

  .modal-content {
    padding: 18px 30px 28px 30px;
    height: ${(props) => (props.$fullHeight ? '100%' : 'auto')};
    min-height: ${(props) => (props.$fullHeight ? '100%' : 'initial')};
    overflow: ${(props) => (props.$fullHeight ? 'auto' : 'initial')};
  }

  .modal-header {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .modal-footer {
    border-top: 0px !important;
    padding-top: 4px !important;
  }

  .modal-body {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.semiBold};
    font-size: 14px;
    line-height: 130%;
    color: ${(props) => props.theme.colors.gray3};
  }

  .modal-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: 18px;
    line-height: 130%;
    color: ${(props) => props.theme.colors.gray1};
  }
`;
StyledModal.Header = styled(Modal.Header) `
  padding: 0px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;
StyledModal.Footer = styled(Modal.Footer) `
  padding: 0px;
  padding-top: 18px;
  display: flex;
  gap: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.gray4};

  * {
    margin: 0;
  }
`;
StyledModal.Title = styled(Modal.Title) `
  flex: 1;
  font-size: 28px;
  line-height: 103%;
  font-weight: ${(props) => props.theme.weight.bold};
  color: ${(props) => props.theme.colors.gray1};
`;
StyledModal.Body = styled(Modal.Body) `
  padding: 18px 0px;
`;
