import PerformanceStatus from './PerformanceStatus';
class Student {
    id;
    name;
    imageUrl;
    performanceStatus;
    performance;
    flowFeedback;
    flowStateText;
    flowState;
    engagement;
    static fromJson(data) {
        const student = new Student();
        student.id = data?.Id;
        student.name = data?.User?.Name ?? data?.Name;
        student.imageUrl = data?.ImageUrl;
        student.performance = data?.Performance;
        student.engagement = data?.Engagement;
        student.flowFeedback = data?.FlowFeedback;
        student.flowStateText = data?.FlowStateText;
        student.flowState = data?.FlowState;
        student.performanceStatus = data?.PerformanceStatus;
        return student;
    }
    getAvatarStatus() {
        const avatarStatus = {
            [PerformanceStatus.LOW]: 'error',
            [PerformanceStatus.REGULAR]: 'neutral',
            [PerformanceStatus.HIGH]: 'success'
        };
        return avatarStatus[this.performanceStatus];
    }
    get nameFormatted() {
        const nameArray = this.name.split(' ');
        return `${nameArray.shift()} ${nameArray.pop()}`.trim();
    }
}
export default Student;
