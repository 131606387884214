import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CampusIcon, EntityBadge, useSideMenu } from '@campus/components';
import { useAuthority } from '@campus/auth';
import { DivIcon, Hr } from '../styles';
import { useWindowDimensions } from '../../../hooks';
import { LeftBar } from '../../../components';
const AppLeftBar = (props) => {
    const { isCurrentContext, changeContext, contexts, currentContext } = useAuthority();
    const { setHiden } = useSideMenu();
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useWindowDimensions();
    useEffect(() => {
        if (isMobile) {
            setHiden(true);
        }
    }, [location]);
    return (React.createElement(LeftBar, { ...props },
        React.createElement(DivIcon, { onClick: () => setHiden(true) },
            React.createElement(CampusIcon, { name: "menu", color: "#FFFFFF", size: 24 }),
            React.createElement(Hr, null)),
        contexts.map((context, i) => (React.createElement(EntityBadge, { active: isCurrentContext(context), key: `${context.name}-${i}`, name: context.name, imageUrl: context.imageUrl, "aria-label": context.name, customSize: 38, onClick: () => {
                changeContext(context, currentContext?.person.contextType);
                navigate('/');
            } })))));
};
export default AppLeftBar;
