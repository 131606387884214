import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useStudentId from '../../hooks/useStudentId';
import { listMessage } from '../../services';
export const MessagesContext = createContext(undefined);
export const MessagesProvider = ({ classroom, stage, children }) => {
    const studentId = useStudentId();
    const [messages, setMessages] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [messageSelected, setMessageSelected] = useState();
    const [page, setPage] = useState(1);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await listMessage({
                studentId,
                classId: classroom.id,
                stageId: stage.id
            });
            setMessages(result.data);
            if (result.data.length > 0) {
                setMessageSelected(result.data[0]);
            }
            else {
                setHasMoreMessages(false);
            }
        }
        catch (error) {
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [studentId, classroom.id, stage.id]);
    const loadMore = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await listMessage({
                studentId,
                classId: classroom.id,
                stageId: stage.id,
                page: page + 1
            });
            setPage(page + 1);
            if (result.data.length === 0) {
                setHasMoreMessages(false);
                return;
            }
            setMessages([...messages, ...result.data]);
        }
        catch (error) {
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    }, [studentId, classroom.id, stage.id, messages, page]);
    useEffect(() => {
        fetchData();
    }, []);
    const onSelectMessage = (message) => setMessageSelected(message);
    const refreshData = useCallback(async () => {
        setPage(1);
        fetchData();
    }, [fetchData]);
    const providerValue = useMemo(() => ({
        isLoading,
        error,
        onSelectMessage,
        messageSelected,
        messages,
        loadMore,
        refreshData,
        hasMoreMessages
    }), [
        isLoading,
        messages,
        error,
        onSelectMessage,
        messageSelected,
        loadMore,
        refreshData,
        hasMoreMessages
    ]);
    return (React.createElement(MessagesContext.Provider, { value: providerValue }, children));
};
export const useMessages = () => useContext(MessagesContext);
