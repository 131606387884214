import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Container, Form as BootstrapForm } from 'react-bootstrap';
import Button from '../Button';
const ActionsContainer = styled.div `
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  margin-top: 1rem;
`;
const HookForm = ({ onSubmit, onReset, defaultValues, loading, children, asyncDefaultValues = false, clearOnReset = false, submitLabel = 'Enviar', ActionsComponent, }) => {
    const values = defaultValues;
    const methods = useForm({ defaultValues: values });
    const onFormSubmit = methods.handleSubmit((data) => onSubmit(data));
    const onFormReset = () => {
        onReset?.();
        if (clearOnReset) {
            const formValues = methods.getValues();
            let resetedValues = {};
            for (const valueKey in formValues) {
                Object.assign(resetedValues, { [valueKey]: null });
            }
            methods.reset(resetedValues);
        }
        else {
            methods.reset();
        }
    };
    useEffect(() => {
        if (asyncDefaultValues) {
            methods.reset(values);
        }
    }, [asyncDefaultValues, values]);
    return (React.createElement(FormProvider, { ...methods },
        React.createElement(BootstrapForm, { onSubmit: onFormSubmit, onReset: onFormReset, noValidate: true },
            React.createElement(Container, null,
                React.createElement(Row, { className: "gy-2 gx-2" }, children),
                React.createElement(ActionsContainer, null,
                    !!ActionsComponent && React.createElement(ActionsComponent, null),
                    React.createElement(Button, { disabled: loading, variant: "secondary", type: "reset" }, "Limpar"),
                    React.createElement(Button, { disabled: loading, type: "submit" }, loading ? 'Carregando...' : submitLabel))))));
};
export default HookForm;
