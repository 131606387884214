class Parameters {
    reportDefaultEvaluationTypeId;
    static fromJson(data) {
        const parameters = new Parameters();
        parameters.reportDefaultEvaluationTypeId =
            data?.ReportDefaultEvaluationType;
        return parameters;
    }
}
export default Parameters;
