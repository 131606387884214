import React, { useContext } from 'react';
const initialState = {
    isFloating: false,
    hiden: false,
    variant: 'default'
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_VARIANT':
            return { ...state, variant: action.payload };
        case 'SET_IS_FLOATING':
            return { ...state, isFloating: action.payload };
        case 'SET_HIDEN':
            return { ...state, hiden: action.payload };
        default:
            return state;
    }
};
export const SideMenuContext = React.createContext(undefined);
export const useSideMenu = () => useContext(SideMenuContext);
export const SideMenuProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const setVariant = React.useCallback((variant) => {
        dispatch({ type: 'SET_VARIANT', payload: variant });
    }, []);
    const setIsFloating = React.useCallback((isFloating) => {
        dispatch({ type: 'SET_IS_FLOATING', payload: isFloating });
    }, []);
    const setHiden = React.useCallback((hiden) => {
        dispatch({ type: 'SET_HIDEN', payload: hiden });
    }, []);
    const value = {
        options: state,
        setVariant: setVariant,
        setIsFloating: setIsFloating,
        setHiden: setHiden
    };
    return (React.createElement(SideMenuContext.Provider, { value: value }, children));
};
