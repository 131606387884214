import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import background from '@campus/components/assets/images/flow-chart-background.svg';
import { formatAsPercentage } from '../../../../utils';
import { createChartData, hideTooltip, showTooltip } from '../../../../utils/charts';
import { Container, FlowChartContainer, MinusSign, PlusSign } from './styles';
const canvasBackgroundImage = new Image();
canvasBackgroundImage.src = background;
class DisciplineAsChartModel {
    discipline;
    _bubbleRadius = 11;
    constructor(discipline) {
        this.discipline = discipline;
    }
    get data() {
        return this.discipline;
    }
    get bubbleX() {
        return this.discipline.currentEngagement;
    }
    get bubbleY() {
        return this.discipline.currentWeight;
    }
    set bubbleRadius(radius) {
        this._bubbleRadius = radius;
    }
    get bubbleRadius() {
        return this._bubbleRadius;
    }
    get bubbleHoverRadiusOffset() {
        return 4;
    }
    getTooltipGeneralContent() {
        const discipline = this.data;
        return {
            title: discipline.name,
            feedback: discipline.flowFeedback,
            performance: formatAsPercentage(discipline.currentWeight),
            engagement: formatAsPercentage(discipline.currentEngagement)
        };
    }
    getTooltipListRowContent() {
        const discipline = this.data;
        return {
            text: discipline.name,
            bubble: {
                text: discipline.name.slice(0, 3),
                backgroundColor: discipline.color
            }
        };
    }
}
const plugin = () => ({
    id: 'custom_canvas_background_image',
    beforeDraw: (chart) => {
        const image = canvasBackgroundImage;
        if (image.complete) {
            chart.ctx.drawImage(image, 0, 0, chart.width, chart.height);
        }
        else {
            image.addEventListener('load', () => chart.draw());
        }
    },
    afterDestroy() {
        hideTooltip();
    },
    afterDatasetDraw(chart) {
        const metadata = chart.getDatasetMeta(0);
        if (metadata.hidden)
            return;
        const dataset = chart.data.datasets[0];
        const context = chart.canvas.getContext('2d');
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillStyle = 'rgb(255, 255, 255)';
        for (const [index, element] of metadata.data.entries()) {
            const dataPoint = dataset.data.at(index);
            if (!dataPoint || !dataPoint.group?.length)
                continue;
            if (dataPoint.group.length === 1) {
                context.font = '600 9px Gilroy';
                const text = dataPoint.group[0].data.name?.slice(0, 3);
                context.fillText(text, element.x, element.y);
            }
            else {
                context.font = '600 12px Gilroy';
                const text = dataPoint.group.length.toString();
                context.fillText(text, element.x, element.y);
            }
        }
    }
});
const classNames = Object.freeze({
    labelContainers: 'label__container',
    labelContainerForYAxis: 'label__container--for-y-axis',
    labelMinusSignContainer: 'label__minus-sign-container',
    labelTexts: 'label__text',
    labelSigns: 'label__sign',
    labelPlusSign: 'label__sign--plus',
    labelMinusSign: 'label__sign--minus'
});
const Flow = ({ className, discipline, increaseDataPointSize }) => {
    const FlowRef = useRef();
    const chartData = useMemo(() => {
        if (!discipline?.length)
            return [];
        const chartModels = discipline
            .filter((d) => d.showPerformance)
            .map((d) => new DisciplineAsChartModel(d));
        if (increaseDataPointSize) {
            for (const chartModel of chartModels) {
                chartModel.bubbleRadius = 16;
            }
        }
        return createChartData(chartModels);
    }, [discipline, increaseDataPointSize]);
    useLayoutEffect(() => {
        Chart.register(...registerables);
        const canvas = FlowRef.current.getContext('2d');
        var gradientGridLine = canvas.createLinearGradient(0, 140, 0, 0);
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 1)');
        gradientGridLine.addColorStop(0, 'rgba(0, 0, 0, 0.2)');
        gradientGridLine.addColorStop(1, 'rgba(0, 0, 0, 0)');
        const data = {
            datasets: [
                {
                    data: chartData,
                    backgroundColor: (context) => {
                        const { dataset, dataIndex } = context;
                        const dataPoint = dataset.data[dataIndex];
                        return dataPoint?.group?.length === 1
                            ? dataPoint.group[0].data.color
                            : '#4a4a4a';
                    },
                    clip: 50,
                    hoverRadius: 4,
                    label: 'Flow'
                }
            ]
        };
        const chart = new Chart(FlowRef.current, {
            data: data,
            type: 'bubble',
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false,
                        external: ({ tooltip }) => {
                            const { dataset, dataIndex } = tooltip.dataPoints[0];
                            const dataAtIndex = dataset.data[dataIndex];
                            const dataPoint = dataAtIndex;
                            showTooltip(dataPoint, tooltip);
                        }
                    }
                },
                scales: {
                    xAxes: {
                        display: true,
                        grid: {
                            display: false,
                            drawTicks: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: false
                        },
                        min: 0,
                        max: 100,
                        offset: false
                    },
                    yAxes: {
                        display: true,
                        grid: {
                            display: false,
                            drawTicks: false,
                            drawBorder: false
                        },
                        ticks: {
                            display: false
                        },
                        min: 0,
                        max: 100,
                        offset: false
                    }
                }
            },
            plugins: [plugin()]
        });
        return () => {
            Chart.unregister();
            chart.destroy();
        };
    }, [chartData]);
    return (React.createElement(Container, { className: className },
        React.createElement("div", { className: `${classNames.labelContainers} ${classNames.labelContainerForYAxis}` },
            React.createElement("span", { className: classNames.labelTexts }, "DESEMPENHO"),
            React.createElement(PlusSign, { className: `${classNames.labelSigns} ${classNames.labelPlusSign}` })),
        React.createElement("div", null,
            React.createElement(FlowChartContainer, { ref: FlowRef })),
        React.createElement("div", { className: classNames.labelMinusSignContainer },
            React.createElement(MinusSign, { className: `${classNames.labelSigns} ${classNames.labelMinusSign}` })),
        React.createElement("div", { className: classNames.labelContainers },
            React.createElement("span", { className: classNames.labelTexts }, "ENGAJAMENTO"),
            React.createElement(PlusSign, { className: `${classNames.labelSigns} ${classNames.labelPlusSign}` }))));
};
export default Flow;
export { classNames as elementClassNames };
