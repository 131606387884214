import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '../Input';
const FormInput = ({ name, label, type, placeholder, required, disabled, format, grid, defaultValue }) => {
    const { control, setValue } = useFormContext();
    const handleBlur = (event) => {
        if (!!format)
            setValue(name, format(event.target.value));
    };
    return (React.createElement(Col, { ...grid },
        React.createElement(Controller, { control: control, name: name, rules: { required, onBlur: handleBlur }, render: ({ field, fieldState: { error } }) => (React.createElement(Input, { ...field, required: required, label: label, type: type, placeholder: placeholder, disabled: disabled, isError: !!error, errorMessage: error?.message, defaultValue: defaultValue })) })));
};
FormInput.defaultProps = {
    grid: { xs: 6 }
};
export default FormInput;
