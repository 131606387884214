import styled from 'styled-components';
export const TreeList = styled.ul `
  margin: 0;
  padding: 0;
  list-style: none;

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 8px;
    position: relative;
  }

  & ul ul {
    margin-left: 0.5em;
  }

  & ul:before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    top: 1px;
    bottom: 0;
    left: -1px;
    background-color: ${(props) => props.theme.colors.gray4};
  }
`;
