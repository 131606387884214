import { ContextType, useAuthority } from '@campus/auth';
import { useStudentId, useTeacherId } from '../../../hooks';
export const useUserId = (contextType) => {
    const { currentContext } = useAuthority();
    const userId = currentContext.person.personId;
    const teacher = useTeacherId();
    const student = useStudentId();
    switch (contextType) {
        case ContextType.STUDENT:
            return { studentId: student };
        case ContextType.TEACHER:
            return { teacherId: teacher };
        case ContextType.MANAGER:
            return teacher != userId ? { teacherId: teacher } : { managerId: userId };
        default:
            return {};
    }
};
