import { Form } from 'react-bootstrap';
import styled from 'styled-components';
export const FormCheck = styled(Form.Check) `
  display: inline-flex;
  gap: 8px;
  padding: 0;
  width: 100%;
`;
export const FormLabel = styled(Form.Check.Label) `
  font-size: 14px;
  line-height: 110%;
  font-weight: ${(props) => props.theme.weight.semiBold};
  color: ${(props) => props.theme.colors[props.$checked ? 'blue1' : 'gray700']};
  margin-top: 1px;
`;
