import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';
export const StyledProgressBar = styled(ProgressBar) `
  height: 20px;
  border-radius: 2px;

  .progress-bar {
    font-size: 12px;
    line-height: 130%;
    font-weight: ${(props) => props.theme.weight.semiBold};
    background-color: ${(props) => props.theme.colors.blue1};

    &.progress-bar-striped {
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
    }
  }
`;
