import styled from 'styled-components';
export const UploadContent = styled.div `
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.$customGap ?? 12}px;
`;
export const Description = styled.span `
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.gray400};
  font-weight: ${(props) => props.theme.weight.regular};
  margin-left: 30px;
  margin-right: 30px;
`;
export const ProgressContent = styled.div `
  padding: 26px 23px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${(props) => props.$customGap ?? 12}px;
`;
