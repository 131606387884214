import styled from 'styled-components';
export const Root = styled.nav `
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  background: ${(props) => props.theme.colors.leftbar.backgroundTop};
  box-sizing: border-box;
  z-index: 10;
  width: 100vw;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: flex;
  }
`;
export const Drawer = styled.div `
  position: relative;
  z-index: 10;
  display: flex;
  height: 100vh;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: ${(props) => (props.$open ? 'flex' : 'none')};
    height: calc(100vh - 80px);
    width: 100vw;
    background-color: ${(props) => props.theme.colors.gray1}99;
  }
`;
export const Logo = styled.img `
  width: 38px;
  margin: 21px 0px;
`;
export const MenuButton = styled.button `
  border: none;
  background: transparent;
`;
