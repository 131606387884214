import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
export const DivDropImage = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 215px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4.124px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  ${(props) => props.$isDraggingOver
    ? 'border: 1px dashed #999999; border-radius: 6px;'
    : ''};
`;
export const LabelUpload = styled.label `
  color: ${(props) => props.theme.colors.gray200};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  width: 65px;
  cursor: pointer;
`;
export const DescriptionImage = styled.p `
  color: ${(props) => props.theme.colors.gray4};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  margin-top: 8px;
  max-width: 221px;
  margin-bottom: 0;
`;
export const DivImage = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 140px;
  height: 215px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4.124px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-image: url(${(props) => props.$image});
  background-size: cover;
`;
