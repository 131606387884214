import React from 'react';
import { SideMenu } from '@campus/components';
import { useAuthority } from '@campus/auth';
import { useContextManager } from '../../providers';
import useMenuHeaderSelect from './hooks/useMenuHeaderSelect';
import StageSelect from './components/StageSelect';
import ClassroomSelect from './components/ClassroomSelect';
import StudyPeriodSelect from './components/StudyPeriodSelect';
const MenuHeader = () => {
    const { classrooms, classroom, setClassroom, stage, stages, setStage, period, periods, setPeriod } = useContextManager();
    const { unityName } = useAuthority();
    const { handleSelectOptionChanged } = useMenuHeaderSelect();
    function createOnChangeHandler(setter) {
        return (newValue) => {
            setter(newValue);
            handleSelectOptionChanged();
        };
    }
    return (React.createElement(SideMenu.Header, { title: unityName, subtitle: React.createElement(React.Fragment, null,
            classroom?.name,
            React.createElement("br", null),
            "Etapa: ",
            stage?.name) },
        React.createElement(ClassroomSelect, { allClassrooms: classrooms, selectedClassroom: classroom, onChange: createOnChangeHandler(setClassroom) }),
        React.createElement(StageSelect, { allStages: stages, selectedStage: stage, onChange: createOnChangeHandler(setStage) }),
        React.createElement(StudyPeriodSelect, { allPeriods: periods, selectedPeriod: period, onChange: createOnChangeHandler(setPeriod) })));
};
export default Object.assign(MenuHeader, {
    ClassroomSelect,
    StageSelect,
    StudyPeriodSelect
});
