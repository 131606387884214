import styled from 'styled-components';
export const Root = styled.li `
  display: flex;
  align-items: center;
  gap: 3px;
  color: #fff;
  font-size: 12px;
  font-weight: ${(props) => props.theme.weight[props.$active ? 'extraBold' : 'semiBold']};
  padding: 7px;
  border-top: 1px solid #ffffff1a;
  cursor: pointer;
  transition: 0.4s all;

  svg {
    width: 15px;
    min-width: 15px;
    height: auto;
  }

  &:hover {
    background: #ffffff1a;
  }
`;
