import React, { Component } from 'react';
import GenericError from './components/GenericError';
class ErrorBoundary extends Component {
    state = {
        hasError: false
    };
    static getDerivedStateFromError(e) {
        return { hasError: true };
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement(GenericError, { title: "Ops algo deu errado!", subTitle: "Por favor, tente novamente", onRetry: () => document.location.reload(), onExit: this.props.onExit }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
