import styled from 'styled-components';
export const Header = styled.header `
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px;
`;
export const HeaderContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
export const Title = styled.span `
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: 14px;
  line-height: 100%;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.colors.gray2};
`;
