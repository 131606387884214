import { useCallback, useEffect, useState } from 'react';
import { useAuthority } from '@campus/auth';
import { listStudyPeriod } from '../../../services';
import { useUserId } from './useUserId';
export const usePeriod = (contextType) => {
    const { currentContext, changeContext } = useAuthority();
    const { managerId, studentId, teacherId } = useUserId(contextType);
    const [period, setPeriod] = useState();
    const [periods, setPeriods] = useState([]);
    const [isCurrentPeriod, setIsCurrentPeriod] = useState(false);
    const [loading, setLoading] = useState(true);
    const changePeriod = (period) => {
        setPeriod(period);
        sessionStorage.setItem('@campus:period', period.id);
        const context = currentContext;
        context.studyPeriodId = period.id;
        changeContext(context, context.person.contextType);
    };
    const getPeriodId = useCallback(() => {
        const periodId = sessionStorage.getItem('@campus:period');
        if (periodId)
            return periodId;
        return currentContext.studyPeriodId;
    }, [currentContext.studyPeriodId]);
    const getPeriods = useCallback(async () => {
        setLoading(true);
        try {
            const response = await listStudyPeriod({
                teacherId,
                studentId,
                managerId
            });
            const newPeriods = response?.data?.items;
            if (!newPeriods?.length)
                return;
            const oldPeriod = newPeriods.find((p) => p.id === getPeriodId());
            const newPeriod = oldPeriod ?? newPeriods[0];
            sessionStorage.setItem('@campus:period', newPeriod.id);
            setPeriod(newPeriod);
            setPeriods(newPeriods);
        }
        finally {
            setLoading(false);
        }
    }, [teacherId, studentId, managerId, getPeriodId]);
    useEffect(() => {
        getPeriods();
    }, [getPeriods]);
    useEffect(() => {
        if (!!currentContext) {
            const hasPeriodDates = !!period?.startDate && !!period?.endDate;
            const isPeriodActive = hasPeriodDates &&
                new Date(period.startDate) < new Date() &&
                new Date(period.endDate) > new Date();
            setIsCurrentPeriod(isPeriodActive);
        }
    }, [currentContext, period]);
    return {
        isCurrentPeriod,
        period,
        periods,
        loading,
        changePeriod
    };
};
