import styled from 'styled-components';
import { Alert } from 'react-bootstrap';
export const Banner = styled(Alert) `
  background: linear-gradient(313.31deg, #b7295a 0%, #ff6a00 100%);
  color: ${({ theme }) => theme.colors.white};

  margin: 0;

  border: none;
  border-radius: 0;

  p {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;
export const BannerUser = styled(Alert) `
  background: ${(props) => props.theme.colors.CSA.orange};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  border: none;
  border-radius: 0;

  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
  height: 50px;
  padding: 0;
  display: flex;

  p {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 20px;
  }
`;
export const TextUnderline = styled.span `
  text-decoration-line: underline;
`;
export const Avatar = styled.div `
  background-image: url(${(p) => p.img});

  width: ${(p) => p.size ?? '36px'};
  height: ${(p) => p.size ?? '36px'};
  background-size: cover;
  background-position: top center;
  border-radius: 50%;
  margin-left: 9px;
`;
