var ContentState;
(function (ContentState) {
    ContentState["NONE"] = "None";
    ContentState["REGULAR"] = "Regular";
    ContentState["EXPIRING"] = "Expiring";
    ContentState["ZERO"] = "Zero";
    ContentState["ANSWERED"] = "Answered";
    ContentState["NOT_ANSWERED"] = "NotAnswered";
    ContentState["PARTIAL_ANSWERED"] = "PartialAnswered";
    ContentState["CORRECTED"] = "Corrected";
    ContentState["VIEWED"] = "Viewed";
    ContentState["ACTIVITY_STARTED"] = "ActivityStarted";
})(ContentState || (ContentState = {}));
export const readIconState = (state) => {
    const stateIconMap = {
        [ContentState.REGULAR]: { color: 'green3', name: 'eye' },
        [ContentState.EXPIRING]: { color: 'orange1', name: 'timerWatch' },
        [ContentState.ZERO]: { color: 'error', name: 'zero' },
        [ContentState.ANSWERED]: { color: 'green3', name: 'check' },
        [ContentState.NOT_ANSWERED]: { color: 'error', name: 'closeRadius' },
        [ContentState.PARTIAL_ANSWERED]: { color: 'orange1', name: 'check' },
        [ContentState.CORRECTED]: { color: 'blue1', name: 'userCheck' },
        [ContentState.VIEWED]: { color: 'green3', name: 'eyeOff' },
        [ContentState.ACTIVITY_STARTED]: { color: 'orange1', name: 'ActivityStarted' },
        [ContentState.NONE]: { color: '', name: '' }
    };
    return stateIconMap[state] || stateIconMap[ContentState.NONE];
};
export default ContentState;
