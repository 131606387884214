import React, { useRef } from 'react';
import { ButtonUploadFile } from './style';
const UploadButton = ({ onInputChange, accept, buttonWidth, ...props }) => {
    const inputRef = useRef();
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonUploadFile, { "$width": buttonWidth, onClick: () => {
                if (inputRef?.current) {
                    const node = inputRef.current;
                    node.click();
                }
            }, ...props }),
        React.createElement("input", { ref: inputRef, type: "file", accept: accept, style: { display: 'none' }, onChange: onInputChange })));
};
export default UploadButton;
