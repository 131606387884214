import styled from 'styled-components';
const variantValues = {
    minimize: {
        padding: '6px 2px',
        justifyContent: 'center',
        textDisplay: 'none',
        badgePosition: 'absolute'
    },
    default: {
        padding: '6px 14px',
        justifyContent: 'initial',
        textDisplay: 'initial',
        badgePosition: 'relative'
    }
};
export const Item = styled.li `
  display: flex;
  flex-direction: row;
  padding: ${(props) => variantValues[props.$variant].padding};
  justify-content: ${(props) => variantValues[props.$variant].justifyContent};
  align-items: center;
  gap: 9px;
  position: relative;

  ${(props) => props.theme.breakpoints.down('sm')} {
    & div {
      width: 26px;
      min-width: 26px;
      height: 26px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 13px;
    background-color: ${(props) => props.$performanceStatusColor
    ? props.theme.colors[props.$performanceStatusColor]
    : ''};
    left: -6px;
    display: ${(props) => (props.$performanceStatusColor ? 'block' : 'none')};
  }

  &:hover {
    background: #ffffff25;
    border-radius: 4px;
  }
`;
