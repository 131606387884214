import Student from './Student';
class User {
    id;
    email;
    name;
    imageUrl;
    static fromJson(data) {
        const user = new User();
        user.id = data?.Id;
        user.email = data?.Email;
        user.name = data?.Name;
        user.imageUrl = data?.ImageUrl;
        return user;
    }
    clone() {
        const user = new User();
        user.id = this.id;
        user.email = this.email;
        user.name = this.name;
        user.imageUrl = this.imageUrl;
        return user;
    }
    get toStudent() {
        const student = new Student();
        student.name = this.name;
        student.id = this.id;
        student.imageUrl = this.imageUrl;
        return student;
    }
}
export default User;
