import React from 'react';
import { Col } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Checkbox from '../Checkbox';
const StyledCol = styled(Col) `
  display: flex;
  align-items: end;

  & > div {
    padding: 0.5rem 0;
  }
`;
const FormCheckbox = ({ name, label, required, disabled, grid, defaultValue }) => {
    const { control } = useFormContext();
    return (React.createElement(StyledCol, { ...grid },
        React.createElement(Controller, { name: name, control: control, rules: { required }, defaultValue: defaultValue, render: ({ field, fieldState: { error } }) => (React.createElement(Checkbox, { ...field, checked: field.value, required: !!required, label: label, disabled: disabled, isError: !!error, errorMessage: error?.message })) })));
};
FormCheckbox.defaultProps = {
    grid: { xs: 6 }
};
export default FormCheckbox;
