import styled from 'styled-components';
export const Root = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$customSize ? props.$customSize : 56)}px;
  height: ${(props) => (props.$customSize ? props.$customSize : 56)}px;
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-radius: ${(props) => props.$active && props.$customSize
    ? props.$customSize / 4
    : props.$active
        ? 14
        : 64}px;
  box-shadow: 0px 2px 3px 0px #00000026;
  cursor: pointer;
  margin-top: ${(props) => props.$customSize ? props.$customSize / 5.6 : 10}px;
  opacity: ${(props) => (props.$active ? 1 : 0.4)};
  padding: 10px;
  color: ${(props) => props.theme.colors.gray2};
  font-size: 16pt;
  text-transform: uppercase;
  transition: 0.4s all;

  &:hover {
    opacity: ${(props) => (props.$active ? 1 : 0.8)};
  }

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    width: 56px;
    height: 56px;
    margin-top: 10px;
    border-radius: ${(props) => (props.$active ? 14 : 64)}px;
  }
`;
export const Image = styled.img `
  width: ${(props) => (props.$customSize ? props.$customSize / 1.5 : 38)}px;
  min-width: ${(props) => (props.$customSize ? props.$customSize / 1.5 : 38)}px;

  @media ${(props) => props.theme.mediaQuery.tabletUp} {
    width: 38px;
    min-width: 38px;
  }
`;
export const TextToolip = styled.span `
  font-family: 'Gilroy';
`;
