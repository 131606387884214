import styled from 'styled-components';
import { Tooltip } from '@campus/components';
export const Image = styled.img `
  width: ${(props) => (props.$size ? `${props.$size}px` : '58px')};
  height: ${(props) => (props.$size ? `${props.$size}px` : '58px')};
  transform: translate(-50%, 50%);
  z-index: 1;
  position: absolute;
  top: -60px;
  left: 150px;
`;
export const CustomTooltipWrapper = styled(Tooltip) `
  .tooltip-inner {
    border-radius: 4px;
    background: rgba(74, 74, 74, 1);
  }
  .tooltip-arrow::before {
    border-top-color: rgba(74, 74, 74, 1) !important;
  }
`;
export const BoxDescription = styled.div `
  width: 297px;
  height: 102px;
  border-radius: 6px;
  background: ${(props) => props.theme.colors.CSA.darkBlue};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const Description = styled.p `
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 21px 21px 21px;
`;
export const TitleDescription = styled.p `
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  padding: 21px 21px 0 21px;
  margin-bottom: 7px;
`;
