import styled from 'styled-components';
import { Button } from '@campus/components';
import Flow, { elementClassNames } from '../Charts/Flow';
export const StyledCloseButton = styled(Button) `
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray500};
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};

  :hover {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray200};
  }
`;
export const Description = styled.p `
  margin: 0;
  margin-top: 12px;
  align-self: end;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.gray4};
`;
export const StyledFlow = styled(Flow) `
  gap: 11px;

  & .${elementClassNames.labelTexts} {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.11px;

    color: ${(props) => props.theme.colors.gray700};
  }

  & .${elementClassNames.labelSigns} {
    width: 10.881px;
    height: 10.881px;
  }

  & .${elementClassNames.labelMinusSign} {
    height: 4px;
  }
`;
