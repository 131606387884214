import React from 'react';
import styled from 'styled-components';
import Placeholder from 'react-bootstrap/Placeholder';
const PlaceholderRow = styled.tr `
  display: flex;
  flex: 1 0 auto;
`;
const PlaceholderCell = styled.td `
  box-sizing: border-box;
  flex: ${(props) => props.width} 0 auto;
  width: ${(props) => props.width}px;
  border-left: 0px;
  :last-child {
    border-right: 0px;
  }
`;
const TablePlaceholder = ({ table }) => {
    const { headerGroups } = table;
    const { pageSize } = table.state;
    const renderPlaceholders = () => {
        const headerGroup = headerGroups[headerGroups.length - 1];
        const elements = [];
        for (let i = 0; i < pageSize; i++) {
            elements.push(React.createElement(PlaceholderRow, { key: `placeholder-${i}` }, headerGroup.headers.map((column) => (React.createElement(PlaceholderCell, { key: column.id, width: column.totalWidth },
                React.createElement(Placeholder, { animation: "glow" },
                    React.createElement(Placeholder, { xs: 6 })))))));
        }
        return elements;
    };
    return React.createElement("tbody", null, renderPlaceholders());
};
export default React.memo(TablePlaceholder);
