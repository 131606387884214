import styled from 'styled-components';
export const List = styled.ul `
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 18px;

  &:before {
    content: attr(aria-label);
    font-size: 12px;
    font-weight: ${(props) => props.theme.weight.semiBold};
    color: #ffffff96;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block;
  }
`;
