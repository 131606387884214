import styled from 'styled-components';
export const NumberInput = styled.input `
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  padding: 0.5rem 0.75rem;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.colors.gray100};
  }
`;
