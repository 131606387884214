import React, { useState } from 'react';

import { Pencil } from '@campus/components/icons';

import { Card, Container, Header, Item, Row, Title } from './styles';
import ModalPermissionManager from './components/ModalPermissionManager';
import ModalPermissionGrade from './components/ModalPermissionGrade';
import ModalPermissionTeacher from './components/ModalPermissionTeacher';
import ModalPermissionStudent from './components/ModalPermissionStudent';

const EvaluationTypePage: React.FC = () => {
  const [showModalManager, setShowModalManager] = useState(false);
  const [showModalGrade, setShowModalGrade] = useState(false);
  const [showModalTeacher, setShowModalTeacher] = useState(false);
  const [showModalStudent, setShowModalStudent] = useState(false);

  return (
    <>
      <Header>
        <Title>Gerenciar permissões da Calculadora do MDC</Title>
      </Header>

      <Card>
        <Container>
          <Row>
            <Item $widthPercentage={90}>Gestor</Item>
            <Item $widthPercentage={10}>
              <Pencil
                size={16}
                onClick={() => {
                  setShowModalManager(true);
                }}
              />
              <ModalPermissionManager
                show={showModalManager}
                setShowModal={setShowModalManager}
              />
            </Item>
          </Row>
          <Row>
            <Item $widthPercentage={90}>Série</Item>
            <Item $widthPercentage={10}>
              <Pencil
                size={16}
                onClick={() => {
                  setShowModalGrade(true);
                }}
              />
              <ModalPermissionGrade
                show={showModalGrade}
                setShowModal={setShowModalGrade}
              />
            </Item>
          </Row>
          <Row>
            <Item $widthPercentage={90}>Professor</Item>
            <Item $widthPercentage={10}>
              <Pencil
                size={16}
                onClick={() => {
                  setShowModalTeacher(true);
                }}
              />
              <ModalPermissionTeacher
                show={showModalTeacher}
                setShowModal={setShowModalTeacher}
              />
            </Item>
          </Row>
          <Row>
            <Item $widthPercentage={90}>Aluno</Item>
            <Item $widthPercentage={10}>
              <Pencil
                size={16}
                onClick={() => {
                  setShowModalStudent(true);
                }}
              />
              <ModalPermissionStudent
                show={showModalStudent}
                setShowModal={setShowModalStudent}
              />
            </Item>
          </Row>
        </Container>
      </Card>
    </>
  );
};

export default EvaluationTypePage;
