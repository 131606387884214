import styled from 'styled-components';
export const ListItem = styled.li `
  padding-left: ${(props) => (props.$level !== 1 ? 14 : 0)}px;
  position: relative;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 0;
    margin-top: -6px;
    position: absolute;
    top: 1em;
    left: 0;
  }

  &:last-child:before {
    background: #fff;
    height: auto;
    top: 12px;
    bottom: 0;
    left: -1px;
  }

  & input {
    z-index: 1;
  }
`;
export const Root = styled.div `
  display: flex;
  align-items: center;
  gap: 5px;
  ${(props) => (props.$selected ? '' : 'margin-bottom: 6px;')}
`;
export const Button = styled.button `
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  border-radius: 4px;
  transition: 0.2s all;
  display: flex;

  &:hover {
    opacity: 0.6;
  }
`;
export const LabelOne = styled.label `
  color: ${(props) => props.theme.colors.gray1};
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
  ${(props) => (props.$isClickable ? 'cursor: pointer;' : '')}

  ${(props) => props.$selected
    ? `
        background: rgb(47,128,237,.2);
        color: ${props.theme.colors.blue1} !important;
        padding: 3px 8px;
        border-radius: 2px;`
    : ''}
`;
export const LabelTwo = styled.label `
  color: ${(props) => props.theme.colors.gray2};
  font-weight: ${(props) => props.theme.weight.semiBold};
  text-decoration-line: underline;
  font-size: 12px;
  ${(props) => (props.$isClickable ? 'cursor: pointer;' : '')}

  ${(props) => props.$selected
    ? `
        background: rgb(47,128,237,.2);
        color: ${props.theme.colors.blue1} !important;
        padding: 3px 8px;
        border-radius: 2px;`
    : ''}
`;
export const LabelThree = styled.label `
  color: ${(props) => props.theme.colors.gray3};
  font-size: 12px;
  ${(props) => (props.$isClickable ? 'cursor: pointer;' : '')}

  ${(props) => props.$selected
    ? `
        background: rgb(47,128,237,.2);
        color: ${props.theme.colors.blue1} !important;
        padding: 3px 8px;
        border-radius: 2px;`
    : ''}
`;
export const Corner = styled.img `
  display: block;
  width: 8px;
  position: absolute;
  top: 3px;
  left: -1px;
`;
