import React, { useEffect } from 'react';
import { FullSpinner } from '@campus/components';
import { useContext } from '../../hooks/useContext';
import { useBuildBlipChat } from '../../hooks/useBuildBlipChat';
import { useAuth } from '../AuthProvider';
import { AuthorityContext } from './Context';
const AuthorityProvider = ({ type, children }) => {
    useBuildBlipChat();
    const { logout } = useAuth();
    const { loading, context, contexts, loadContexts, saveContext, isCurrentContext } = useContext();
    useEffect(() => {
        loadContexts(type);
    }, []);
    const onChangeContext = async (newContext, type) => {
        await saveContext(newContext, type);
        window.location.reload();
    };
    const handleLogout = () => logout();
    if (loading || !context) {
        return React.createElement(FullSpinner, { title: "Carregando dados do usu\u00E1rio..." });
    }
    return (React.createElement(AuthorityContext.Provider, { key: `${context?.unityId}${context?.tenantId}`, value: {
            contexts,
            currentContext: context,
            unityName: context.unityName,
            logout: handleLogout,
            changeContext: onChangeContext,
            isCurrentContext
        } }, children));
};
export default AuthorityProvider;
