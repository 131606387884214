import styled from 'styled-components';
import { Form, InputGroup } from 'react-bootstrap';
export const StyledInputGroup = styled(InputGroup) `
  & > * {
    border-color: ${(props) => props.theme.colors[props.$isError ? 'error' : 'gray4']};
  }

  & > :not(:first-child) {
    border-left: none;
  }

  & > :not(:last-child) {
    border-right: none;
  }

  & .invalid-feedback {
    color: ${(props) => props.theme.colors.error};
  }
`;
export const StyledTextarea = styled(Form.Control) `
  color: ${(props) => props.theme.colors.gray2};
  font-size: 14px;
  line-height: 140%;
  border-radius: 6px;
  flex: 1;
  padding: 8px 12px;
  resize: vertical;
  font-weight: ${(props) => props.theme.weight.regular};

  &::placeholder {
    color: ${(props) => props.theme.colors.gray4};
    font-weight: ${(props) => props.theme.weight.regular};
    font-size: 14px;
    line-height: 140%;
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.gray4};
    box-shadow: none;
    color: ${(props) => props.theme.colors.gray2};
    outline: none;
  }
`;
