import React from 'react';
import Label from '../Label';
import FormFeedback from '../FormFeedback';
import { IconBox, StyledInputGroup, StyledInput, StyledFormGroup } from './styles';
const Input = React.forwardRef(({ name, label, errorMessage, isError, required, size, value, defaultValue, StartIcon, EndIcon, className, fullWidth, max, height, ...props }, ref) => {
    return (React.createElement(StyledFormGroup, { className: className, "$fullWidth": fullWidth },
        label && React.createElement(Label, { required: required }, label),
        React.createElement(StyledInputGroup, { id: "input-group", "$isError": isError },
            StartIcon && React.createElement(IconBox, { id: name }, StartIcon),
            React.createElement(StyledInput, { ...props, defaultValue: defaultValue, value: value, ref: ref, id: name, name: name, max: max, required: required, size: size, "$height": height }),
            EndIcon && React.createElement(IconBox, { id: name }, EndIcon)),
        isError && errorMessage && React.createElement(FormFeedback, null, errorMessage)));
});
export default Input;
