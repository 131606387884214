
import LibraryObjectContentType from './LibraryObjectContentType';

class LibraryObjectChapter {
  id?: string;

  name?: string;

  contentType?: LibraryObjectContentType;

  contentUrl?: string;

  imageUrl?: string;

  static fromJson(data: any): LibraryObjectChapter {
    const libraryObjectChapter = new LibraryObjectChapter();

    libraryObjectChapter.id = data?.Id;

    libraryObjectChapter.name = data?.Name;

    libraryObjectChapter.contentType = data?.ContentType;

    libraryObjectChapter.contentUrl = data?.ContentUrl;

    libraryObjectChapter.imageUrl = data?.ImageUrl;

    return libraryObjectChapter;
  }
}

export default LibraryObjectChapter;
