import styled from 'styled-components';
export const FooterPagination = styled.div `
  display: flex;
  justify-content: space-between;
`;
export const FooterPaginationNumberPages = styled.div `
  display: flex;
  gap: 4px;
`;
export const ButtonPage = styled.button `
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
  border: 1px solid #cccccc;
  color: #808080;
  background: ${({ $selected }) => ($selected ? '#E6E6E6' : '#FFFFFF')};
  border-radius: 6px;
`;
