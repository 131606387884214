import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthProvider, useAuthority } from '@campus/auth';
import { ErrorBoundary, SideMenuProvider, ToastContainer } from '@campus/components';
import '@campus/components/assets/style';
import MainOutlet from './components/MainOutlet';
import { DefaultComponent } from './components/DefaultComponents';
const App = ({ contextType, components, children }) => {
    const { logout } = useAuthority();
    const PageHeader = components.PageHeader;
    const MainProvider = components.MainProvider ?? DefaultComponent;
    return (React.createElement(ErrorBoundary, { onExit: logout },
        React.createElement(MainProvider, { contextType: contextType },
            PageHeader ? React.createElement(PageHeader, null) : null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(MainOutlet, { contextType: contextType, components: components }) }, children)))));
};
const AppContainer = ({ theme, contextType, components, children }) => {
    return (React.createElement(SideMenuProvider, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(ToastContainer, null,
                React.createElement(BrowserRouter, null,
                    React.createElement(AuthProvider, { type: contextType },
                        React.createElement(App, { contextType: contextType, components: components }, children)))))));
};
export default AppContainer;
