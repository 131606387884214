import { useEffect, useState } from 'react';
import Api from '../../../api/Api';
import { useAuth0Authorization, getAuth0AuthConfig } from './useAuth0Authorization';
import { useAzureAuthorization, getAzureAuthConfig } from './useAzureAuthorization';
const API_URL = process.env.REACT_APP_SERVICE_URL;
const AUTH_PROVIDER = process.env.REACT_APP_AUTH_PROVIDER;
export const authConfig = {
    AUTH0: getAuth0AuthConfig,
    AZURE: getAzureAuthConfig
};
export const useAuthorization = () => {
    const [hasError, setHasError] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const useAuthorizator = AUTH_PROVIDER === 'AUTH0' ? useAuth0Authorization : useAzureAuthorization;
    const { isAuthenticated, error, loading, getAccessToken, logout } = useAuthorizator();
    const handleLogout = async () => {
        localStorage.clear();
        sessionStorage.clear();
        await logout();
    };
    const handleAxiosError = (res) => {
        if (res.error && res.statusCode === 403)
            setHasError(true);
    };
    useEffect(() => {
        if (error)
            setHasError(true);
    }, [error]);
    useEffect(() => {
        if (authenticated != isAuthenticated) {
            Api.createInstance(API_URL, getAccessToken, handleAxiosError);
            setAuthenticated(isAuthenticated);
        }
    }, [authenticated, isAuthenticated]);
    return { authenticated, loading, error: hasError, logout: handleLogout };
};
