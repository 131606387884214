import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
`;
export const TableRow = styled.tr `
  ${(props) => props.$selected &&
    `border: 2px solid ${props.theme.colors.CSA.darkBlue} !important;`};
`;
export const FooterContainer = styled.div `
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  padding-top: 8px;

  color: ${(props) => props.theme.colors.gray500};
  font-weight: ${(props) => props.theme.weight.semiBold};

  ul {
    margin: 0;

    li {
      padding: 0;
    }
  }
`;
export const RefreshButton = styled.button `
  color: #0b5ed7;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const CheckboxContainer = styled.div `
  display: flex;
  justify-content: center;
`;
export const TableTd = styled.td `
  border-left: 0px;
  :last-child {
    border-right: 0px;
  }
`;
export const TableTh = styled.th `
  border-left: 0px;
  :last-child {
    border-right: 0px;
  }
`;
